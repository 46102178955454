.slider-pager {
	position: relative;
	margin-top: 5rem;
}

.pager-btn {
	width: 18px;
	height: 18px;
	border: solid 2px $bright-purple;
	display: inline-block;
	margin-right: 15px;
	background: none;
	border-radius: 50%;
	transition: 0.35s ease all;

    cursor: pointer;
	&.cycle-pager-active {
		background: $bright-purple;
	}

	&:hover {
		background: $motum-green;
	}

	&:focus{
		outline: none;
	}

	.theme-yellow & {
        // border: solid 2px $motum-green;
        border: solid 2px $motum-purple;
        &.cycle-pager-active {
			// background: $motum-green;
			background: $motum-purple;
		}

		&:hover {
			// background: $bright-green;
			background: $bright-purple;
		}
    }
    .theme-green & {
        border: solid 2px $motum-purple;
        &.cycle-pager-active {
			background: $motum-purple;
		}

		&:hover {
			background: $bright-purple;
		}
    }
    .theme-purple & {
        border: solid 2px $motum-purple;
        &.cycle-pager-active {
			background: $motum-purple;
		}

		&:hover {
			background: $bright-purple;
		}
    }
    .theme-red & {
        border: solid 2px $motum-blue;
        &.cycle-pager-active {
			background: $motum-blue;
		}

		&:hover {
			background: $bright-blue;
		}
    }
    .theme-blue & {
        border: solid 2px $motum-blue;
        &.cycle-pager-active {
			background: $motum-blue;
		}

		&:hover {
			background: $bright-blue;
		}
    }

    .our-work-quotes & {
    	border: solid 2px $bright-red;
        &.cycle-pager-active {
			background: $bright-red;
		}

        &:not(.cycle-pager-active):hover {
			background: $motum-red;
		}
    }
}