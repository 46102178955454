.craft-partner-row {
    background-color: #fff;
    position: relative;
    z-index: 1;

    > .wrapper {
        &, & > div {
            display: flex;
            align-items: center;
        }
        & > div {
            justify-content: space-between;
            column-gap: 1rem;
            row-gap: 2rem;
            // flex-wrap: wrap;
            width: 100%;
            padding-left: 3rem;
            position: relative;
            @include desktop-small-below {
                flex-direction: column;
                align-items: flex-start;
            }
            @include phone-large-below {
                padding-left: 0;
                align-items: center;
                width: fit-content;
                padding-top: 4rem;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 125%;
                transform: translateY(-50%);
                background-color: #DDDDDD;
                @include desktop-small-below {
                    height: 100%;
                }
                @include phone-large-below {
                    top: 0;
                    left: 50%;
                    transform: translateY(0) translateX(-50%);
                    height: 1px;
                    width: 110%;
                }
                @include phone {
                    width: 90%;
                }
            }
        }
        padding: 5rem 0;
        @include desktop-large-below {
            padding: 5rem;
        }
        @include phone-large-below {
            padding: 4rem 2rem;
            flex-direction: column;
            justify-content: center;
        }
    }

    h2 {
        font-size: 2.8rem;
        margin: 0;
        @include tablet-below {
            font-size: 2.4rem;
        }
        @include phone-large-below {
            text-align: center;
        }
    }

    .logo-container {
        margin-right: 3rem;
        min-width: 22rem;
        max-width: 45rem;
        @include phone-large-below {
            margin-right: 0;
            margin-bottom: 4rem;
        }
        @include phone {
            max-width: 38rem;
        }
        img {
            width: 100%;
        }
    }
}