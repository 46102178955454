
$color-correct: #00c700;
$color-wrong: #ff0000;

.score{
    position: fixed;
    left: 0;
    top: 0;
    border: 2px solid red;
    display: block;
    font-weight: 700;
    font-size: 2rem;
    padding: 10px;
    display: none;
}

.quiz-wrapper{
    float: left;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    // min-height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease-in-out .2s;
    .quiz-visible &{
        opacity: 1;
        pointer-events: auto;
    }
    @include tablet-below{
        padding-top: 10rem;
    }
    @include phone-large-below{
        z-index: 9999;
        height: 100%;
        background-color: #fff;
    }
}

.questions, .results{
    float: left;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.questions{

    &.visible{
        min-height: 85vh;
        opacity: 1;
        height: auto;
    }
    //display: none !important;
}

.results{
    //display: flex;
    //flex-direction: column;
    &.visible{
        min-height: 85vh;
        opacity: 1;
        height: auto;
    }
    //display: block !important;
}

.results-container{
    display: flex;
    flex-direction: column;
}

.results-ctas{
    padding-left: 3rem;
    @include column(7);
    @include push(4);
    @include desktop-small-below{
        @include column(10);
        padding-left: 0;
        @include push(1);
    }
    @include phone-large-below{
        @include column(11);
        @include push(.5);
        margin-bottom: 2rem;
    }
}


.results-ctas > div{
    background-color: #f1f1f1;

    padding: 3.5rem;
    display: flex;
    align-items: center;
    @include phone-large-below{
        flex-direction: column;
        padding: 2rem;
    }
}

.results-cta{
    @include column(6);
    @include phone-large-below{
        @include column(12);
        margin-bottom: 2rem;
    }
}
.results-cta__title{
    font-weight: 700;
    color: $motum-darkgray;
    font-size: 2.6rem;
    display: block;
    margin-bottom: 1rem;
}
.results-cta__subtitle{
    font-weight: 500;
    color: #787878;
    font-size: 1.75rem;
    display: block;
}
.results-cta a{
    font-weight: 600;
    font-size: 2rem;
    color: #1db4ff;
    border-bottom: 4px solid rgba(#1db4ff, .5);
    transition: all .2s ease;
    &:hover{
        border-bottom: 4px solid #1db4ff;
    }
}

.result{
    float: left;
    width: 100%;
    display: none;

}

.result__content{
    padding-left: 3rem;
    @include column(7);
    float: left;

    //.promoted-quiz &, .promoted-quiz & strong {
    //    color: #fff;
    //}

    @include desktop-small-below{
        @include column(10);
        padding-left: 0;
        @include push(1);
    }
    @include phone-large-below{
        @include column(11);
        @include push(.5);
    }
}



.result__image{
    @include column(3);
    @include push(1);
    float: left;
    @include desktop-small-below{
        margin-top: 2rem;
        margin-bottom: 2rem;
        @include column(10);
        padding-left: 0;
        @include push(1);
        max-width: 400px;
    }
    @include tablet-below{
        max-width: none;
    }
    @include phone-large-below{
        @include column(11);
        @include push(.5);
    }
}


.result__title{
    font-size: 6rem;
    font-weight: 800;
    margin-bottom: 2rem;
    display: block;
    //.promoted-quiz & {
    //    color: #fff;
    //}
}
.result__subtitle{
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: block;
    position: relative;
    padding: 1.3rem 0;
    &:before, &:after{
        content: '';
        position: absolute;
        background-color: $motum-darkgray;
        left: 0;
        width: 100%;
        height: 3px;
    }
    &:before{
        top: 0;

    }
    &:after{
        bottom: 0;
    }
    //.promoted-quiz & {
    //    color: #fff;
    //    &:before, &:after {
    //        background-color: #fff;
    //    }
    //}
}


.question{
    margin-bottom: 6rem;
    display: none;
    //position: absolute;
    // top: 50%;
    // left: 50%;
    //transform: translate(-50%, -50%);
    width: 100%;
    flex-direction: column;
    &.active{
        display: flex;
    }
    @include desktop-below{

    }
}

.question__content{
    @include column(7);
    @include push(2.5);
    @include desktop-medium-below{
        @include column(9);
        @include push(1.5);
    }
    @include desktop-below{
        @include column(10);
        @include push(1);
    }
    @include tablet-below{
        margin-top: 3rem;
    }
    .multiple-choice-quiz &{
        @include column(9);
        @include push(1.5);
    }
}

.question__title{
    overflow: visible;
    display: block;
    font-size: 3.25rem;
    line-height: 1.23;
    //transition: transform .5s cubic-bezier(.64,.39,.03,1.24);
    //transform: translateY(150%);
    //.active &{
    //    transform: translateY(0%);
    //}
    
    //.promoted-quiz & {
    //    color: #fff;
    //}
}

.question__subtitle{
    position: relative;
    width: 100%;
    display: block;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 2.7rem;
    //padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    overflow: hidden;
    &:after{
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        background-color: #3a363c;
        bottom: 0;
        left: 0;
    }
    //.promoted-quiz &:after {
    //    background-color: #fff;
    //}
    > span{
        display: block;
        padding-bottom: 1rem;
    }
    > span > span{
        display: block;
        transition: transform .5s cubic-bezier(.64,.39,.03,1.24);
    }
    span.question-correct, span.question-wrong{
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(150%);
    }
    span.question-correct{
        color: $color-correct;
    }
    span.question-wrong{
        color: $color-wrong;
    }

    .is-correct &{
        span.question-correct{
            transform: translateY(0);
        }
    }

    .is-wrong &{
        span.question-wrong{
            transform: translateY(0);
        }
    }

    .is-answered &{
        .question-num{
            transform: translateY(-100%);
        }
    }

    //.promoted-quiz & span {
    //    color: #fff;
    //}
}

.answer-container{
    position: relative;
    &:nth-child(1) .answer:before{
        content: 'A';
    }
    &:nth-child(2) .answer:before{
        content: 'B';
    }
    &:nth-child(3) .answer:before{
        content: 'C';
    }
    &:nth-child(4) .answer:before{
        content: 'D';
    }
    &:nth-child(5) .answer:before{
        content: 'E';
    }
    &:nth-child(6) .answer:before{
        content: 'F';
    }
    &:nth-child(7) .answer:before{
        content: 'G';
    }
    opacity: 0;
    transition: opacity .5s ease-in-out;

    //.promoted-quiz & .answer span {
    //    color: #fff;
    //}

    @include phone-large-below{
        position: static;
        margin-bottom: 2rem;
    }
    .active &{
        opacity: 1;
    }
    @for $i from 0 to 12 {
        &:nth-child(#{$i}) {
            transition-delay: .2s * $i;
        }
    }
}

.question-responses{
    //display: none;
    margin-top: 3rem;
}

.quiz-btn__close{
    position: absolute;
    right: 5rem;
    top: 5rem;
    font-size: 5rem;
    color: $motum-darkgray;
    z-index: 10;
    cursor: pointer;
    opacity: .7;
    &:hover{
        opacity: 1;
    }

    //.promoted-quiz & {
    //    color: #fff;
    //}

    @include tablet-below{
        right: 2rem;
        top: 2rem;
    }
}

.question-response{
    // display: none;
    height: 0;
    opacity: 0;
    transition: all .5s ease-in-out;
    pointer-events: none;
    overflow: hidden;
    .is-answered &{
        pointer-events: auto;
    }
    p, p strong{
        color: #3a363c;
        font-size: 2.1rem;
        line-height: 1.4;
        //.promoted-quiz & {
        //    color: #fff;
        //}
    }
    a{
        color: #1db4ff;
        border-bottom: 4px solid rgba(#1db4ff, .5);
        transition: all .2s ease;
        &:hover{
            border-bottom: 4px solid #1db4ff;
        }
    }
}

.question-response.both{
    .is-answered &{
        opacity: 1;
        height: auto;
    }
}

// Correct answer
.question-response.correct{
    .is-correct &{
        opacity: 1;
        height: auto;
    }
}

// Wrong answer
.question-response.wrong{
    .is-wrong &{
        opacity: 1;
        height: auto;
    }
}



.btn__next-question, .btn__see-results{
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease-in-out;
    background: transparent;
    border: none;
    font-family: $font-body;
    color: #1db4ff;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 2rem;
    transform: translateX(-1rem);
    cursor: pointer;
    @include column(7);
    @include push(2.5);
    @include desktop-below{
        @include column(10);
        @include push(1);
        margin-top: 2rem;
        justify-content: flex-end;
    }
    .is-answered &{
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0);
        transition-duration: .2s;
    }
    &:focus, &:hover{
        background-color: transparent;
        color: $motum-darkgray;
        transform: translateX(1rem);

        //.promoted-quiz & {
        //    color: #fff;
        //}
    }
    span{
        display: inline-block;
        border-radius: 100%;
        border: 5px solid #1db4ff;
        width: 60px;
        height: 60px;
        position: relative;
        font-size: 3rem;
        margin-left: 2rem;
        i{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    @include phone-large-below{
        border: 3px solid #1db4ff;
        font-size: 3rem;
        padding: 1.5rem;
        justify-content: center;
        span{
            height: auto;
            width: auto;
            border: 0;
            margin-left: 3rem;
        }
    }
}

.question__submit{
    pointer-events: none;
    opacity: 0;
    width: 100%;
    float: left;
    margin-top: 0rem;
    padding-top: 2rem;
    //display: none;
    transform: translateY(-2rem);
    transition: all .4s cubic-bezier(.64,.39,.03,1.54);
    i{
        margin-left: 1rem;
        font-size: 1.8rem;
    }
    .answer-active &{
        opacity: 1;
        transform: translateY(0rem);
        pointer-events: auto;
    }
    .is-answered &{
        height: 0;
        overflow: hidden;
        pointer-events: none;
    }
    @include phone-large-below{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.btn__submit-answer{
    opacity: 1;
    transition: transform .2s cubic-bezier(.64,.39,.03,1.54), background-color .1s ease;
    border: 0;
    background-color: $motum-darkgray;
    padding: 1.5rem;
    font-family: $font-body;
    font-weight: 800;
    font-size: 2.2rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    white-space: nowrap;
    color: #fff;

    span{
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid $motum-darkgray;
        top: 1px;
        left: 50%;
        transform: translate(-50%, -100%);
        transition: border-bottom-color .1s ease;
    }
    .is-answered &{
        opacity: 0;
    }
    &:hover, &:focus{
        transform: scale(1.1) translateX(-45%);
        span{
            border-bottom-color: #1db4ff;
        }
        background-color: #1db4ff;
    }

    @include phone-large-below{
        width: 100%;
        padding: 2.5rem 0;
        font-size: 3rem;
    }
}


.answers{
    margin-top: 5rem;
    transition: all .3s ease-in-out;
    .is-answered &{
        display: none;
        //margin-top: 0;
        //height: 0;
        //opacity: 0;
    }
    @include phone-large-below{
        position: relative;
    }
}

.answer.correct{
    font-weight: 700;
}






.answer-spot{
    display: inline-block;
    position: relative;
    min-width: 12rem;
    //padding: .25rem;
    padding-left: .75rem;
    padding-right: .75rem;
    //padding-bottom: 2rem;
    //padding-top: .4rem;
    padding-bottom: 18px;
    font-weight: 700;
    margin-top: -1.8rem;
    margin-bottom: -1rem;
    transform: translateY(5px);
    transition: transform .1s ease-out;
    &.pop{
        transform: scale(1.04) translateY(4px);
        &:before{
            background-color: #e2e2e2;
        }
    }
    //overflow: hidden;
    .answer-set &{
        min-width: 0;
    }
    &:before{
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: calc(100% - 20px);
        background-color: #eeeeee;
        top: 10px;
        left: 0;
    }
    &:after{
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background-color: #3a363c;
        bottom: 0px;
        left: 0;
        .answer-set &{
            background-color: #1db4ff;
        }
        .is-correct &{
            background-color: $color-correct;
        }
        .is-wrong &{
            background-color: $color-wrong;
        }

    }
    span{
        position: relative;
        z-index: 1;
        display: block;
        transform: translateY(4px);
        line-height: 1.5;
        //text-transform: lowercase;
    }
    .user-answer{

        overflow: hidden;
        transition: all .5s cubic-bezier(.64,.39,.03,1.24);

        .promoted-quiz & {
            color: $motum-darkgray;
        }

    }
    .correct-answer{
        //display: none;
        //position: absolute;
        //top: 4px;
        //left: 6px;
        height: 0;
        width: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity .5s cubic-bezier(.64,.39,.03,1.24) .1s;
    }
    .answer-set &{
        .correct-answer{

        }
    }

    .is-wrong &{
        .user-answer{
            transform: translateY(-120%);
            opacity: 0;
            height: 0;
            width: 0;
        }
        .correct-answer{
            //display: block;
            height: auto;
            width: auto;
            //transform: translateY(12px);
            opacity: 1;
            transform: translateY(8px);

            .promoted-quiz & {
                color: $motum-darkgray;
            }
        }
    }
}

.answers{
    display: flex;
    justify-content: space-between;
    @include phone-large-below{
        flex-direction: column;
    }
}
button.answer{
    background: transparent;
    border: 0;
    font-family: $font-body;
    font-weight: 400;
    font-size: 2.5rem;
    color: #777777;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    cursor: pointer;

    .multiple-choice-quiz &{
        font-size: 2rem;
        line-height:1.2;
        text-align: left;
        align-items: flex-start;
    }


    .cased-answer{
        display: none;
    }
    &:before{
        color: #fff;
        //padding: 2rem;
        width: 47px;
        height: 49px;
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        background-image: url('/images/interface/icon_quiz_speechbubble-2.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        color: #1db4ff;
        font-weight: 800;
        font-size: 2.4rem;
        padding-bottom: 0.5rem;
        background-position-y: bottom;
        transition: all .15s cubic-bezier(0,.42,.32,1.53);
    }
    &.active{
        font-weight: 700;
        color: #3a363c;
        cursor: default;
        &:before{
            background-image: url('/images/interface/icon_quiz_speechbubble_active-2.png');
            color: #fff;
            transform: scale(1);
        }
        >span:after{
            width: 100%;

            .promoted-quiz & {
                background-color: #fff;
            }
        }
    }
    &:hover:not(.active), &:focus:not(.active){
        &:before{
            transform: scale(1.2);
        }
    }

    > span{
        position: relative;
    }
    >span:after{
        position: absolute;
        bottom: -.7rem;
        left: 0;
        background-color: #3a363c;
        content: '';
        display: block;
        width: 0;
        height: 4px;
        transition: all .3s cubic-bezier(0,.42,.32,1);
    }

    @include phone-large-below{
        @include column(12);
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        font-size: 2.5rem;
        font-weight: 600;
        color: #1db4ff;
        border: 2px solid #1db4ff;
        padding: 2rem;
        text-align: left;
        &.active{
            background-color: #1db4ff;
            color: #fff;
            &:before {
                color: #fff;
            }
            span:after{
                display: none;
            }
        }
        &:focus:not(.active){
            background-color: rgba(29, 180, 255, 0.29);
        }
        &:before{
            background: none;
            width: auto;
            height: auto;
            padding: 0;
            display: inline-block;
            margin-right: 1rem;
            margin-bottom: 0;
        }
        &:hover{
            &:before{
                transform: scale(1) !important;
            }
        }
    }

}

.progress-container{
    float: left;

    @include column(10);
    @include push(1);
    @include height-small{
        //  margin-top: 5rem;
    }
    @include tablet-below{
        display: none;
    }
}

.progress-bar{
    background-color: #d8d8d8;
    width: 100%;
    height: 7px;
    display: block;
    position: relative;
}
.progress-bar__indicator{
    background-color: #1db4ff;
    width: 0;
    height: 7px;
    display: block;
    position: relative;
    transition: width .7s cubic-bezier(.64,.39,.03,1);
    background-color: .2s ease-in-out;

    .is-showing-results &{
        background-color: #dfdfdf;
    }

    @for $i from 1 to 12 {
        [progress='#{$i}'] & {
            width: (100% / 6) * $i;
        }
    }
    &[count='5'] {
        @for $i from 1 to 5 {
            [progress='#{$i}'] & {
                width: (100% / 4) * $i;
            }
        }
    }


}


.progress-bar-point{
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #d8d8d8;
    display: block;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: transform .5s cubic-bezier(.64,.39,.03,1.25) .5s;
    &:before{
        font-family: 'icoMoon';
        color: #fff;
        font-size: .8rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &.active{
        background-color: #1db4ff;
        transform: translateY(-50%) scale(1) ;
    }
    &.is-correct{
        &:before{
            content: '\e90e';
            font-size: 1rem;
        }
        .is-showing-results &{
            background-color: $color-correct;
        }

    }
    &.is-wrong{
        &:before{
            content: '\e90a';
        }
        .is-showing-results &{
            background-color: $color-wrong;
        }

    }


    [count='6'] &{
        @for $i from 0 to 12 {
            &:nth-child(#{$i + 1}) {
                left: (100% / 6) * $i;
            }
        }
    }
    [count='5'] &{
        @for $i from 0 to 12 {
            &:nth-child(#{$i + 1}) {
                left: (100% / 4) * $i;
            }
        }
    }


    &:nth-child(1){
        left: 0;
    }
    .is-showing-results &{
        @for $i from 0 to 12 {
            &:nth-child(#{$i + 1}) {
                transition-delay: .15s * $i;
            }
        }
        transform: translateY(-50%) scale(1.1);
    }
    @for $i from 0 to 12 {
        &:nth-child(#{$i + 1}) .progress-bar-point__answer {
            transition-delay: .15s * $i;
        }
    }
}
.progress-bar-point__answer{
    position: absolute;
    background-color: #e3e3e3;
    color: #fff;
    top: -1rem;
    left: 50%;
    transform: translateY(0%) translateX(-50%) scale(0);
    font-weight: 700;
    padding: 1rem;
    text-align: center;
    white-space: nowrap;
    transition: all .6s cubic-bezier(.64,.39,.03,1.24);
    opacity: 0;
    // display: none;

    &:after{
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        left: 50%;
        transition: border-bottom-color .1s ease;
        .is-correct &{
            border-top: 10px solid $color-correct;
            bottom: 1px;
            transform: translate(-50%, 100%);
        }
        .is-wrong &{
            border-bottom: 10px solid $color-wrong;
            top: 1px;
            transform: translate(-50%, -100%);
        }
    }



    .is-showing-results .is-correct &{
        background-color: $color-correct;
        transform: translateY(-100%) translateX(-50%) scale(1);
        opacity: 1;
    }
    .is-showing-results .is-wrong &{
        background-color: $color-wrong;
        bottom: -1rem;
        top: auto;
        transform: translateY(100%) translateX(-50%) scale(1);
        opacity: 1;
    }
    // display: none;
}

.progress-bar__counter{
    background-color: #1db4ff;
    position: absolute;
    width: 32px;
    height: 32px;
    color: #fff;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1.9rem;
    right: -.8rem;
    transform: translateY(-100%) translateX(50%);
    .is-showing-results &{
        opacity: 0;
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 10px solid #1db4ff;
        transform: translateY(100%);
    }
    @include desktop-large-below{

        &:after{

        }
    }
}


.quiz-share-buttons{
    margin-top: 1rem;
    a{
        border-bottom: 0;
        &:hover{
            border-bottom: 0;
        }
    }
    button{
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;

        opacity: .8;
        transition: all .15s ease-in-out;
        i{

            font-size: 3.5rem;
            &:before{
                color: #1db4ff;
            }
        }
        &:hover{
            opacity: 1;
        }
    }
    button{
        margin-right: .3rem;
    }
}

.header{
    .quiz-visible &{
        opacity: 0;
        pointer-events: none;
    }
}
//
//.questions *{display: block !important;}
















