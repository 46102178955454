.page-header-process{
    //  margin: rem 0;
    position: relative;
    z-index: 2;
    // padding-top: 15rem;
    min-height: 65vh;
    display: flex;
    align-items: center;
    .wrapper{
        justify-content: center;
        display: flex;
    }
    &::before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 84%);
    }
}

.page-header-process__title{
    text-align: center;
    @include desktop-medium-above{
        max-width: 80%;
    }
}


.process-visible-grid{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    &.process-visible-grid--light{
        opacity: .3;
    }
    @include phone-large-below{
        opacity: .5;
    }
    .wrapper{
        display: flex;
        align-items: stretch;
        height: 100%;
        max-width: 1800px;
    }
    span{
        display: block;
        //border-right: 1px solid rgba(0,0,0,.1);
        padding: 2rem;
        width: 100%;
        position: relative;
        &:first-child{
            //border-left: 1px solid rgba(0,0,0,.1);
        }

        &::before, &::after{
            content: '';
            height: 100%;
            width: 2px;
            //background-repeat: repeat;
          //  background-color: red;
            background-image: url('/images/interface/dotted-divider-pattern.svg');
            background-position: 0 0;
            position: absolute;
            display: none;
        }
        &:nth-child(even)::after, &:nth-child(odd)::before {
            animation: gridlineDown 120s infinite linear;
        }
        &:nth-child(odd)::after {
            animation: gridlineUp 120s infinite linear;
        }

        &::after{
            display: block;
            right: -2px;

        }

        &:first-child::before{
            display: block;
            left: 0;
        }

        //@include column(1);
    }
}

@keyframes gridlineUp {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 -100%;
    }
}
@keyframes gridlineDown {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 100%;
    }
}

.process-sections{
    position: relative;
    .wrapper{
        display: flex;
        flex-direction: column;
    }

    &::before{
        position: absolute;
        width: .5rem;
        display: block;
        content: '';
        background-color: #E0F0FA;
        left: 50%;
        top: -35rem;
        transition: height 3s cubic-bezier(0.64, 0.39, 0.03, 1) .5s;
        height: 0;
        .is-visible &{
            height: calc(100% + 40rem);
        }
    }


    &::after{
        position: absolute;
        width: .5rem;
        display: block;
        content: '';
        background: linear-gradient(180deg, white 30%, rgba(255,255,255,0) 84%);
        left: 50%;
        top: -35rem;
        transition: height 3s cubic-bezier(0.64, 0.39, 0.03, 1.24) .5s;
        height: 45rem;
    }

}


.process-section{
    @include column(6);
    display: flex;
    transform: translateX(5rem);
    margin-bottom: 10rem;


    .process-section-row--vertical &{
        @include column(8);
        @include push(2);
        text-align: center;
        flex-direction: column-reverse;
        transform: translate(0);
        //padding-bottom: 20rem;
        @include desktop-small-below{
            @include column(12);
            @include push(0);
        }
    }

    &::after{
        content: '';
        position: absolute;
        top: 0;
        height: 0;
        width: 5px;
        z-index: 1;
        transition: height 1s cubic-bezier(0.694, 0.048, 0.335, 1) 0.2s;
        .process-section-row:nth-child(odd) &{
            right: 4.5rem;
        }
        .process-section-row:nth-child(even) &{
            left: 5rem;
        }

        .process-section-row.process-section-row--vertical &{
            display: none;
        }

    }

    &.process-section--reverse{
        align-self: flex-end;
        flex-direction: row-reverse;
        margin-right: 0;
        transform: translateX(-5rem);
    }
    .is-in-view &{
        &::after{
            height: 100%;
        }
    }
    @include desktop-below{
        @include column(12);
        flex-direction: column-reverse;
        align-items: center;
        margin-right: 0;
        transform: translateX(0rem);
        text-align: center;

        &::after{
            left: 50%;
            height: 10rem;
            margin-top: -6rem;
            width: 3px;
            display: none;
        }


        &.process-section--reverse{
            flex-direction: column-reverse;
            transform: translateX(0rem);
            &::after{
                left: 50%;
                height: 10rem;
            }
        }
    }
}


.process-section__primary{
    padding-right: 6rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: right;
    position: relative;
    z-index: 3;
    .process-section-row--vertical &{
        padding-right: 0;
        text-align: center;
        p{
            font-size: 2.8rem;
            color: #5E8390;
            font-weight: 400;
            strong{
                color: $blue-black;
            }
        }
    }
    .process-section--reverse &{
        padding-right: 0;
        padding-left: 6rem;
        text-align: left;
        @include desktop-below{
            text-align: center;
            padding-left: 0;
        }
    }
    @include desktop-below{
        text-align: center;
        max-width: 75%;
        padding-right: 0;

    }
    @include phone-large-below{
        max-width: 90%;
    }

    p{

    }





}

.process-section--theme-purple{
    color: $purple-black;
    &::after{
        background-color: $green-primary;
    }
    .process-section__orb{
        background-color: $purple-primary;
    }
    .process-section__title{
        color: $purple-saturated;
    }
}
.process-section--theme-blue{
    color: $blue-black;
    &::after{
        background-color: $yellow-primary;
    }
    .process-section__orb{
        background-color: $blue-primary;
    }
    .process-section__title{
        color: $blue-saturated;
    }
}
.process-section--theme-red{
    color: $red-black;
    &::after{
        background-color: $blue-primary;
    }
    .process-section__orb{
        background-color: $red-primary;
    }
    .process-section__title{
        color: $red-saturated;
    }
}
.process-section--theme-yellow{
    color: $yellow-black;
    &::after{
        background-color: $purple-primary;
    }
    .process-section__orb{
        background-color: $yellow-primary;
    }
    .process-section__title{
        color: $yellow-saturated;
    }
}
.process-section--theme-green{
    color: $green-black;
    &::after{
        background-color: $yellow-primary;
    }
    .process-section__orb{
        background-color: $green-primary;
    }
    .process-section__title{
        color: $green-saturated;
    }
}
.process-section--theme-gray{
    color: $blue-black;
    &::after{
        background-color: $yellow-primary;
    }
    .process-section__orb{
        background-color: #E0F0FA;
    }
    .process-section__title{
        color: $blue-black;
    }
}
.process-section__title{
    @extend %heading-three;
    .process-section-row--vertical &{
        @extend %heading-one;
    }

}

.process-section__subtitle{
    @extend %heading-two;
}

.process-section__secondary{

    .process-section-row--vertical &{
        display: flex;
        justify-content: center;
    }


    @include desktop-below{
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }
}



.process-section__orb{
    width: 10rem;
    height: 10rem;
    display: flex;
    background-color: red;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    margin-top: 7rem;
    transition: all 1s cubic-bezier(0.64, 0.39, 0.03, 1.24) .8s;
    transform-origin: 50% 50%;
    transform: scale(0) translateX(-50%);



    .is-in-view:not(.process-section-row--vertical) &{
        transform: scale(1)
    }


    .process-section-row--vertical &{
        transform: scale(1);
        &::before{
            display: block;
            content: url('/images/interface/process_cta_plane-trail.png');
            width: 717px;
            height: 600px;
            position: absolute;
            right: 7.5rem;
            top: 3.5rem;
            @include phone-large-below{
                top: 1rem;
            }

        }
    }



    svg{
        //opacity: 1;
        transition: all 1.3s cubic-bezier(0.64, 0.39, 0.03, 1.24) .9s;
        transform: translate(-2rem, -2rem);
        overflow: visible;
        .process-section--reverse &{
            transform: translate(2rem, -2rem);
        }
        
        .process-section-row--vertical &{
            transform: translate(0, 0) scale(1.5);
        }

        .is-in-view &{
         //   opacity: 1;
         //   transform: translate(-2rem, -2rem) scale(1.1);
        }


        .process-section--reverse.is-in-view &{
           // transform: translate(2rem, -2rem) scale(1.1);
        }


    }

}

.process-section-row{
    position: relative;
    &--vertical{
        overflow: hidden;
        &::after{
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: rotate(180deg);
            background: linear-gradient(180deg, rgba(255,255,255,1) 30%,  rgba(255,255,255,0) 84%);
        }
        margin-top: -5rem;

        .wrapper{
            position: relative;
            z-index: 11;
        }
    }
}

@function grid-width($cols) {
    @return calc((100% / 10) * #{$cols});
}
.process-section-row__decoration{
    max-width: 1800px;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    margin: 0 auto;
    @include desktop-below{
        display: none;
    }
    span{
        display: block;
        width: .5rem;
        height: 0;
        position: absolute;
        transform: translateX(-.2rem);
        transition: height 1s cubic-bezier(0.694, 0.048, 0.335, 1) 0.2s;
       // left: calc((100% / 10) * 7);
      //  left: 0;
        &:nth-child(1){
            transition-delay: 0.5s;
        }
        &:nth-child(2){
            transition-delay: 1s;
        }

        .process-section-row.is-in-view:nth-child(1) &, .process-section-row.is-in-view:nth-child(3) &, .process-section-row.is-in-view:nth-child(5) &{
            &:nth-child(1){
                height: 6rem;
            }
            &:nth-child(2){
                height: 10rem;
            }
        }
        .process-section-row.is-in-view:nth-child(2) &, .process-section-row.is-in-view:nth-child(4) &{
            &:nth-child(1){
                height: 5rem;
            }
            &:nth-child(2){
                height: 8rem;
            }
        }


    }
    .process-section-row:nth-child(odd) &{
        span:nth-child(1){
            left: grid-width(6);
        }
        span:nth-child(2){
            left: grid-width(9);
        }
    }
    .process-section-row:nth-child(even) &{
        span:nth-child(1){
            left: grid-width(2);
        }
        span:nth-child(2){
            left: grid-width(4);
        }
    }
    span:nth-child(1){
        top: 30%;
    }
    span:nth-child(2){
        top: 60%;
    }

}

.process-section-row-decoration--purple{
    background-color: $purple-primary;
}
.process-section-row-decoration--blue{
    background-color: $blue-primary;
}
.process-section-row-decoration--red{
    background-color: $red-primary;
}
.process-section-row-decoration--yellow{
    background-color: $yellow-primary;
}
.process-section-row-decoration--green{
    background-color: $green-primary;
}


