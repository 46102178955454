.content-divider {
    padding: 3rem 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        width: 80%;
        display: inline-block;
        height: 2px;
        background: #dedede;
    }
}