.quote{
    position: relative;
    padding-right: 5rem;

    svg{
        width: 4rem;
        position: absolute;
        left: 0;
        top: 0;
        path{
            fill: $bright-red; 

            .theme-yellow & {
                fill: $bright-yellow;
            }
            .theme-green & {
                fill: $motum-green;
            }
            .theme-purple & {
                fill: $motum-purple;
            }
            .theme-red & {
                fill: $motum-red;
            }
            .theme-blue & {
                fill: $bright-yellow;
            }
        }
    }
    p{
        font-size: 2.2rem;
        padding-left: 7rem;
        &::before{

        }
    }
}
p.quote__author{
    font-weight: 700;
    font-size: 2rem;
    padding-left: 7rem;
    position: relative;
    p{
        position: relative;
        z-index: 1;
    }
    &::after{
        position: absolute;
        width: 6rem;
        height: 2px;
        content: '';
        display: block;
        background-color: rgba($bright-red, 0.25);
        top: 25%;
        left: 0;
        transform: translateY(-50%);

        .theme-yellow & {
            background-color: rgba($bright-yellow, 0.25);
        }
        .theme-green & {
            background-color: rgba($motum-green, 0.25);
        }
        .theme-purple & {
            background-color: rgba($motum-purple, 0.25);
        }
        .theme-red & {
            background-color: rgba($motum-red, 0.25);
        }
        .theme-blue & {
            background-color: rgba($bright-yellow, 0.25);
        }
    }
}