
/* The default outline styling, for greatest accessibility. */
/* You can skip this to just use the browser's defaults. */
.focus-visible {
  outline: #000 auto 2px;
}


/* When mouse is detected, ALL focused elements have outline removed. */
body.using-mouse :focus{
  outline: none !important;
  //TODO: Make selector more efficient
  *, *::before, *::after{
    outline: none !important;
  }
}

/* Screen Reader only element */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.skip-to-content, #skiptocontent {
  left: 10%;
  z-index: -999;
  position: absolute;
  overflow: hidden;

  background:#fff;
  padding:10px;
  font-size:2rem;

  text-align: center;
  text-decoration: none;
  line-height: 150%;
  border-radius: .5rem;

  transform: translateY(-100%);
  transition: transform 0.3s;

  color: #000;

  &:focus {
    color:#111;
  }

  span {
    color: #000;
    font-weight:700;
    background: #fff;
  }
}
.skip-to-content:focus{
  transform: translateY(10%);
  z-index: 9999;
}