@import "_includes";


*{
    box-sizing: border-box;
    // backface-visibility: hidden;
}
html{
    overflow-x: hidden;
    font-size: 62.5%;
    @include desktop-large-below{
        font-size: 60%;
    }
    @include desktop-medium-below{
        font-size: 55%;
    }
    @include desktop-small-below{
        font-size: 52%;
    }
    @include tablet-below{
        font-size: 50%;
    }
    @include phone-large-below{
        font-size: 40%;
    }
}


body{
    font-family: $font-body;
    background-color: white;
    font-feature-settings: "liga" 0;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 1.4rem;
    color: $motum-darkgray;
    transition: background-color .5s ease-in-out;
    &.page-index-index{
        background-color: $motum-darkgray;
    }
    &.home{
        background-color: $motum-darkgray;
    }
    &.light{
        background-color: #fff;
    }
    &.page-people-toronto-agency{
        background-color: $motum-lightgrey;
    }
    &.error-page {
        background-color: $motum-darkgray;
        color: $motum-lightgrey;
    }

   // transition-delay: 1s;

    .to-specialties &{
        background-color: #fff;
    }

}






.list-styles{
    display: inline-block;
    li{
        margin-bottom: 2.5rem;
        padding-left: 2.5rem;
        position: relative;
        &:before{
            font-family: 'icomoon';
            content: '\e904';
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

// a{
//     text-decoration:none;
//     color: $motum-blue;
//     font-weight: 700;
//     &:hover{
//         text-decoration: underline;
//     }
// }

a{
    .main-content &, .page-header-subspecialties &{
        text-decoration: none;
        color: $motum-hyperlink-blue;
        font-family: Roboto, sans-serif;
        letter-spacing: 0.25px;
        transition: 0.2s ease all;
        border-bottom: rgba($motum-hyperlink-blue, 0.25) solid 4px;

        &:hover, &:active, &:focus{
            color: lighten($motum-hyperlink-blue, 5%);
            border-bottom: lighten($motum-hyperlink-blue, 25%) solid 4px;
        }
        .theme-red &{
            color: $motum-hyperlink-red;
            border-bottom: rgba($motum-hyperlink-red, 0.25) solid 4px;
            &:hover, &:active, &:hover{
                color: lighten($motum-hyperlink-red, 15%);
                border-bottom: lighten($motum-hyperlink-red, 25%) solid 4px;
            }
        }
        .theme-yellow &{
            color: $motum-hyperlink-yellow;
            border-bottom: rgba($motum-hyperlink-yellow, 0.25) solid 4px;
            &:hover, &:active, &:hover{
                color: lighten($motum-hyperlink-yellow, 15%);
                border-bottom: lighten($motum-hyperlink-yellow, 25%) solid 4px;
            }
        }
        .theme-green &{
            color: $motum-hyperlink-green;
            border-bottom: rgba($motum-hyperlink-green, 0.25) solid 4px;
            &:hover, &:active, &:hover{
                color: lighten($motum-hyperlink-green, 5%);
                border-bottom: lighten($motum-hyperlink-green, 25%) solid 4px;
            }
        }
        .theme-purple &{
            color: $motum-hyperlink-purple;
            border-bottom: rgba($motum-hyperlink-purple, 0.25) solid 4px;
            &:hover, &:active, &:hover{
                color: lighten($motum-hyperlink-purple, 15%);
                border-bottom: lighten($motum-hyperlink-purple, 25%) solid 4px;
            }
        }
    }
}

h1{
    @extend %heading-one;
}

h2{
    @extend %heading-two;
}
h3{
    @extend %heading-three;
}


/* ==== End Typography ==== */

/* ==== Tables ==== */

.page-thinking-business-to-business-marketing-read, .cke_editable {

    table {
        width: 100%;
        // background-color: $motum-lightgrey;
        margin: 6rem 0;

    }
    thead {
        margin-bottom: 1rem;
    }
    thead tr {
        background-color: $motum-blue;
        border-bottom: 4px solid $motum-grey;
        font-size: 2rem;
        // font-weight: 600;
        // color: #fff;
    }
    tbody tr {
        border-bottom: 2px solid $motum-grey;
    }
    th, td {
        padding: 2rem;
        // border-right: 2px solid $motum-blue;
        &:last-child {
            border-right: none;
        }
    }
    td {
        font-size: 1.6rem;
    }

}

.cke_editable {
    th, td {
        border: 1px dotted $motum-grey;
    }
}

/* ==== End Tables ==== */



/* ==== Button Styling ==== */

.motum-btn {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    border: solid 3px $motum-hyperlink-blue;
    padding: 1.5rem 3rem;
    transition: all 1s cubic-bezier(.53,-.01,0,1);
    //box-shadow: inset 0 0 0 0 $motum-yellow;
    color: $motum-hyperlink-blue;
    position: relative;
    z-index: 10;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 2rem;
    span {
        z-index: 11;
    }
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 100%;
        background-color: lighten($motum-blue, 10%);
        position: absolute;
        top: 0;
        left: 0;
        //right: calc(100% + 3px);
        transition: all 0.5s cubic-bezier(.53,-.01,0,1);
        z-index: -1;
    }
    &:hover, &:active, &:focus {
        color: darken($motum-hyperlink-blue, 5%);
        &:before {
            //right: 0;
            width: 100%;
        }
    }
    .theme-red & {
        border: solid 3px $motum-hyperlink-red;
        color: $motum-hyperlink-red;
        &:before {
            background-color: lighten($motum-red, 10%);
        }
        &:hover, &:active, &:focus{
            color: darken($motum-hyperlink-red, 5%);
        }
    }
    .theme-yellow & {
        border: solid 3px $motum-hyperlink-yellow;
        color: $motum-hyperlink-yellow;
        &:before {
            background-color: lighten($motum-yellow, 10%);
        }
        &:hover, &:active, &:focus{
            color: darken($motum-hyperlink-yellow, 5%);
        }
    }
    .theme-green & {
        border: solid 3px $motum-hyperlink-green;
        color: $motum-hyperlink-green;
        &:before {
            background-color: lighten($motum-green, 10%);
        }
        &:hover, &:active, &:focus{
            color: darken($motum-hyperlink-green, 5%);
        }
    }
    .theme-purple & {
        border: solid 3px $motum-hyperlink-purple;
        color: $motum-hyperlink-purple;
        &:before {
            background-color: lighten($motum-purple, 10%);
        }
        &:hover, &:active, &:focus{
            color: darken($motum-hyperlink-purple, 5%);
        }
    }
}

// .motum-btn{
//   background-color: transparent;
//   border: none;
//   box-shadow: inset 0 0 0 3px $motum-hyperlink-blue;
//   font-family: Roboto, sans-serif;
//   text-transform: uppercase;
//   font-weight: 600;
//   color: $motum-hyperlink-blue;
//   font-size: 2rem;
//   letter-spacing: 1px;
//   padding: 2rem 4rem;
//   //position: relative;
//   transition: 0.5s ease all;
//   transform-origin: center;
//   //left: 50%;
//   //top: 50%;
//   transform: translateY(-50%);
//   overflow: hidden;
//   margin-top: 5rem;
//   text-align: center;
//   max-width: 30rem;
//   span{
//     &:before, &:after{
//       content: '';
//       display: block;
//       position: absolute;
//       background: lighten($motum-hyperlink-blue, 15%);
//       transition: 0.35s ease all;
//       transition-delay: 0.1s;
//     }
//     &:first-child{
//       &:before, &:after{
//         width: 0%;
//         height: 3px;
//       }
//       &:before{
//         top: 0;
//         left: 0;
//       }
//       &:after{
//         bottom: 0;
//         left: 100%;
//       }
//     }
//     &:last-child{
//       &:before, &:after{
//         width: 3px;
//         height: 0%;
//       }
//       &:before{
//         top: 0%;
//         left: 0;
//       }
//       &:after{
//         top: 100%;
//         right: 0;
//       }
//     }
//   } //end span
//   &:before, &:after{
//     content: '';
//     display: block;
//     width: 0%;
//     height: 90%;
//     background: lighten($motum-hyperlink-blue, 15%);
//     position: absolute;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//     z-index: -1;
//     transition: all 0.25s ease-in-out .2s;
//     transition-delay: 0.5s;
//   }
//   &:after{
//     background: lighten($motum-hyperlink-blue, 25%);
//     transition: 0.25s ease-in-out .2s;
//     transition-delay: 0.4s;
//     width: 80%;
//     left: -80%;
//   }
//   &:hover, &:active, &:focus{
//     outline: none;
//     box-shadow: inset 0 0 0 0 white;
//     //padding: 15px 50px;
//     //margin-top: 4.5rem;
//     color: white;
//     &:before{
//       width: calc(100% - 6px);
//       left: 3px;
//       box-shadow: inset 0 0 0 3px white;
//     }
//     &:after{
//       left: 100%;
//       box-shadow: inset 0 0 0 3px white;
//     }
//     span{
//       &:first-child{
//         &:before, &:after{
//           width: 100%;
//         }
//         &:before{
//           top: 0;
//           left: 0;
//         }
//         &:after{
//           bottom: 0;
//           left: 0;
//         }
//       }
//       &:last-child{
//         &:before, &:after{
//           height: 100%;
//         }
//         &:before{
//           top: 0;
//           left: 0;
//         }
//         &:after{
//           top: 0;
//           right: 0;
//         }
//       }
//     } //end span
//   }
//   .theme-red &{
//     box-shadow: inset 0 0 0 3px $motum-hyperlink-red;
//     color: $motum-hyperlink-red;
//     span{
//         &:before, &:after{
//             background: lighten($motum-hyperlink-red, 15%);
//         }
//     }
//     &:before{
//         background: lighten($motum-hyperlink-red, 15%);
//     }
//     &:after{
//         background: lighten($motum-hyperlink-red, 25%);
//     }
//     &:hover, &:active, &:focus{
//         color: white;
//     }
//   } //end .reb-btn &
//   .theme-green &{
//     box-shadow: inset 0 0 0 3px $motum-hyperlink-green;
//     color: $motum-hyperlink-green;
//     span{
//         &:before, &:after{
//             background: lighten($motum-hyperlink-green, 15%);
//         }
//     }
//     &:before{
//         background: lighten($motum-hyperlink-green, 15%);
//     }
//     &:after{
//         background: lighten($motum-hyperlink-green, 25%);
//     }
//     &:hover, &:active, &:focus{
//         color: white;
//     }
//   } //end .green-btn &
//   .theme-yellow &{
//     box-shadow: inset 0 0 0 3px lighten($motum-hyperlink-yellow, 5%);
//     color: lighten($motum-hyperlink-yellow, 5%);
//     span{
//         &:before, &:after{
//             background: lighten($motum-hyperlink-yellow, 15%);
//         }
//     }
//     &:before{
//         background: lighten($motum-hyperlink-yellow, 15%);
//     }
//     &:after{
//         background: lighten($motum-hyperlink-yellow, 25%);
//     }
//     &:hover, &:active, &:focus{
//         color: white;
//     }
//   } //end .yellow-btn &
//   .theme-purple &{
//     box-shadow: inset 0 0 0 3px $motum-hyperlink-purple;
//     color: $motum-hyperlink-purple;
//     span{
//         &:before, &:after{
//             background: lighten($motum-hyperlink-purple, 15%);
//         }
//     }
//     &:before{
//         background: lighten($motum-hyperlink-purple, 15%);
//     }
//     &:after{
//         background: lighten($motum-hyperlink-purple, 25%);
//     }
//     &:hover, &:active, &:focus{
//         color: white;
//     }
//   } //end .purple-btn &
//   .grey-btn &{
//     box-shadow: inset 0 0 0 3px darken($motum-darkgray, 10%);
//     color: darken($motum-darkgray, 10%);
//     margin: 0 auto;
//     margin-top: 5rem;
//     span{
//         &:before, &:after{
//             background: $motum-darkgray;
//         }
//     }
//     &:before{
//         background: $motum-darkgray;
//     }
//     &:after{
//         background: darken($motum-darkgray, 10%);
//     }
//     &:hover, &:active, &:focus{
//         color: white;
//     }
//   } //end .purple-btn &
// }

/* ==== End Button Styling ==== */


.wrapper{
    max-width: 1496px;

    width: 100%;
    margin: 0 auto;
    position: relative;
    &.large{
        max-width: 1700px;
    }
    &.wide{
        max-width: calc(100vw - 60px);
    }
    &.page-container{
        padding: 0 3rem;
    }

    @include desktop-large-below{
        padding: 0 5rem;
    }

    @include phone-large-below{
        padding: 0 2rem;
    }


}


//
//
//.row{
//   // margin: 0 auto;
//    position: relative;
//    display: block;
//    @include column(12);
//    @include clearfix();
//    &--max{
//        max-width: 1360px;
//        width:100%;
//        margin:0 auto;
//    }
//    &--btn{
//        margin:1.5rem 0;
//        .btn{
//            display:inline-block;
//            margin-top:1.5rem;
//        }
//    }
//    &--padding{
//        padding:2rem 0;
//    }
//    &--spacer{
//        margin:2rem;
//    }
//}



.btn{
    padding:1.2rem;
    font-size:1.1em;
    color:#fff;
    border-radius:6px;
    color:#fff;
    font-weight:700;
    text-transform:uppercase;
    // float: right;

    @include transition(0.3s);
    &--big{
        font-size:1.4em;
        padding:1.5rem;
        border-radius:10px;
        margin-right:3rem;
        display:inline-block;
    }
    &:hover{
        @include transition(0.3s);
        background-color:$colour-primary;
        padding-right:0.5rem;
    }
}
strong{
    font-weight: 700;
    color: #000;
}




// ===== Sub Navigation =====
.sub-nav, .inline-nav{
    background-color: #414141;
    @include column(12);
    ul{
        margin-bottom:2rem;
        max-width: 1360px;
        float: none;
        margin: 0 auto;
        width: 100%;
        display: flex;

        @include tablet-below{
            display: none;
        }
    }


    li{
        @include tablet-below{
            font-size:0.8em;
            padding:1.5rem 0.5rem;
        }
        display:inline-block;
        position: relative;
        transition: background .15s ease;
        &:hover{
            background: #343434;
            a{
                color: #fff;
                text-decoration: none;
            }
        }

        &:after{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $motum-red;
            content: '';
            display: block;
            opacity: 0;
        }

        &.active{
            background:#343434;
            &:after{opacity: 1;}
            a{
                color: #fff;
            }

        }

        @for $i from 1 through 14{
            &:nth-child(#{$i}) a{
                animation: fadeRiseIn .5s ease-in-out ((.07s * $i)) 1 forwards normal;
            }
        }

        > ul{
            display: none;
        }
    }
    a{
        color:#d8d8d8;
        font-weight:600;
        text-align: center;
        display: block;
        padding:1.5rem;
        font-size: .9em;
        position: relative;
        transition: color .15s ease;

        transform: translateY(20px);
        opacity: 0;
    }

}

.inline-nav{
    background-color: #f0f0f0;
    //border-bottom: 1px solid #ccc;
}

.inline-nav ul.navigation{
    max-width: 1360px;
    justify-content: left;

    li{

        a{
            //text-decoration: underline;
            color: #8C8888;
            text-transform: none;
            font-size: 0.8em;
        }
        &:hover a{
            text-decoration: none;
        }


        &.active{
            background: #e4e4e4;

            a{
                color: #414141;
            }

            &:after{
                display: none;
            }
        }

        &:not(.active):hover{
            background: #f0f0f0;

            a{
                color: #414141;
                text-decoration: none;
            }
        }
    }
}

/* Dropdown Nav */
.main-nav ul li ul{
    width:200px;
    top: 24px;
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index:7;
    opacity:0;
    visibility:hidden;
    background-color: #58595b;
    display: none;
    // transition: all .1s ease-in-out;

    li{
        background-color: #58595b;
        transition: background-color .15s ease;
    }
    li a{
        color: #afafaf;
        text-transform: none;
        padding:1.5rem;
        display: block;
        transition: color .15s ease;
        i{
            display: none;
        }
    }
    li:hover, li.active{
        background-color: #696969;
        a{
            color: #fff;
        }
    }


    li:last-child.active + :after{
        border-color: red;
    }

    li.has-subnav{
        > a:after{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1em;
            font-weight: 300;
            display: block;
            content: '\e91f';
            font-family: 'icoMoon';
        }
    }


    &:before{
        display: block;
        content: '';
        position: absolute;
        top: -12px;
        width: 0;
        height: 0;
        border-left: 100px solid transparent;
        border-right: 100px solid transparent;
        border-bottom: 12px solid #58595b;
    }

    &:after{
        display: block;
        content: '';
        position: absolute;
        bottom: -12px;
        width: 0;
        height: 0;
        border-left: 100px solid transparent;
        border-right: 100px solid transparent;
        border-top: 12px solid #58595b;
    }

}

li.has-subnav ul.subnav li.has-subnav{
    position: relative;

    // &:after{
    //     content: '>';
    //     position: absolute;
    //     top: 15px;
    //     right: 5px;
    // }

    ul{
        left: 100%;
        transform: translateX(0);
        top: 0;
        transition: none;
        display: none;
        opacity: 1;
        visibility: visible;

        background-color: blue;

        &:before{
            border-bottom-color: darken(#696969, 20%);
        }

        &:after{
            border-top-color: darken(#696969, 20%);
        }

        li{
            background-color: darken(#696969, 20%);
        }

        li a{
            color: #afafaf;

            &:hover{
                color: #fff;
            }
        }
    }


    &:hover{
        ul{
            display: block;
        }
    }
}




/* Mobile Nav */
.main-nav__list--mobile{
    display:none;
    padding:0;
    border-top:1px solid #f8f8f8;
    transition:none ;
    @include column(12);
    &:hover{
        transition:none;
    }
    li{
        display:block;
        padding:1.5rem 0;
        position:relative;
        border-bottom:1px solid #f8f8f8;
        i{
            position:absolute;
            right:28px;
            top:5px;
            color:#fff;
            font-size:2.3em;
            cursor:pointer;
        }
        a{
            padding:0 1.5rem;
            color:#fff;
            &:hover{
                color:#fff;
            }
        }
        ul{

            position:relative;
            background:$colour-primary-dark;
            border-top: 3px solid rgba(0, 0, 0, 0.2);
            visibility:visible;
            opacity:1;
            padding:0;
            top:0;
            left:0;
            margin-top:1.2rem;
            margin-bottom:-1.5rem;
            display:block;
            transition:none ;
            &:hover{
                transition:none ;
            }
            li{
                border:none;
            }
        }
    }
}



// ===== Container =====

.container{
    //clear:both;
    //width:100%;
    //overflow: hidden;
    //min-height: 100vh;
    //@extend .typography-styles;
    //display: flex;
    //flex-direction: column;
    height: 100%;
    //text-align: center;
    //padding: 20px;
    // overflow: auto; //auto causes stacking context issue on real phones with modal backdrop when modals are open
    overflow:initial; 
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    clear: both;
    width: 100%;

    // Main layout
    &--with-sidebar{
        max-width:1200px;
        width:100%;
        margin:0 auto;
        display:block;
        @include clearfix;
    }
    &--full{
        max-width: none;
        .container__content{
            @include column(12);
        }
    }
    &--no-sidebar-subnav{
        max-width:1360px;
        width:100%;
        margin:0 auto;
        display:block;
        @include clearfix;

        .container__content{
            @include column(12);
        }
    }



    &--no-sidebar{
        max-width:1360px;
        width:100%;
        margin:0 auto;
        display:block;
        @include clearfix;

        .container__content{
            @include column(12);
        }
        .main-content{
            width: 100%;
        }

    }
    &--no-header{
        margin-top:20px;
        .container__sidebar{
            margin-top:0;
        }
    }
    &--with-sidebar{
        .container__sidebar{
            margin-top:2rem;
            padding:1rem;
        }
    }

    // Main Content area
    &__content{
        @include column(9);
        @include desktop-small-below{
            @include column(8);
        }
        @include tablet-below{
            @include column(12);
        }
    }
    // Sidebar
    &__sidebar{
        @include column(3);
        background:#fff;
        margin-top:-10rem;
        position:relative;
        z-index:5;
        @include desktop-small-below{
            @include column(4);
        }
        @include tablet-below{
            margin-top:1rem;
            @include column(12);
        }
    }
}




.main-content{
    width: 100%;
    @include column(7);
    padding: 3rem 3rem 3rem 0;


    &.form-container{
        padding-top: 0;
        @include column(9);
        @include desktop-below{
            width: 100%;
            padding-right: 0;
        }
        .contact-wrapper--wide & {
            @include column(8);
            @include desktop-below {
                @include column(12);
            }
        }
    }

    


    @include desktop-medium-below{
        //padding: 3rem;
    }

    h2{
        padding-bottom: 2rem;
        font-size: 2.5em;
        color: #3d3d3d;
    }

    h3{
        padding: 2rem 0 1rem;
        font-size: 2rem;
        color: #3d3d3d;
    }

    p, li{
        line-height: 1.9;
        margin-bottom: 1.4rem;
        color: $motum-darkgray;
    }

    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    ul li{
        padding-left: 1.5rem;
        background-image: url('/images/interface/bullet.gif');
        background-position: left 8px;
        background-repeat: no-repeat;
    }

    ol{
        padding-left: 1.5rem;
        list-style-type: decimal;
    }

    @include desktop-medium-below{
        font-size: .9em;
    }
    @include desktop-below{
        font-size: .8em;
        @include column(12);
    }

    @include tablet-below{
        @include column(12);
    }

    @include phone-large-below{
        font-size: 0.9em;
    }

    &--wide{
        @include column(10);
        @include desktop-small-below{
            @include column(10);
        }
        @include phone-large-below{
            @include column(12);
        }

    }

    &--blog{
        @include column(8.5);
        padding-right: 10rem;
        @include phone-large-below{
            @include column(12);
        }
        img{
            max-width: 100%;
        }

    }

    &--full-width{
        @include column(12);
        max-width: 1360px;
        margin: 0 auto;
        float: none;
    }

    &--centered{
        @include column(12);
        max-width: 800px;
        margin: 0 auto;
        float: none;
        font-size: 1.4rem;
    }
}


.blog-sidebar{
    margin-top: 25px;
    @include column(3.5);
    @include phone-large-below{
        @include column(12);
    }
}






@keyframes peekIn{
    100%{
        transform: translateY(0);
    }
}




@keyframes fadeRiseIn{
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}




@keyframes logoSize{
    from{
        transform:scale(0);
    }
    to{
        transform:scale(1);
    }
}




// .mobile-nav-block .google-search{
.google-search{
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    width: 100%;
    margin: 25px 0;
    padding: 0 2rem;
    float: left;

    .gsc-input input.gsc-input{
        padding: 0.7rem 0.7rem 0.7rem 2.8rem ;
        font-size: 1rem ;
        height: 28px ;
        font-family: $font-body;
        width: 100%;

    }


    .gsc-input{
        width: 100%;
    }

    ::-webkit-input-placeholder{
        color: #fff;
    }

    :-moz-placeholder{ /* Firefox 18- */
        color: #fff;
    }

    ::-moz-placeholder{  /* Firefox 19+ */
        color: #fff;
    }

    :-ms-input-placeholder{
        color: #fff;
    }
    .gsib_a{
        padding: 0;
    }
    #gs_tti51:after{
        left: 10px;
    }
    .gsc-search-button{
        display: block;
        background-color: darken($motum-red, 10%);
        height: 30px;
        width: 52px;
        position: relative;
        border: 0;
        input{
            border: 0;
        }
        &:after{
            pointer-events: none;
            font-family: 'icoMoon';
            content: '\e923';
            font-size: .9em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}




.toggle-search{
    background: transparent;
    border: 0;
    color: #cdcdcd;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 1rem;
    margin-top: -10px;
    transition: all .15s ease-in-out;
    transform-origin: 50% 50%;
    &:hover{
        color: #fff;
    }
}




.btn-square{
    display: inline-block;
    border: 3px solid #4b4b4b;
    color: #4b4b4b;
    padding: 1rem 2rem;
    font-weight: 700;
    transition: all .15s ease;
    &.uppercase{
        text-transform: uppercase;
    }
    &.orange{
        color: $motum-red;
        border-color: $motum-red;
        &:hover{
            color: #fff;
            background-color: $motum-red;
            text-decoration: none;
            // border-color: darken($motum-red, 20%);
        }
    }
    &.blue{
        color: $motum-blue;
        border-color: $motum-blue;
        &:hover{
            color: darken($motum-blue, 20%);
            border-color: darken($motum-blue, 20%);
        }
    }
    &.lightblue{
        color: $motum-blue;
        border-color: $motum-blue;
        &:hover{
            color: lighten($motum-blue, 50%);
            border-color: lighten($motum-blue, 50%);
        }
    }
    &.grey{
        color: #939393;
        border-color: #939393;
        &:hover{
            text-decoration: none;
            color: $motum-red;
            border-color: $motum-red;
        }
    }
    &.large{
        font-size: 1.4rem;
        padding: 8rem 12rem;
        border-width: 4px;

        @include phone-large-below{
            font-size: 2rem;
        }
    }
    &:hover{
        text-decoration: none;
    }
}



.header__mobile-menu-button{
    //background: $motum-red;
    background: transparent;
    border: 0;
    position: absolute;
    top: -19px;
    right: 21px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    display: none;
    @include tablet-below{
        display: block;
    }
    &:hover{
        background-color: darken($motum-red, 10%);
    }
    &:before{
        font-family: 'icoMoon';
        content: "\e901";
        color: #fff;
        font-size: 3rem;
        line-height: 0.9;
    }
}




.scroll-container
{
    position: fixed;
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar{
        //display: none;
    }
}

.site-search{
    background-color: rgba(0,0,0,.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    .site-search__subtitle{
        color: $motum-red;
        font-weight: 700;
        font-size: 1.8rem;
    }
}



.homepage-video-player{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    //display: none;
    transition: opacity .2s ease-in-out;
    z-index: 16;
    video{
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 1;
        transform: translateX(-50%) translateY(-50%);
        //background: url(polina.jpg) no-repeat;
        //background-size: cover;

        //mix-blend-mode: overlay;

        opacity: 0;
        transition: opacity .5s ease-in-out;
        &.visible{
            opacity: .25;
        }
        @include tablet-below{
            display: none;
        }
    }



    .video-cover{
        transition: background-color .5s ease-in-out;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%);
    }

    &[active-video=branding] .video-cover{
        background-color: $motum-blue;
    }
    &[active-video=analytics] .video-cover{
        background-color: $motum-yellow;
    }

    .is-exiting &{
        opacity: 0;
    }

}




// Video Styles


@keyframes playIcon{
    0%{
        transform:scale(1);
    }
    50%{
        transform:scale(1.1);
    }
    100%{
        transform:scale(1);
    }
}
.wistia_click_to_play{
    width:100%!important;
    height:auto!important;
}
.portfolio-video{
    //overflow:auto;
    position:relative;
    cursor:pointer;
    margin-top:0!important;
    .wistia_click_to_play{
        width:100%!important;
        height:auto!important;
    }
    .portfolio-video__image{
        width:100%;
    }
    .portfolio-video__overlay{
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.5);
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
        color:#fff;
        transition: all .8s cubic-bezier(.64,.39,.03,1.24);
        .portfolio-video__icon{
            display:block;
            @include responsive-font(30.2vw, 75px, 150px);
            transform:scale(1);
            transition: all .8s cubic-bezier(.64,.39,.03,1.24);
            @include tablet-below{
                font-size:18rem;
            }
            @include phone-large-below{
                font-size:12rem;
            }
        }
        .portfolio-video__text{
            display:block;
            @include responsive-font(15.2vw, 20px, 30px);
            text-transform:uppercase;
            opacity:0;
            transform:translateY(-50%);
            transition: all .8s cubic-bezier(.64,.39,.03,1.24);
            @include tablet-below{
                font-size:3.5rem;
            }
            @include phone-large-below{
                font-size:2.5rem;
            }
        }
    }
    &:hover{
        .portfolio-video__overlay{
            background:rgba(0,0,0,0.7);
            transition: all .8s cubic-bezier(.64,.39,.03,1.24);
        }
        .portfolio-video__icon{
            transform:scale(1.1);
            animation:playIcon 1s cubic-bezier(.64,.39,.03,1.24) infinite;
            transition: all .8s cubic-bezier(.64,.39,.03,1.24);
        }
        .portfolio-video__text{
            opacity:1;
            transform:translateY(25%);
            transition: all .8s cubic-bezier(.64,.39,.03,1.24);
        }
    }
}


.blog-header{
    position:relative;
    width:100%;
    height:auto;
    padding: 17rem 0 12rem 0;
    z-index:3;
    margin:0 auto;
    overflow:hidden;
    background-position: center center;
    background-size: cover;
    //transition: all 5s ease-in-out;
    //background-attachment: fixed;
    //background-repeat: no-repeat;
    &.featured{
        min-height: 100vh;
    }

    .fade{
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .7;
    }
    p{
        color:#c6c6c6;
        font-size:1.35em;
        line-height: 1.45em;
        margin-bottom: 20px;
        display: inline-block;
        @include tablet-below{
            font-size:1em;
        }
    }
    strong{
        color: $motum-darkgray;
    }

    i{
        font-size: 6.5em;
        color: $motum-red;
        float: left;
        margin-right: 2rem;
        transform: translateY(20px);
        opacity: 0;
        animation: fadeRiseIn .5s ease-in-out 1 forwards normal;
        @include desktop-below{
            display: block;
            float: none;
            font-size: 5em;
            margin-right: 0;
            margin-bottom: 1rem;
        }
        @include tablet-below{
            font-size: 4em;
        }
    }

    .blog-header__content{
        @include column(10);
        float: none;
        margin: 0 auto;
        position: relative;
        z-index: 15;
        @include desktop-medium-below{
            //@include column(9);
            // padding-left: 20px;
        }
        @include desktop-below{
            text-align: center;
            @include column(12);
            padding: 0 4rem;
        }
        @include phone-large-below{
            padding: 0 1.5rem;
        }
    }


    .blog-header__date{
        color:rgba(250,250,250,0.7);
        font-size:1.8rem;
        display:block;
        padding-top:1rem;
    }
    .blog-header__author{
        color:rgba(250,250,250,0.7);
        font-size:1.8rem;
    }
    .blog-header__subtitle{
        font-weight: 700;
        color: #fff;
        font-size: 2.9rem;
        display: block;
        padding: 2.1rem 0;
        line-height: 1.3;
        //transform: translateY(20px);
        //opacity: 0;
        //animation: fadeRiseIn .5s ease-in-out 1 forwards normal;
        @include tablet-below{
            font-size:1.8em;
        }
    }
    .blog-header__type{
        color:#fff;
        font-size:1.8rem;
        padding-bottom:1rem;
        text-transform:uppercase;
        display:block;
    }
    .blog-header__title{
        color:#fff;
        font-size: 8.5rem;
        padding: 2rem 0 1.5rem 0;
        font-weight: 700;
        overflow: hidden;
        position: relative;

        //margin-bottom: 30px;
        //transform: translateY(20px);
        //opacity: 0;
        //animation: fadeRiseIn .5s ease-in-out .2s 1 forwards normal;
        &:before{
            position: absolute;
            width: 100%;
            height: 5px;
            background-color: #fff;
            display:block;
            content: '';
            left: 0;
            top: 0;
        }
        @include tablet-small-below {
            font-size: 7rem;
        }
        @include phone-large-below {
            font-size: 5.5rem;
        }

    }

    .blog-header__background{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position-x: 50%;
    }

}



.blog-content, .cke_editable{
    padding: 4rem 0;
    position: relative;
    @include column(8);
    @include desktop-below{
        width: 80%;
    }
    @include tablet-below{
        width: 90%;
    }
    @include phone-large-below {
        padding-right: 2rem;
    }
    float: none;
    margin: 0 auto;
    p, ul li, ol li, .blockquote{
        font-size: 2rem;
        line-height: 3.3rem;
        letter-spacing: 1;
        font-family: 'Lora', Times, "Times New Roman", serif;
    }
    p{
        font-weight: 100;
    }
    a{
        font-weight: 400;
    }
    ul li{
        padding-left: 2.5rem;
        position: relative;
        line-height: 2.3rem;
        margin-bottom: 1.5rem;
        color: $motum-grey;
        &::before{
            content: '\e904';
            font-family: 'icomoon';
            display: block;
            // width: 7px;
            // height: 7px;
            font-size: 1.5rem;
            color: $motum-red;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
    ol li{
        color: $motum-grey;
        padding-left: 1.5rem;
        //     &::before{
        //         content: counter(li);
        //         display: inline-block;
        //         color: $motum-blue;
        //     }
        //     counter-increment: li;
    }
    ul, ol{
        padding: 2rem 0;
    }
    ol{
        list-style-type:decimal-leading-zero;
        padding-left: 3rem;
    }
    h2{
        margin-top: 5rem;
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 3rem;
        color: #2a2a2a;
        line-height: 6.5rem;
    }
    h3, h4{
        margin-top: 5rem;
        font-size: 3.75rem;
        font-weight: 700;
        margin-bottom: 2rem;
        color: #2a2a2a;
        line-height: 4.5rem;
    }
    h4{
        font-size: 3.35rem;
        padding-top: 1rem;
    }
    img{
        display: block;
        margin: 0 auto;
        margin-bottom: 2rem;
        max-width: 100%;
    }
    @include tablet-below{
        h2, h3, h4{
            line-height: auto;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .line-break-thin{
        border-top: 0.2rem solid $motum-darkgray;
        margin: 3rem 0;
    }
    .line-break-thick{
        border-top: 0.8rem solid $motum-darkgray;
        margin: 3rem 0;
    }
    .callout{
        position: relative;
        padding: 1rem 0 4rem;
        margin: 7rem 0;
        h2{
            background-color: white;
            text-align: center;
            color: $motum-grey;
            padding: 1rem;
        }
        h3, h4{
            text-align: center;
            color: $motum-grey;
        }
        p{
            //background-color: whitesmoke;
            width: 70%;
            margin: 0 auto;
            padding: 1.5rem;
            color: $motum-grey;
            @include phone-large-below{
                width: 85%;
                text-align: center;
            }
        }
        &:before{
            content: '';
            display: block;
            border: 8px solid $motum-blue;
            height: 100%;
            width: 80%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            @include phone-large-below{
                width: 90%;
            }
        }
    }
    .callout2{
        position: relative;
        margin: 4rem 0;
        background-color: whitesmoke;
        padding: 3rem 3rem 1rem;
        h2, h3, h4{
            margin-top: 2rem;
            color: $motum-grey;
        }
        p{
            color: $motum-grey;
        }
        &:before{
            content: '';
            display: block;
            width: 25%;
            height: 8px;
            background-color: $motum-red;
        }
    }
    blockquote, twitterwidget, .twitter-tweet {
        display: block;
        margin: 0 auto;
    }
    twitterwidget, .twitter-tweet {
        margin-top: 3rem;
    }
    .blockquote{
        color: $motum-grey;
        margin: 0 auto;
        margin: 4rem 0 3rem;
        padding: 2rem 11rem;
        position: relative;
        &:before, &:after{
            display: block;
            font-size: 14rem;
            position: absolute;
            width: 100%;
            height: 100%;
            font-weight: bold;
            font-family: $font-body;
            letter-spacing: 0.5;
        }
        &:before{
            content: '\e909';
            top: 0;
            left: 0;
            font-family: icomoon;
            font-size: 7rem;
        }
        &:after{
            content: '\e908';
            top: 75%;
            left: 95%;
            font-family: icomoon;
            font-size: 7rem;
        }
        p:last-child{
            color: $motum-red;
            text-align: right;
            font-style: italic;
        }
        @include desktop-small-below{
            //background-color: lightpink;
            padding: 2rem 8rem 2rem 10rem;
        }
        @include desktop-below{
            //background-color: lightblue;
            padding: 2rem 6rem 2rem 8rem;
            &:before{
                top: 10%;
            }
        }
        @include tablet-below{
            //background-color: lightgreen;
            padding: 3rem 2rem 3rem 4rem;
            &:before{
                top: 5%;
            }
            &:after{
                top: 98%;
            }
        }
        @media (max-width: 400px){
            //background-color: lightgreen;
            //padding-top: 5rem;
            padding: 3rem 2rem;
            &:before{
                top: 2%;
            }
        }
    }
    .author{
        margin: 15rem 10rem 10rem;
        position: relative;
        p{
            font-family: $font-body;
            padding-left: 2rem;
            font-weight: 600;
            &:first-child{
                font-size: 5rem;
            }
            &:nth-child(2){
                letter-spacing: 1px;
            }
            &:nth-child(3){
                width: 65%;
                font-weight: 400;
                line-height: 1.25;
                padding-bottom: 2rem;
            }
        }
        &::before{
            content: '';
            display: block;
            background-color: whitesmoke;
            width: 100%;
            height: 100%;
            top: 1.65rem;
            left: 0;
            position: absolute;
            z-index: -1;
            border-bottom: 8px solid $motum-red;
        }
        @include desktop-small-below{
            //background-color: lightgreen;
            margin: 15rem 0 10rem;
        }
        @include tablet-below{
            p:nth-child(3){
                width: 100%;
                padding-right: 2rem;
            }
            &::before{
                top: 2rem;
            }
        }
    }

}

.mobile-only{
    display: none;
    @include tablet-below{
        display: block;
    }
}

.desktop-only{
    @include tablet-below{
        display: none;
    }
}


.article-list{
    z-index: 5;
    position: relative;
    @include column(12);
    margin-bottom: 5rem;
    margin-top: 4rem;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    &:after{
        content:"";
        display:block;
        float:left;
        @include column(2.92);
        @include desktop-below{
            @include column(5.92);
        }
        @include phone-large-below{
            @include column(12);
        }
    }
    .article-block{
        &:last-child{
            padding-right:0;
        }
    }
}

.grid-sizer, .article-block{
    @include column(2.92);
    @include desktop-below{
        @include column(5.92);
    }
    @include phone-large-below{
        @include column(12);
    }
    &.featured{
        @include column(5.84);
        @include phone-large-below{
            @include column(12);
        }
    }
}

.gutter-sizer{
    @include column(.1);
    float: none;
}
.article-block{
    display: block;
    //@include column-margin-equal(.1);
    margin-bottom: 4rem;
    //margin-top: 30px;
    text-decoration: none;
  //  transition: all .8s cubic-bezier(.64,.39,.03,1.24);
    //
    //.is-entering &, .is-visible &{
    //    opacity: 1;
    //    transform: translateY(0);
    //}
    //.is-exiting &{
    //    opacity: 0;
    //    transform: translateY(2rem);
    //}

    transition: all .8s cubic-bezier(.64,.39,.03,1.24);
    opacity: 0;
    .is-visible &{
        opacity: 1;
    }



    @for $i from 1 through 25{
        &:nth-child(#{$i}){
            transition: all .8s cubic-bezier(.64,.39,.03,1.24) (.3s + (.02s * $i));
        }
    }






    &:not(.grid-sizer){
        // border-bottom: 1px solid #999999;
    }
    > div{
        //background-color: $motum-lightgrey;
        border-radius: 3px;
    }
    .article-block__image{
        width:100%;
        img{
            width:100%;
        }
        //height: 180px;
        //background-color: #d2d2d2;
        // @include desktop-below{
        //     max-height: 19rem;
        //     overflow: hidden;
        // }

        //background-image: url('/images/interface/blog_med_placeholder.jpg');
    }



    &.featured{
        .article-block__content{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3rem;
        }
        .article-block__image{
            position: relative;
            img{
                display: block;
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.5);
                transition: all .15s ease-in-out;
            }
        }
        .article-block__title, .article-block__subtitle{
            color: #fff;
        }
        .article-block__title{
            font-size: 5.5rem;
            line-height: 1.05;
            @include desktop-below{
                font-size: 3.5rem;
            }
            @include tablet-below{
                font-size: 2.5rem;
            }
            @include phone-large-below{
                font-size: 4rem;
            }
        }
        .article-block__subtitle{
            font-size: 2rem;
            border-bottom: 4px solid #fff;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            text-transform: uppercase;

            @include tablet-below{
                border-bottom: 3px solid #fff;
            }
            @include phone-large-below{
                border-bottom: 2px solid #fff;
            }
        }
        &:hover .article-block__image:after{
            background-color: rgba(0,0,0,.7);
        }
        //.article-block__properties{
        //  opacity: 0;
        //}
    }

    .article-block__content{
        padding: 1rem 2rem 0 0;
    }
    .article-block__title{
        font-size: 2.35rem;
        font-weight: 700;
        color: #3d3d3d;
        margin-bottom: 0.4rem;
        display: block;
        line-height: 2.5rem;
    }
    .article-block__date, .article-block__subtitle{
        font-weight:600;
        padding-bottom:0.6rem;
        display:block;
        color:$motum-grey;
    }
    .article-block__subtitle{

    }
    .article-block__properties{
        font-size: 1.7rem;
        color: #3b3b3b;
        margin-bottom: 1rem;
        border-bottom: 3px solid #3b3b3b;
        padding: 0 0 0.8rem 0;
        display: flex;
        justify-content: space-between;
        .type{

            text-transform: uppercase;
        }
    }

    p{
        font-size: 1.5rem;
        color: #556069;
        line-height: 1.4;
        font-weight: 400;
    }

    &.large{
        @include column(5.92);
        @include desktop-below{
            @include column(12);
        }
        .article-block__image{
            height: 370px;
        }
        .article-block__title{
            font-size: 4rem;
            line-height: 4.4rem;
        }
        p{
            font-size: 2rem;
        }
    }


    &:hover{
        text-decoration: none;
        //.article-block__title{
        //    text-decoration: underline;
        //}
        //transform: translateY(-3px);
        //box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.05);
    }

}

//.material-form-container{
//
//}

// %valid-input-style{
//   border-bottom: 1px solid yellow;
//   box-shadow: 0 1px 0 0 yellow;
// }
// %invalid-input-style{
//   border-bottom: blue;
//   box-shadow: 0 1px 0 0 blue;
// }


// #contactUs{

//     // ============== Form Styling ==============  //


//     // input:not([type]),
//     // input[type=text]:not(.browser-default),
//     // input[type=password]:not(.browser-default),
//     // input[type=email]:not(.browser-default),
//     // input[type=url]:not(.browser-default),
//     // input[type=time]:not(.browser-default),
//     // input[type=date]:not(.browser-default),
//     // input[type=datetime]:not(.browser-default),
//     // input[type=datetime-local]:not(.browser-default),
//     // input[type=tel]:not(.browser-default),
//     // input[type=number]:not(.browser-default),
//     // input[type=search]:not(.browser-default),
//     // textarea.materialize-textarea{
//     //         border-bottom-color: yellow;
//     //         &:focus:not([readonly]){
//     //             border-bottom: 1px solid $motum-blue;
//     //             box-shadow: none;
//     //         }
//     //     }
//     input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea{
//         font-size: 1.75rem ;
//         font-weight: 500;
//         line-height: 2rem;
//         //border-bottom-color: transparent;
//     }
//     .input-field label{
//         font-size: 1.75rem ;
//         font-weight: 400;
//         transform: translateY(5px);
//         color: #878787;
//         //top: -10px;
//     }
//     .select-wrapper + label, .input-field label.active{
//         font-size: 1.5rem ;
//         color: #878787;
//         font-weight: 600;
//     }
//     .input-field label:not(.label-icon).active{
//         transform: translateY(-14px) scale(0.8);
//     }
//     .select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup{
//         color: $motum-red ;
//         background-color: whitesmoke ;
//     }
//     .dropdown-content li:hover, .dropdown-content li.active, .dropdown-content li.selected, .dropdown-content li:hover, .dropdown-content li.active, .dropdown-content li.selected{
//         //background-color: $motum-blue ;
//     }
//     .dropdown-content{
//         margin-top: 6px ;
//         overflow: scroll;
//     }
//     .z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-floating, .dropdown-content, .collapsible, .side-nav{
//         box-shadow: none ;
//         border: 2px solid whitesmoke;
//     }
//     [type="checkbox"] + label:before, [type="checkbox"]:not(.filled-in) + label:after{
//         border: 2px solid $motum-blue ;
//     }
//     [type="checkbox"]:checked + label:before{
//         border-top: 2px solid transparent ;
//         border-left: 2px solid transparent ;
//         border-right: 2px solid $motum-red ;
//         border-bottom: 2px solid $motum-red ;
//     }
//     [type="checkbox"]:not(:checked):disabled + label:before{
//         background-color: rgba(0, 0, 0, 0.3) ;
//     }
//     [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after{
//         background-color: $motum-red ;
//     }
//     [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:before, [type="radio"].with-gap:checked + label:after{
//         border-color: $motum-red ;
//     }
//     .picker__frame{
//         width: 100% ;
//         max-width: 400px ;
//     }
//     .picker__wrap{
//         border-top: 8px solid $motum-darkgray;
//     }
//     .picker__box{
//         box-shadow: none ;
//         border: none ;
//     }
//     .picker__weekday-display{
//         background-color: #fff ;
//         color: $motum-grey ;
//         width: 100%;
//         font-weight: 700 ;
//         display: none;
//     }
//     .picker__date-display{
//         background-color: #fff ;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-wrap: wrap;
//         border-bottom: solid 4px $motum-darkgray;
//         padding-top: 15px;
//     }
//     .picker__month-display, .picker__day-display, .picker__year-display{
//         //width: 33%;
//         color: $motum-darkgray ;
//         font-weight: 700 ;
//         font-size: 3.5rem ;
//         text-transform: none ;
//     }
//     .picker__month-display, .picker__day-display{
//         padding-right: 15px;
//     }
//     .picker__calendar-container{
//         padding: 3rem 1rem ;
//     }
//     .picker__close, .picker__today{
//         color: $motum-red ;
//     }
//     .btn-flat:focus, .btn-flat:hover{
//         background-color: rgba($motum-red, 0.2) ;
//     }
//     .picker__footer{
//         border-top: 2px solid $motum-darkgray;
//     }
//     .picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected{
//         background-color: $motum-blue ;
//     }
//     .picker__nav--next:before{
//         border-left: 0.75em solid $motum-red ;
//     }
//     .picker__nav--prev:before{
//         border-right: 0.75em solid $motum-red ;
//     }
//     .btn, .btn-large, .btn-flat{
//         text-transform: none ;
//         height: auto ;
//         padding: 5px 20px ;
//     }
//     .btn{
//         background-color: transparent;
//         color: $motum-red ;
//         border: 2px solid $motum-red;
//         text-transform: none;
//         position: relative;
//         border-radius: 0;
//         //z-index: -1;
//         // &::after{
//         //     content: '';
//         //     display: block;
//         //     position: absolute;
//         //     top: -2px;
//         //     left: -2px;
//         //     width: calc( 100% + 4px );
//         //     height: calc( 100% + 4px );
//         //     border: 2px solid blue;
//         //     z-index: 1;
//         // }
//         &:hover, &:active, &:focus{
//             padding-right: 20px;
//         }

//     }
//     ul li{
//         margin-bottom: 0;
//     }
//     .textarea-label{
//         left: 1.5rem ;
//     }
//     .label-style{
//         color: $motum-grey;
//         font-size: 1.75rem;
//         font-weight: 500;
//     }
//     .options-style{
//         label{
//             font-weight: 500;
//             font-size: 1.5rem ;
//             color: $motum-grey;
//         }
//     }
//     textarea{
//         min-height: 7rem;
//     }

//     // input[type="submit"]{
//     //     background-color: #fff;
//     //     border: 2px solid $motum-red;
//     //     box-shadow: none;
//     //     color: $motum-red;
//     //     font-size: 1.75rem;
//     //     font-weight: 700;
//     //     padding: 0.75rem 1.75rem;
//     //     margin-top: 35px;
//     //     &:hover{
//     //         background-color: yellow !important;
//     //     }
//     // }
//     .small-text{
//         font-size: 1.5rem;
//         line-height: 1.75;
//         padding-bottom: 1rem;
//     }

// } //end #contactus








// Contact Sidebar
.contact-sidebar{
    @include column(2.5);

    .contact-wrapper--wide & {
        @include column(3.5);
        @include desktop-below {
            @include column(12);
        }
    }
    
    margin: 3.5rem 0 2rem auto;
    h2, h3{
        font-weight: 700;
        font-size: 4rem;
        border-top: 4px solid $motum-darkgray;
        padding: 1.5rem 0;
    }
    // h3{
    //     border-top: none;
    //     border-bottom: 0.2rem solid $motum-darkgray;
    //     //padding-bottom: 0.8rem;
    // }
    .contact-sidebar__tels{
        //border-top: 0.2rem solid $motum-darkgray;
     //   border-bottom: 0.2rem solid $motum-darkgray;
        padding: 0 0 1.5rem;
        a{
            font-weight: 600;
            font-size: 2rem;
            color: $motum-darkgray;
            display: flex;
            align-items: center;
            div{
                width: 38px;
                margin-right: 1.5rem;
            }
        }
        // a:first-child{
        //     padding-bottom: 1.5rem;
        // }
    }
    .contact-sidebar__address{
        padding: 1rem 0 3rem;
        p{
            width: 100%;
            font-weight: 600;
            font-size: 2rem;
            color: $motum-darkgray;
            margin-bottom: 0;
            line-height: 2.25rem;
        }
        .motum-btn {
            margin-top: 2rem;
            @include desktop-below {
                display: none;
            }
        }
    }
    .sidebar-btn{
        text-decoration: none;
    }
    .pin1, .pin2{
        transition: 0.35s all ease;
    }
    .contact-sidebar__map-btn{
        color: $motum-blue;
        border: 0.2rem solid $motum-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 2rem;
        max-width: 282px;
        margin-top: 2rem;
        cursor: pointer;
        transition: 0.35s all ease;
        div{
            width: 2.5rem;
            border: none;
            display: block;
            margin: 0.75rem;
        }
        &:hover, &:active, &:focus{
            text-decoration: none;
            border-color: $colour-focus;
            color: $colour-focus;
            .pin1{
                fill: rgba($colour-focus, 0.3);
            }
            .pin2{
                fill: $colour-focus;
            }
        }
    }
    @include desktop-below{
        width: 100%;
        //padding: 3rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        .contact-sidebar__talk, .contact-sidebar__find{
            width: 47%;
        }
        h3{
            border-top: 0.8rem solid #3a363c;
        }
        .contact-sidebar__tels{
            border-bottom: none;
        }
    }
    @include tablet-small-below{
        display: block;
        .contact-sidebar__talk, .contact-sidebar__find{
            width: 100%;
        }
        .contact-sidebar__tels{
            border-bottom: 0.2rem solid $motum-darkgray;
        }
        h3{
            border-top: 0.2rem solid $motum-darkgray;
        }
    }
}

#motum-map{
    height: 400px;
    width: 100%;
    background: lightblue;
}








.page{
    //position: absolute;
    //width: 100%;
    //min-height: 100vh;
    //height: auto;
    //top: 0;
    //left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;




    &.is-visible, &.is-entering{
        z-index: 1;
    }
    &.is-exiting{
        z-index: 0;
    }

    // Define transitions
    .transition-swipe &{
        clip: rect(0,100vw,100vh,100vw);
        &.is-visible, &.is-entering{
            animation: transitionSwipeIn .6s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
        }
        &.is-exiting{
            animation: transitionSwipeOut .6s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
        }
    }


    .quiz-visible & {
        overflow:hidden;
    }

}

.page-contents{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    transition: transform 1s ease-in-out;
    .is-animating.is-leaving:not([class*="to-specialties-"]):not([class*="to-our-work-"]):not([class*="to-thinking-category"]) &{
        transform: translateY(-10rem);
        transition: transform .5s cubic-bezier(0.83, 0.16, 0.69, 0.91);
    }
    .is-animating:not([class*="to-specialties-"]):not([class*="to-our-work-"]):not([class*="to-thinking-category"]) &{
        transform: translateY(4rem);
    }
}

//body.transition-fade{
//    .page.is-visible .page-contents, .page.is-entering .page-contents{
//        opacity: 0;
//        animation: transitionFadeIn .6s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
//    }
//    .page.is-exiting .page-contents{
//        animation: transitionFadeOut .6s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
//    }
//} 


@keyframes transitionSwipeIn{
    from{
        clip: rect(0,100vw,600vh,100vw);
    }
    to{
        clip: rect(0,100vw,600vh,0);
    }
}

@keyframes transitionSwipeOut{
    from{
        clip: rect(0,100vw,600vh,0);
    }
    to{
        clip: rect(0,0vw,600vh,0);
    }
}



@keyframes transitionFadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes transitionFadeOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}


.placeholder-large-list{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    li{
        font-size: 5rem;
        text-align: center;
    }
}



.specialties-menu{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    min-height: 100vh;
    //pointer-events: none;
    //opacity: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    //padding-top: 15rem;
    transition: opacity .3s ease-in-out;
    pointer-events: auto;
    backface-visibility: hidden;

    @include height-xsmall{
        padding-top: 10rem;
    }

    .wrapper{
        z-index: 25;
        position: absolute;
        left: 50%;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    //background-color: #fff;
    .is-visible &{
        //opacity: 1;

    }



    .is-exiting &{
        pointer-events: none;
    }
    //&.is-clickable{
    //  pointer-events: auto;
    //}
    //.is-exiting &{
    //  transition: all .6s ease-in-out;
    //  opacity: 0;
    //}
}

.specialties-menu__header{
    margin-bottom: 5rem;
    position: relative;
    z-index: 15;
    //transition: opacity .5s ease-in-out;
    //opacity: 0;
    //transition-delay: .5s;

    opacity: 0;
    transform: translateX(-40px);


    p{
        line-height: 1.3;
        color: #3a363c;
        @include responsive-font(2vw, 14px, 28px);

        @include height-medium{
            @include responsive-font(1.5vw, 14px, 28px);
        }

        @include height-small{
            @include responsive-font(1.2vw, 14px, 28px);
        }

    }
    //.is-open &{
    //  opacity: 1;
    //}
    .is-open.is-active &{

        opacity: .5;
    }

    .is-entering &, .is-visible &{
        animation: departmentItemSlideFadeIn 1s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
    }

    .is-exiting &{
        opacity: 1;
        transform: translateX(0);
        animation: departmentItemSlideFadeOut 1s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
    }

    @include tablet-below{
        width: 100%;
        text-align: center;
    }
}

.specialties-menu__title{
    @include responsive-font(6vw, 30px, 70px);
    font-weight: 700;
    display: block;
    margin-bottom: 1rem;
    color: #00a2e5;
    @include height-medium{
        @include responsive-font(4vw, 30px, 70px);
    }
    @include height-small{
        @include responsive-font(3vw, 30px, 70px);
    }
}

.specialties-menu__btn-close{
    position: absolute;
    right: 0rem;
    top: 5rem;
    font-size: 9rem;
    background: transparent;
    border: 0;
    z-index: 25;
    .cross span{
        background-color: $motum-darkgray;
        width: 55px;
        height: 5px;
    }
    .page-specialties-index &{
        display: none;
    }
}

.quiz-btn-container{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .promoted-post & {
        top: 90%;
    }
}


.quiz-btn__title{
    color: $motum-yellow;
    font-weight: 700;
    margin-bottom: 7.5rem;
    text-transform: uppercase;
    display: block;
    font-size: 1.8rem;
    .quiz-visible &{
        opacity: 0;
    }
}


.quiz__transit-circle {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    transition: width 0.15s linear,height 0.15s linear .2s;
    //left: 0;
    top: 4.1rem;
    width: 5rem;
    height: 5rem;
    z-index: -1;
    circle{
        fill: #fff;
    }
    .quiz-visible &{
        width: 300vw !important;
        height: 300vh !important;
        z-index: 2;
        //transform: translate(-50%, -50%);
        @include phone-large-below{
            height: 0vh !important;
            width: 0vw !important;
            opacity: 0;
        }
    }
}



.specialties__transit-circle{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    left: 0;
    top: 0;
    width: 0rem;
    height: 0rem;
    z-index: 15;
    left: 50%;
    top: 50%;

    circle{
        fill: $motum-yellow;
    }
}

// Deep nesting for special hover transition circle treatment on the homepage.
.page-ready.page-index-index .specialties__transit-circle {
    width: 7.5rem;
    height: 7.5rem;
}





.homepage-splash-btn{
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);

    //&:hover{
    //    .specialties__transit-circle{
    //        width: 9.5rem;
    //        height: 9.5rem;
    //        circle{
    //            fill: #fff;
    //        }
    //    }
    //}
}


.quiz-splash-btn{
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    background: transparent;
    border: 0;
    font-size: 2.35rem;
    .quiz-splash-btn__bars span:after,  .quiz-splash-btn__bars span:before{
        text-align: center;
    }
    &:hover{
        .quiz__transit-circle{
            width: 9.5rem;
            height: 9.5rem;
            circle{
                fill: #fff;
            }
        }
    }
    .quiz-visible &{
        pointer-events: none;
    }
}
.quiz-splash-btn__bars{
    .quiz-visible &{
        opacity: 0;
    }
}
/* Homepage splash bars */

.homepage-splash-btn__bars, .quiz-splash-btn__bars{
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    position: relative;
    z-index: 55;
    transform: scale(0);
    transition: all 1s cubic-bezier(0.53, -0.01, 0, 1.53) .5s;
    .page-ready &{
        transform: scale(1);
    }
}
.homepage-splash-btn__bars span, .quiz-splash-btn__bars span{
    display: block;
    width: 60px;
    height: 40px;
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;
    &:last-child{
        margin: 0;
    }
    &:before, &:after{
        content: "\e904";
        font-family: icomoon;
        color: $motum-darkgrey;
        font-size: 4rem;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0.2rem;
        left: 50%;
        //background-color: $motum-darkgray;
        transform: translateX(-200%);
        transition: transform ease 300ms;
        @include tablet-below {
            top: 0.45rem;
        }
        @include phone-large-below {
            top: 0.65rem;
            font-size: 5rem;
        }
    }
    &:before{
        //background-color: $motum-blue;
        color: $motum-darkgrey;
    }
    &:after{
        transform: translateX(-50%);
        .page-specialties-index & {
            transform: translateX(-200%) !important;
        }
    }
    &:nth-child(2):before, &:nth-child(2):after{
        transition-delay: 75ms;
    }
    &:last-child:before, &:last-child:after{
        transition-delay: 150ms;
    }
}

.homepage-splash-btn:hover .homepage-splash-btn__bars span:before, .quiz-splash-btn:hover .quiz-splash-btn__bars span:before{
    transform: translateX(-50%);
    .page-specialties-index & {
        transform: translateX(-200%) !important;
    }
}
.homepage-splash-btn:hover .homepage-splash-btn__bars span:after, .quiz-splash-btn:hover .quiz-splash-btn__bars span:after{
    transform: translateX(200%);
}



/* End Homepage splash bars */






@keyframes transitionCircleGrow{
    to{
        width: 320vmax;
        height: 320vmax;
    }
}

@keyframes transitionCircleShrink{
    to{
        width: .1vmin;
        height: .1vmin;
    }
}



// Market loading animation
//.loading-animation {
//  height: 48px;
//  width: 48px;
//  animation: rotate 0.8s infinite linear;
//  border: 5px solid #ccc;
//  border-right-color: transparent;
//  border-radius: 50%;
//  position: fixed;
//  left: 50%;
//  top: 50%;
//  transform: translate(-50%, -50%);
//  transition: border-color .5s ease-in-out;
//  display: none;
//  z-index: 100;
//  border-color: transparent;
//  .page-specialties-index &{
//    border-color: #00a2e5;
//    border-right-color: transparent;
//  }
//}

.loading-animation{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 100;
}
.spinner {
    width: 70px;
    height: 70px;
    position: relative;
    margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 0 50px $motum-darkgrey;
    //opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center;

    -webkit-animation: sk-bounce 1.5s infinite ease-in-out;
    animation: sk-bounce 1.5s infinite ease-in-out;

    // -webkit-animation: sk-fade 2.0s infinite ease-in-out;
    // animation: sk-fade 2.0s infinite ease-in-out;
    //
}

.double-bounce2 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

// @-webkit-keyframes sk-fade {
//   0% {
//     -webkit-transform: scale(0);
//     border: 50px solid $motum-darkgrey;
//   }
//   100% {
//     -webkit-transform: scale(1);
//     border: 0px solid $motum-darkgrey;
//   }
// }

// @keyframes sk-fade {
//   0% {
//     transform: scale(0);
//     -webkit-transform: scale(0);
//     border: 50px solid $motum-darkgrey;
//   }
//   100% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//     border: 0px solid $motum-darkgrey;
//   }
// }

@-webkit-keyframes sk-bounce {
    0% { box-shadow: inset 0 0 0 50px $motum-darkgrey; -webkit-transform: scale(0.0);}
    33% { box-shadow: inset 0 0 0 50px $motum-darkgrey;}
    100% { box-shadow: inset 0 0 0 0px $motum-darkgrey; -webkit-transform: scale(1.0);}
}

@keyframes sk-bounce {
    0% {
        box-shadow: inset 0 0 0 50px $motum-darkgrey;
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 33% {
          box-shadow: inset 0 0 0 50px $motum-darkgrey;
      } 100% {
            box-shadow: inset 0 0 0 0px $motum-darkgrey;
            transform: scale(1.0);
            -webkit-transform: scale(1.0);
        }
}



// @-webkit-keyframes sk-bounce {
//   0%, 100% { -webkit-transform: scale(0.0) }
//   50% { -webkit-transform: scale(1.0) }
// }

// @keyframes sk-bounce {
//   0%, 100% {
//     transform: scale(0.0);
//     -webkit-transform: scale(0.0);
//   } 50% {
//       transform: scale(1.0);
//       -webkit-transform: scale(1.0);
//     }
// }


@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}




//===========
//Thank you
//===========

.thank-you-page {
    //background-color: $motum-yellow;
    min-height: 100vh;
    img {
        max-width: 35rem;
        @include tablet-small-below {
            max-width: 30rem;
        }
    }
    .wrapper {
        padding: 16rem 3rem 10rem;
    }
    .page-header__content {
        text-align: center;
    }
    .motum-btn {
        &:hover, &:active, &:focus{
            &:before, &:after {
                box-shadow: inset 0 0 0 0 white;
            }
        }
    }
    h3 {
        line-height: 1.3;
        padding: 2.5rem 5rem;
        max-width: 100rem;
        margin: 0 auto;
        font-weight: 400;
        font-size: 2.25rem;
        @include tablet-below {
            padding: 0;
        }
        a {
            color: $motum-hyperlink-purple;
            box-shadow: inset 0 -4px 0 0 $motum-purple;
        }
    }
    .page-header__subtitle.thank-you {
        span span {
            transform: translateY(0) translateX(0);
        }
    }
}











/* Dropdown Nav */
.footer ul li ul{
    opacity:0;
    visibility:hidden;
    display: none;
}


// portfolio row columns

.motum-row {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    //padding-bottom: 1.5vw;
}



.m-col {
    overflow: hidden;
    //display: flex;
    //flex-direction: column;
    //padding-right: 5px;
    //margin: 0 auto;
    > div {
        padding-bottom: 5px;
    }

    img{
        flex-stretch: 0;
    }


}


.col-12{
    @include column(12);
}
.col-9{
    @include column(9);
}
.col-8{
    @include column(8);
}
.col-7{
    @include column(7);
}
.col-6{
    @include column(6);
}
.col-5{
    @include column(5);
}
.col-4{
    @include column(4);
}
.col-3{
    @include column(3);
}
.col-2{
    @include column(2);
}
.col-1{
    @include column(1);
}

.w-80 {
    @include column(8);
    @include tablet-below{
        @includ column(12);
    }
}
.w-70 {
    @include column(7);
    @include tablet-below{
        @includ column(12);
    }
}
.w-50 {
    @include column(6);
    @include tablet-below{
        @includ column(12);
    }
}
.w-30 {
    @include column(4);
    @include tablet-below{
        @includ column(12);
    }
}

.portfolio-item__two-col {
    column-count: 2;
    column-gap: 3rem;
    padding-bottom: 7rem;
}

.centered {
    margin: 0 auto;
}

.centertext {
    text-align: center;
}




// Grid CSS

// Extend typography styles to all columns
[class*='col-']{
   // @extend .typography-styles;
}



.portfolio-items-stacked > [class*='col-']{
    @include desktop-small-below{
        &:first-child{
            padding-top: 1rem;
            padding-left: 0;
            padding-right: .75rem;
        }

        &:last-child{
            padding-top: 1rem;
            padding-right: 0;
            padding-left: .75rem;
        }
    }
    @include tablet-below{
        &:first-child, &:last-child{
            padding: 1rem .75rem 0 .75rem;
        }
    }
}

img.stretch-to-bounds{
    //width: 100%;
    // margin-bottom: 1.5vw;
    object-fit: cover;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    width: 100%;
    &:last-child {
        margin-bottom: none;
    }
}

.container .row.portfolio-items-stacked{
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 0;
    @include desktop-small-below{
        margin-left: 0;
    }
}


.container .row{
    margin-bottom: 1rem;
    //@include desktop-medium-below{
    //  margin-right: 0rem;
    //  margin-left: 0rem;
    //}
}



.portfolio-image-modal__image{
    position: relative;
    cursor: pointer;
    display: block;
    &:before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        content: '';
        z-index: 1;
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }
    &:after{
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        font-size: 4rem;
        font-family: 'icoMoon';
        content: '\e900';
        z-index: 2;
        transform: translate(-50%, -50%) scale(0);
        transition: scale .2s cubic-bezier(.64,.39,.03,1.24);
    }
    &:hover{
        &:before{
            opacity: 1;
        }
        &:after{
            transform: translate(-50%, -50%) scale(1);
        }
    }
}



// Bamboo Careers styles
#BambooHR{
    margin-top: 3.5rem;

    .BambooHR-ATS-board h2{
        font-weight: 700;
        font-size: 4rem;
        border-top: .8rem solid #3a363c;
        padding: 1.5rem 0;
        color: #3a363c;
        border-bottom: 0 !important;
        font-family: $font-body;
    }
    .BambooHR-ATS-Department-Header{
        border-bottom: 2px solid #3a363c!important;
        border-top: 2px solid #3a363c!important;
        font-weight: 300 !important;
        font-size: 3rem !important;
        padding: 1.5rem 0;
        color: #3a363c;
        line-height: 1;
    }
    .BambooHR-ATS-Jobs-List{
        padding: 0;
        border-bottom: 0;
    }
    .BambooHR-ATS-Jobs-Item{
        // border-top: 2px solid #ccc;
        // border-bottom: 2px solid #ccc;
        padding: 2.4rem 0 0.25rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        &:first-child{
            border-top: 0;
        }
        a {
            padding-left:4rem;
            position:relative;
            border-bottom:0;
            &:after{
                content: "\e904";
                font-family: icomoon;
                box-sizing: border-box;
                font-size: 1.3rem;
                color: #009fda;
                border: 2px solid #009fda;
                opacity:0.75;
                border-radius: 50%;
                padding: 1.5px 7px;
                position: absolute;
                top: 50%;
                left: 0;
                transition-duration: .75s;
                transition-delay: 75ms;
                transform: translateY(-50%);
                width: 3rem;
                height: 3rem;
                display:flex;
                justify-content: center;
                align-items: center;
                @include phone-large-below{
                    border-width: 2px;
                }
            }
            &:hover:after {
                opacity:1;
            }
        }

    }
    .BambooHR-ATS-Department-Item{
        padding-left: 0 !important;
        padding-top: 1rem;
    }


    .BambooHR-ATS-board a{
        font-size: 2.5rem;
        line-height: 1.4;
    }

    .BambooHR-ATS-Location{
        font-size: 1.5rem;
        margin-top: 0;
        padding: 0;
        font-weight: 500;
        padding-left: 4rem;
    }



}






// ================================
// Social Share Thinking Post Block
// ================================

.social-share-quiz {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // transform: translateY(-50%);
}

.social-share-buttons-container {
    background: lightblue;
}

.social-share-buttons {
    display: inline-flex;
    align-items: center;
    background: #fff;
    padding: 2rem;
    float: right;
    transform: translateY(-50%);
    margin-bottom: -4rem;
    position: relative;
    z-index: 3;
    //transition: all 1s cubic-bezier(0.53, -0.01, 0, 1.23);
    &__text {
        display: inline-block;
        
        &--share {
            font-size: 1.8rem;
            font-weight: 600;
            .header-light & {
                color: #fff;
            }
        }
        &--this {
            font-size: 1.8rem;
            font-weight: 600;
            margin-right: 1.5rem;
            .header-light & {
                color: #fff;
            }
        }
    }
    
    .think-blog-post & {
        @include tablet-small-above{
            padding: 0 0 0 2rem;
            transform: translateY(-53%);
        }

        flex-shrink: 0;
        @include tablet-small-below{
            transform: translateY(0);
            margin-bottom: .5rem;
            .social-share-buttons__text {
                display: block;
                text-align: center;
                margin-bottom: 1.5rem;
            }
        }
        @include phone-large-below {
            flex-shrink: 0;
            position: relative;
            transform: inherit;
            margin: 2rem 0;
            float: initial;
            padding: 0;
        }
    }

    .promoted-post & {
        background: none;
        
        @include tablet-small-below {
            margin: 5rem 0 2rem;
            padding: 0;
        }
    }

    &.fixed {
        transform: translateY(0);
        margin-bottom: 0;
        position: fixed;
        right: 3rem;
        top: 10rem;
        padding: 0;
        background: transparent;
        flex-direction: column;
        display: flex;

        .social-share-buttons__text {
            margin-bottom: 1.5rem;
        }

        .social-share-buttons__text--this {
            display: none;
        }

        @include phone-large-below {
            right: 1rem;
        }
    }

    .social-share-quiz & {
        // background: transparent;
        padding: 2rem;
        // transform: translateY(0);
        transform: translate(-2rem, -50%);
        background: #f0f0f0;
        // margin-bottom: 0;
        float: none;

        @include tablet-below {
            transform: none;
            padding: 2rem 0 0;
        }
    }
}

.social-share-button {
    border: none;
    width: 6rem;
    height: 6rem;
    position: relative;
    overflow: hidden;
    transition: transform 0.7s cubic-bezier(0.53, -0.01, 0, 1.23);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: .2rem;

    .fixed &{
        margin-left: 0;
    }

    .think-blog-post & {
        width: 4rem;
        height: 4rem;
    }

    i {
        position: relative;
        z-index: 2;
        font-size: 2rem;
        color: $motum-darkgrey;
        transition: color 0.2s ease-in-out;
        // transition-delay: 0.2s;
        .think-blog-post & {
            font-size: 1.5rem;
        }
    }

    a {
        color: $motum-darkgrey;
    }

    &.share-facebook {
        background: $motum-blue;
    }

    &.share-linkedin {
        background: $motum-blue;
    }

    &.share-twitter {
        background: $motum-blue;
    }

    &.share-email {
        background: $motum-red;
        content: '\e90f';
        font-weight: 700;
    }

    &::before, &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: transform 0.2s ease, background-color 0.2s ease;
    }

    &::before {
        background: $motum-yellow;
    }

    &::after {
        background: $motum-darkgrey;
        transition-delay: 0.2s;
    }

    &:hover, &:focus {
        transform: translateY(-1rem);

        i {
            color: #fff;
        }

        &::before, &::after {
            height: 100%;
        }
        
    }

    .fixed & {
      //  display: block;
        margin-bottom: 1rem;
        width: 4.5rem;
        height: 4.5rem;

        @include phone-large-below {
            width: 3.5rem;
            height: 3.5rem;
        }

        i {
            font-size: 1.6rem;

            @include phone-large-below {
                font-size: 2rem;
            }
        }

        &::before, &::after {
            width: 0;
            height: 100%;
        }

        &:hover, &:focus {
            transform: translateX(1rem);

            &::before, &::after {
                width: 100%;
            }
        }
    }

    .blog-header & {
        i {
            margin: 0;
            float: none;
        }
    }

    .social-share-quiz & {
        @include tablet-below {
            width: 4rem;
            height: 4rem;
        }
    }
}

.social-share-quiz__container {
    background: #f0f0f0;
}

.promoted-post {
    min-height: 100vh;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .page-header__title, .page-header__subtitle, .post-header__back-btn a {
        color: #fff;
    }
} 



//
//.transition-fade {
//    transition: .5s;
//    opacity: 1;
//
//    .is-animating &{
//        opacity: 0;
//    }
//}




















.home__transit-circle{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    left: 0;
    top: 0;
    z-index: 15;
    left: 50%;
    top: 50%;

    width: 5rem;
    height: 5rem;

    //&:hover{
    //    width: 8rem;
    //    height: 8rem;
    //}

    //
    //transition: all 1s ease-in-out;
    //.is-animating &{
    //    width: 320vmax;
    //    height: 320vmax;
    //}

    circle{
        fill: #fff;

    }


}



.transition-home-specialties-circle{
    width: 2rem;
    height: 2rem;
    transition: all 1s ease-in-out;

    //.to-specialties &{
    .is-animating &{
        width: 320vmax;
        height: 320vmax;
        background-color: red;
    }
    //}

}

.text--peek-transition {
    overflow:hidden;
    > span {
        display: block;
        position: relative;
    }
} 

.transition-pageheader-subtitle-peek{
    //TODO find less hacky solution 
    transform: translateY(0);
    transition: transform .5s cubic-bezier(0.64, 0.39, 0.03, 1.24) 0.1s;
    .is-animating &{
        transform: translateY(0.001%); 
    }
    .is-animating:not([class*="to-thinking-category"]) &{
        transform: translateY(100%);
    }
    .is-animating.is-leaving:not([class*="to-thinking-category"]) &{
        transform: translateY(-100%); 
    }
}
 

.transition-pageheader-title-peek{
    transform: translateY(0);
    transition: transform .5s cubic-bezier(0.64, 0.39, 0.03, 1.24) 0.1s;
    .is-animating &{
        transform: translateY(0.001%); 
    }
    .is-animating:not([class*="to-thinking-category"]) &{
        transform: translateY(100%);
    }
    .is-animating.is-leaving:not([class*="to-thinking-category"]) &{
        transform: translateY(-120%);
    }
}


.transition-specialties-graphic-faderise{
    transition: all .4s $smooth-bezier .2s;
    transform: translateY(0px);
    opacity: 1;

    .is-animating &{
        transform: translateY(2rem);
        opacity: 0;
    }
    .is-animating.is-leaving &{
        transform: translateY(-2rem);
        opacity: 0;
    }
    .is-visible &{

    }
    .is-exiting &{

    }
}



.transition-article-block{
    transition: all .8s cubic-bezier(.64,.39,.03,1.24);
    transform: translateY(0);
    opacity: 1;
    @for $i from 1 through 25{
        &:nth-child(#{$i}){
            transition-delay: ((.05s * $i));
        }
    }
    .is-animating &{
        opacity: 0;
        transform: translateY(2rem);
    }
}


//
//.transition-specialties-entry-circle{
//    transition: all .9s ease-in-out;
//
//    width: 220vw;
//    height: 220vh;
//    .is-animating &{
//         width: .1vw;
//        height: .1vh;
//    }
//    .is-animating.is-leaving &{
//        width: 220vw;
//        height: 220vh;
//    }
//}


