

.header{
  width: 100%;
  z-index: 999;
  opacity: 0;
  position: absolute;
  display: flex;
  align-items: center;
  transition:
  padding-top .5s ease-in-out,
  padding-bottom .5s ease-in-out,
  background-color .2s ease-in-out .3s,
  box-shadow .2s ease-in-out .3s,
  transform .3s ease-in-out;
  transform: translateY(0);

  //position: fixed;

  body:not(.mobile-menu-open) &{
    &.header--is-hidden{
      transform:  translateY(-100%);
    }
  }

  body:not(.mobile-menu-open) & {
    &.header--is-fixed {
      position: fixed;
    }
  }

  @include tablet-below{
    position: fixed;
  }



  .is-visible &{
    opacity: 1;
  }


  //.page-specialties-index &{
  //  transition: opacity .2s ease-in-out;
  //  opacity: 0;
  //}
  .wrapper{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
  }
  .specialties-menu-open &{
    //position: absolute;
    .main-nav ul.navigation > li a > span > span:first-child, .main-nav ul.navigation > li a > span > span:last-child, .main-nav ul.navigation > li span > span > span:first-child, .main-nav ul.navigation > li span > span > span:last-child{
      transition: all .15s ease-in-out .2s;
      color: $motum-darkgray;

    }


    .main-nav ul.navigation>li>a:before, .main-nav ul.navigation>li>span:before{
      background-color: #00a2e5;
    }
    .main-nav ul.navigation>li>a:after, .main-nav ul.navigation>li>span:after{
      background-color: lighten(#00a2e5, 30%);
    }


    .main-nav ul.navigation > li:after{
      background-color: #e2db19;
    }
  }


  .header__logo{
    @include column(3);
    float: none;
    max-width: 200px;
    overflow: hidden;
    position: relative;
    height: 27px;
    transform: translateY(-5px);

    display: block;
    img{
      max-width:100%;
    }

    @include desktop-medium-below{
    }
    @include tablet-below{
      @include column(8);
    }

    span{
      display: block;
      transition: all .3s ease-in-out .2s;
    }


    svg{
      max-width: 100%;
      path{
        fill: #fff;
        transition: all .2s ease-in-out;
      }
      &:nth-child(2){
        path{
          fill: $motum-darkgray;
        }

        //position: absolute;
        //top: 0;
        //width: 100%;
        //height: 100%;
        //left: 0;
      }
    }

    .header-dark &, .header-fixed &{
      //        svg path{
      //            transition: all .15s ease-in-out .2s;
      //            fill: $motum-darkgray;
      //}
      span{
        transform: translateY(-50%);
      }

      svg:nth-child(1){
        // opacity: .1;
        // animation: logoSlideChildOne 1s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
        path{
          fill: #c4c4c4;

        }
      }

      svg:nth-child(2){

        // transform: translateY(130%);
        // animation: logoSlideChildTwo 1s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
      }
    }



    .is-animating:not([class*="to-specialties-"]):not([class*="to-our-work-"]):not([class*="to-thinking-category"]) &{
      span{
        transform: translateY(0);
      }
    }





    .page-thinking-business-to-business-marketing-read &, .mobile-menu-open &{
      svg path{
        fill: #fff;
      }
    }

    .first-load &{

    }


  }

  body:not(.mobile-menu-open) & {
    &.header--is-fixed .header__logo {
      transform: none;
    }
  }


}

.header__inner{
  position: relative;
  width: 100%;
  z-index: 2;
  padding-top: 40px;
  padding-bottom: 10px;

  body:not(.mobile-menu-open) .header--is-fixed & {
      background-color: #fff;
      box-shadow: -1px -3px 20px 0px rgba(0, 0, 0, 0.15);
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
  }
}

.page-thinking-business-to-business-marketing .header .header__logo svg path, .error-page .header .header__logo svg path{
  fill: $motum-darkgray;
  transition: all .2s ease-in-out;
  transition-delay: .3s;
}

.page-thinking-business-to-business-marketing-read .header .header__logo svg path, .error-page .header .header__logo svg path{
  fill: #fff;transition: all .2s ease-in-out;
  transition-delay: .3s;
}

body:not(.mobile-menu-open) {
  .page-thinking-business-to-business-marketing-read .header.header--is-fixed .header__logo svg path, .error-page .header.header--is-fixed .header__logo svg path, .page-our-work .header.header--is-fixed .header__logo svg path, {
    fill: $motum-darkgray;
  }
}

.header-light .main-nav__social-icons i{
  color: #fff;
  transition: all .2s ease-in-out;
  transition-delay: .3s;
}

.header--is-fixed .main-nav__social-icons i{
  color: $motum-darkgray;
}

.page-thinking-business-to-business-marketing-read .header.header--is-fixed .main-nav ul.navigation>li>a>span>span, .page-thinking-business-to-business-marketing-read .header.header--is-fixed .main-nav__social-icons i, .error-page .header.header--is-fixed .main-nav ul.navigation>li>a>span>span, .error-page .header.header--is-fixed .main-nav__social-icons i,
.page-our-work .header.header--is-fixed .main-nav ul.navigation>li>a>span>span, {
  color: $motum-darkgray;
}

.page-thinking-business-to-business-marketing-read {
  iframe {
    display: block;
    margin: 0 auto;
  }
}


@keyframes logoSlideChildOne{
  to{
    transform: translateY(-100%);
  }
}
@keyframes logoSlideChildTwo{
  to{
    transform: translateY(0);
  }
}
