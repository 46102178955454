
.specialty-card{
	padding: 1rem;
	position: relative;
	transition: opacity .2s ease-in-out;

	.hover & {
		opacity: .6;
		&:hover {
			opacity: 1;
		}
	}
	&.is-hovering{
		z-index: 3;
	}
	&.theme-yellow{
		>div:after {
			box-shadow: 0 20px 40px -10px rgba($bright-yellow, .9), 0 50px 80px 0 rgba($bright-yellow, .6);
		}
		.specialty-card__container {
			background: $motum-yellow;
		}
	}
	&.theme-green{
		>div:after {
			box-shadow: 0 20px 40px -10px rgba($bright-green, .9), 0 50px 80px 0 rgba($bright-green, .6);
		}
		.specialty-card__container {
			background: $motum-green;
		}
	}
	&.theme-purple{
		>div:after {
			box-shadow: 0 20px 40px -10px rgba($bright-purple, .6), 0 50px 80px 0 rgba($bright-purple, .3);
		}
		.specialty-card__container {
			background: $motum-purple;
		}
	}
	&.theme-red{
		>div:after {
			box-shadow: 0 20px 40px -10px rgba($bright-red, .6), 0 50px 80px 0 rgba($bright-red, .3);
		}
		.specialty-card__container {
			background: $motum-red;
		}
	}
	&.theme-blue{
		>div:after {
			box-shadow: 0 20px 40px -10px rgba($bright-blue, .9), 0 50px 80px 0 rgba($bright-blue, .6);
		}
		.specialty-card__container {
			background: $motum-blue;
		}
	}
}

.specialty-card > div {
	position: relative;
	transform-style: preserve-3d;
	transform: perspective(300px);
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	@include tablet-above{
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			//background-color: #000;
			// box-shadow: 0 20px 40px -10px rgba(#000, .6), 0 50px 80px 0 rgba(#000, .3);
			// z-index: -1;
			transform: translateZ(-50px);
			transition: .3s;
			opacity: 0;
		}
	}

	&:hover::after {
		opacity: 1;
	}

	@include tablet-below{
		border: 1px solid #ebebeb;
	}
}

.specialty-card__container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	// align-content: flex-start;
	justify-content: center;
	text-align: center;

	@include tablet-below {
		align-content: center;
	}

	@include phone-large-below {
		padding: 3rem 0;
	}
}

.specialty-card__visual {
	width: 23rem;
	height: 23rem;
	border-radius: 50%;
	margin: 0 auto;
	margin-top: -3rem;
	display: block;
	position: relative;

	.theme-yellow & {
		// background: $motum-green;
		background: $motum-purple;
	}
	.theme-green & {
		background: $motum-purple;
	}
	.theme-purple & {
		background: $motum-lightgrey;
	}
	.theme-red & {
		background: $motum-blue;
	}
	.theme-blue & {
		background: $motum-yellow;
	}

	svg {
		width: calc(100% + 2rem);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); 
	}

	@include desktop-medium-below {
		width: 21.5rem;
		height: 21.5rem;
	}

	@include desktop-small-below {
		width: 19rem;
		height: 19rem;
	}

	@media (max-width: 900px) {
		width: 16rem;
		height: 16rem;
	}

	@include tablet-below {
		margin-top: 0;
		width: 22rem;
		height: 22rem;
	}
}

.specialty-card__title {
	padding-top: 3rem;
	font-size: 3.2rem;
	font-weight: 700;
	width: 100%;
	display: block;
	transition-delay: 0.075s;
	color: $motum-darkgrey;
	// max-width: 200px;
	text-align:center;

	@include desktop-medium-below {
		padding-top: 1.5rem;
	}

	@include desktop-small-below {
		max-width: none;
		padding: 2rem 2rem 0;
	}

	@media (max-width: 900px) {
		padding-top: 1rem;
	}

	@include tablet-below {
		font-size: 4rem;
		padding-top: 3rem;
	}
}

.specialty-card__cta {
	padding-top: 1.5rem;
	padding-bottom: 5px;
	font-size: 1.6rem;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	transition-delay: 0.15s;
	line-height: 1.2;
	color: $motum-darkgrey;

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background: $motum-darkgrey;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@media (max-width: 900px) {
		font-size: 1.4rem;
	}

	@include tablet-below {
		font-size: 1.6rem;
	}
}



