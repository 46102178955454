.client-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	&:not(.cycle-slide):not(:first-child){
		height: 0;
		overflow: hidden;
	}

	.client {
		display: block;
		@include column(3.25);
		padding: 0 6rem 5rem 0;
		display: flex;
		justify-content: center;
		align-content: center;
		transition: 0.7s ease all;

		@for $i from 1 through 6 {
	    	&:nth-child(#{$i}) {
	        	transition-delay: #{$i * .15}s;
	    	}
	    }

	    @include desktop-below {
	    	@include column(5.5);
	    }

	    @include tablet-small-below {
	    	@include column(3.5);
			padding-right: 3rem;
	    }
	}

	a.client:hover svg, a.client:focus svg {
		opacity: 0.5;
	}

	svg {
		width: 100%;
		margin-top: 0;
		max-height: 100px;
		transition: 0.35s ease all;
	}

	&.cycle-slide {
		.client{
			transform: scale(0.7);
			opacity: 0;
			pointer-events: none;
		}
	}

	&.cycle-slide.cycle-slide-active {
		.client{
			transform: scale(1);
			opacity: 1;
			pointer-events: all;
		}
	}

	@include tablet-small-below {
		justify-content: center;
	}
}

.testimonial__slideshow {
	> div {
		&:not(.cycle-slide):not(:first-child){
			height: 0;
			overflow: hidden;
		}
	}
}