.highlighted-cta-btn{

    &.right { 
        float: right;
    }
    
    > span{
        display: inline-block;
        padding: 1.1rem 1.5rem;
        font-weight: 700;
        font-size: 3rem;
        color: $motum-darkgray;
        position: relative;
        transition: all 1s cubic-bezier(.53,-.01,0,1.23);
        &:last-child{
            margin-top: -1rem;
            transition-delay: .04s;
            z-index: 2;
        }
        i{
            font-size: 2rem;
        }
    }

    >span:before, >span:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;

    }
    > span:before{
        transition: all 1s cubic-bezier(.53,-.01,0,1.23) .3s;

        .theme-yellow & {
            background-color: $bright-yellow;
        }
        .theme-green & {
            background-color: $motum-green;
        }
        .theme-purple & {
            background-color: $motum-purple;
        }
        .theme-red & {
            background-color: $bright-blue;
        }
        .theme-blue & {
            background-color: $bright-yellow;
        }

        .is-visible &{
            width: 100%;
        }
        .is-exiting &{
            transition-delay: 0s;
            width: 0;
        }
    }
    > span:after{
        background-color: $motum-darkgray;
        transition: all .5s cubic-bezier(.53,-.01,0,1.00);

    }
    > span:last-child:before{
        transition-delay: .5s;
        .is-exiting &{
            transition-delay: .2s;
        }
    }
    > span > span{
        position: relative;
        z-index: 3;
        opacity: 0;
        display: block;
        transform: translateX(-3rem);
        transition: all .75s cubic-bezier(.53,-.01,0,1.03);
        .is-visible &{
            opacity: 1;
            transform: translateX(0);
        }
        .is-exiting &{
            opacity: 0;
        }
    }
    > span:last-child > span{
        transition-delay: .1s;
    }

    &:hover > span{
        transform: translateX(2rem);
    }
    &:hover > span:after{
        width: 100%;
        .is-exiting &{
            width: 0;
        }
    }
    &:hover > span > span{
        color: #fff;
    }

    @include tablet-below{
        float: none;
        margin: 0 auto;
    }
}



.btn-hollow, .specialty-cta__btn {
    //text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 800;
    border: solid 3px $motum-darkgray;
    padding: 1rem 2rem;
    transition: 0.35s ease all;
    //box-shadow: inset 0 0 0 0 $motum-yellow;
    color: $motum-darkgray;
    overflow: hidden;
    position: relative;
    z-index: 10;
    display: inline-flex;

    &--yellow{
        border: solid 3px $bright-yellow;
        color: $bright-yellow;
        &::before{
            background-color: $bright-yellow;
        }
        &:hover, &:active, &:focus {
            color: $motum-darkgrey;
        }
    }
    &--blue{
        border: solid 3px $bright-blue;
        color: $bright-blue;
        &::before{
            background-color: $bright-blue;
        }
        &:hover, &:active, &:focus {
            color: #fff;
        }
    }
    &--large{
        font-size: 2.3rem;
    }
    span:first-child {
        position: relative;
        z-index: 2;
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        // background-color: rgba($motum-yellow, 0.4);

        position: absolute;
        top: 0;
        right: calc(100% + 3px);
        transition: 0.35s ease all;
        z-index: 1;
    }
    &:hover, &:active, &:focus {

        &:before {
            right: 0;
        }
        span.btn-hollow__icon{
            margin-left: 2rem;
        }
        span.btn-hollow__icon i:first-child{
            transform: translateX(200%);
        }
        span.btn-hollow__icon i:last-child{
            transform: translateX(0);
        }
    }
    @include phone-large-below {
        padding: 0.5rem 1.5rem;
    }


    span.btn-hollow__icon{
        overflow: hidden;
        display: block;
        position: relative;
        margin-left: 1rem;
        transition: margin .6s cubic-bezier(0.64, 0.39, 0.03, 1);
        @include tablet-small-below {
            padding-right: 0.7rem;
        }
        
        i{
            position: relative;
            z-index: 2;
            display: block;
            transition: transform .6s cubic-bezier(0.64, 0.39, 0.03, 1);
            &:first-child{

            }
            &:last-child{
                position: absolute;
                left: 0;
                top: 0;
                transform: translateX(-200%);
            }
        }
    }
}







