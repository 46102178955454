.article-cards {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.article-card {
	width: 25%;
	display: block;
	padding: 1rem;

	transition: transform .2s ease-in-out, opacity .2s ease-in-out;
	.is-animating &{
		transform: translateY(2rem);
		opacity: 0;
	}

	.js-tilt {
		transform-style: preserve-3d;
	}

	&.featured {
		width: 50%;
	}

	&.on-specialty {
		//width: 750px;
        width: 100%;
        transform: translateZ(15px);
		&:hover {
			.article-card__content {

			}
		}
	}

	&.latest {
		@include column(12);
		margin-bottom: 4rem;

		.article-card__container {
			height: 535px;
		}

		.article-card__bg-container {
			//width: calc(100% + 10rem);
			//margin-left: -5rem;
		}

		&:hover {
			.article-card__bg-container {
			//	width: calc(100% + 12rem);
			}
		}
	}

	&:hover {
		outline: none;
		.article-card__bg-container {
			//width: calc(100% + 1.5rem);
			// height: calc(100% + 1.5rem);
			box-shadow: 1rem 1rem 6rem rgba($motum-darkgrey, 0.1); 

			.article-card.on-specialty & {

			}
		}
		.article-card__img{
			width: 100%;
			height: 100%;
		}
		.article-card__content {
			transform: translateY(-1rem);
			box-shadow: 1rem 1rem 5rem rgba($motum-darkgrey, 0.15);
		}
	}
    &.on-specialty{
        &:hover{
            .article-card__bg-container {
                width: 100%;
                height: 100%;
            }
        }
    }

    @include desktop-small-below {
    	@include column(4);

    	&.featured {
    		@include column(8);
			&.latest {
				@include column(12);
			}
    	}
    }

    @media (max-width: 850px) {
        @include column(6);

        &.featured {
    		@include column(6);
    	}
    }

    @media (max-width: 600px) {
        @include column(12);

        &.featured {
    		@include column(12);
    	}
    }
}

.article-card__container {
	height: 460px;
	// margin-right: 3rem;
	transition: 0.15s ease-in-out all;
	box-shadow: 1rem 1rem 7rem rgba($motum-darkgrey, 0);
	display: flex;
	flex-wrap: wrap;
	align-content: flex-end;
	justify-content: flex-end;
	position: relative;

	.article-card.on-specialty & {
		//height: 280px;

        @include responsive-height(24vw, 250px, 340px);

        justify-content: flex-start;

		// margin-right: 0;
		//height: auto;
        @include desktop-large-below{
            margin-right: 0;
        }

        @include desktop-below{
           // @include responsive-height(20vw, 165px, 260px);
        }
	}

	@media (max-width: 600px) {
		height: 325px;

		.featured &, .latest & {
			height: 460px;
		}
	}
}

.article-card__bg-container {
	transition: 0.35s ease all;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	overflow: hidden;
	border-radius: 1rem;

}

// .article-card__bg {
// 	transition: 0.25s ease-in-out all;
// 	width: 110%;
// 	height: 110%;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	display: block;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: center center;
// }

.article-card__img {
	transition: 0.25s ease-in-out all;
	display: block;
	object-fit: cover;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 105%;
	height: 105%;


	.article-card.on-specialty & {
		transition: all .5s ease-in-out;
	}
}


.article-card__content {
	margin-right: -3rem;
	margin-bottom: 3rem;
	width: 100%;
	background: #fff;
	border-radius: 1rem;
	padding: 2rem;
	box-shadow: 1rem 1rem 5rem rgba($motum-darkgrey, 0.1);
	transition: 0.35s ease all;
	position: relative;
	z-index: 1;
	height:fit-content;

    .on-specialty &{
        margin-left: -3rem;
        margin-right: auto;
    }

	>span {
		display: block;
	}

	.article-card.latest & {
		margin-right: -1rem;
	}

	.article-card.featured &, .article-card.latest & {
		max-width: 640px;
	}
}

.article-card__title {
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.1;
	color: $motum-darkgrey;

	.article-card.latest & {
		font-size: 4rem;
	}

	.on-specialty & {
		font-size: 4rem;
		font-weight: 500;
	}
}

.article-card__summary {
	font-size: 1.55rem;
	line-height: 1.2;
	color: $motum-darkgrey;
	padding-top: 2rem;

	.article-card.latest & {
		font-size: 1.8rem;
	}
}

.article-card__tags {
	padding-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	font-size: 2rem;
	line-height: 1.2;
	color: $motum-darkgrey;
	font-weight: 500;

	>span {
		margin-right: 1rem;
		padding-right: 1rem;
		border-right: solid 1px rgba($motum-grey, 0.6);

		&:first-child {
			border-right: solid 1px $motum-lightgrey;
		}

		&:last-child {
			margin-right: 0;
			padding-right: 0;
			border-right: none;
		}
	}

	.article-card.latest & {
		font-size: 1.8rem;
	}

	.theme-yellow {
		color: $purple-saturated;
	}
	.theme-green {
		color: $green-saturated;
	}
	.theme-purple {
		color: $purple-saturated;
	}
	.theme-red {
		color: $red-saturated;
	}
	.theme-blue {
		color: $blue-saturated;
	}
}

.article-card__read-time {
	color: #aaaaaa;
}








