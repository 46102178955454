.post-autor {
    display: flex;
    align-items: center;
    border-bottom: solid 2px $motum-darkgrey;
    padding-bottom: 1rem;

    .promoted-post & {
        border-bottom: solid 2px #fff;
    }
}

.post-autor__img {
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    margin-right: 2rem;
    border-radius: 50%;
    position: relative;

    img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.post-autor__credit-type {
    display: block;
    font-size: 1.4rem;
    font-style: italic;
    padding-bottom: 2px;
    @include tablet-below {
        font-size:1.8rem;
    }

    .promoted-post & {
        color: #fff;
    }

}

.post-autor__details {
    color: $motum-darkgrey;
    font-size: 1.5rem;
    font-weight: 700;
    @include tablet-below {
        font-size:1.9rem;
    }

    .promoted-post & {
        color: #fff;
    }
}

.post-header__date {
    color: $motum-darkgrey;
    font-size: 1.6rem;
    padding: 1rem 0;
    display: block;

    .promoted-post & {
        color: #fff;
    }
    @include tablet-below {
        font-size:1.9rem;
    }
}

.post-header__nav {
    border-top: solid 4px $motum-darkgrey;
    padding-top: 1rem;

    .promoted-post & {
        border-top: solid 4px #fff;
    }
}

.post-header__nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;

    .pertitle {
        display: block;
        font-size: 1.4rem;
        font-style: italic;
        @include tablet-below {
            font-size:1.8rem;
        }

        .promoted-post & {
            color: #fff;
        }

    }

    .title {
        font-weight: 700;
        font-size: 1.7rem;
        @include tablet-below {
            font-size:1.9rem;
        }
    }

    .arrow {
        border: solid 2px;
        font-size: 1.5rem;
        padding: 5px 5px 3px;
        border-radius: 50%;
        margin-left: 2rem;
        transition: 0.35s ease all;

        &:hover, &:focus {
            outline: none;
        }

        .theme-yellow & {
            color: $bright-yellow;
            border-color: $bright-yellow;

            &:hover, &:focus {
                background: rgba($bright-yellow, 0.2);
            }
        }
        .theme-green & {
            color: $motum-green;
            border-color: $motum-green;

            &:hover, &:focus {
                background: rgba($motum-green, 0.2);
            }
        }
        .theme-purple & {
            color: $bright-purple;
            border-color: $bright-purple;

            &:hover, &:focus {
                background: rgba($bright-purple, 0.2);
            }
        }
        .theme-red &, .red-theme & {
            color: $bright-blue;
            border-color: $bright-blue;

            &:hover, &:focus {
                background: rgba($bright-blue, 0.2);
            }
        }
        .theme-blue & {
            color: $bright-blue;
            border-color: $bright-blue;

            &:hover, &:focus {
                background: rgba($bright-blue, 0.2);
            }
        }
    }

    .promoted-post & {
        .title {
            color: #fff;
        }
    }
}

.post-tags {
    width: 100%;
    position: relative;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet-small-below {
        flex-direction: column;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        @include tablet-small-below{
            display: none;
        }
        .promoted-post &{
            display: none;
        }

        .theme-yellow & {
            background: $bright-yellow;
        }
        .theme-green & {
            background: $motum-green;
        }
        .theme-purple & {
            background: $motum-purple;
        }
        .theme-red & {
            background: $motum-red;
        }
        .theme-blue & {
            background: $bright-yellow;
        }
    }

    >div {
        background: #fff;
        display: inline-block;
        padding-right: 2rem;
        position: relative;
        z-index: 2;
        @include phone-large-below {
            padding-right: 0;
        }
    }

    .promoted-post &>div {
        background: none;

        @include tablet-small-below {
            padding-right: 0;
        }
    }

    .quiz-visible &{
        opacity: 0;
    }
}

.post-tag {
    padding: 7px 10px;
    border-radius: 5px;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    font-weight: 700;
    transition: 0.35s ease all;
    position: relative;
    overflow: hidden;
    display: inline-block;

    @include tablet-below {
        font-size:2rem;
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        z-index: -1;
        transition: 0.35s ease all;
        opacity: 0;
    }

    &:hover, &:focus {
        //outline: none;

        &::before {
            opacity: 1;
        }
    }

    .theme-yellow & {
        box-shadow: inset 0 0 0 2px $bright-purple;
        //color: $bright-purple;

        &:hover, &:focus {

            &::before {
                background: rgba($bright-purple, 0.2);
            }
        }
    }
    .theme-green & {
        box-shadow: inset 0 0 0 2px $bright-purple;
        //color: $bright-purple;

        &:hover, &:focus {

            &::before {
                background: rgba($bright-purple, 0.2);
            }
        }
    }
    .theme-purple & {
        box-shadow: inset 0 0 0 2px $bright-purple;
        //color: $bright-purple;

        &:hover, &:focus {

            &::before {
                background: rgba($bright-purple, 0.2);
            }
        }
    }
    .theme-red & {
        box-shadow: inset 0 0 0 2px $bright-blue;
        //color: $bright-blue;

        &:hover, &:focus {

            &::before {
                background: rgba($bright-blue, 0.2);
            }
        }
    }
    .theme-blue & {
        box-shadow: inset 0 0 0 2px $bright-blue;
        //color: $bright-blue;

        &:hover, &:focus {

            &::before {
                background: rgba($bright-blue, 0.2);
            }
        }
    }

    .promoted-post & {
        box-shadow: inset 0 0 0 2px $bright-blue;
        background: rgba($bright-blue, 0.2);
        color:#fff;

        &:hover, &:focus {

            &::before {

            }
        }
    }

}

.post-header__img {
    width: 100%;
    position:relative;

    img {
        width: 100%;
    }

    .promoted-post & {
        height: 100%;
        position: absolute;
        img {
            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
        }
    }

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
    }
}















