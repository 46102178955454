[class*="specialty-illustration-fill"] {
    fill: $motum-darkgrey;
}

.portfolio-item {
    .basf-article {
        //background-color: lightblue;
        overflow: hidden;
        position: relative;
        padding: 2.75rem;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        width: 100%;
        margin-bottom: 0.5rem;
        div {
            width: 100%;
        }
        img {
            position: absolute;
            z-index: -1;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            margin-top: 0;
        }
        p {
            &:first-child {
                font-weight: 400;
                font-size: 2rem;
                margin: 0 0 -5px;
                text-transform: uppercase;
            }
            &:nth-child(2) {
                font-weight: 700;
                font-size: 3.3rem;
                margin: 0;
                line-height: 3.5rem;
            }
        }
        .motum-btn {
            margin-top: 2rem;
            font-size: 1.8rem;
            border-color: $motum-darkgrey;
            color: $motum-darkgrey;
            padding: 1rem 2rem;
            &:before {
                background-color: $motum-yellow;
            }
        }
    } //end basf articles
}


.specialty-header__tall {
    height: 600px;

    > div:after {
        display: none;
    }
}



.specialty-header-title-container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    .specialty-header-title-container{

    }
    @include desktop-small-below{
        flex-direction: column-reverse;
    }
    @include phone-large-below{
        margin-top: 3rem;
    }
}

.specialty-header__content {
//    @include column(8);
    width: 100%;
    position: relative;
    z-index: 5;
    @include desktop-medium-below {
        //@include column(9);
        // padding-left: 20px;
    }
    @include desktop-below {

        @include column(12);
        //padding: 0 40px;
    }
    @include desktop-small-below {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @include phone-large-below {

    }
}




.specialty-header-subspecialties, .specialty-header__body-nav {
    @include column(8);
    position: relative;
    transition: opacity .6s ease-in-out;
    opacity: 0;
    @include desktop-small-below {
        @include column(8);
    }
    @include tablet-below {
        @include column(12);
    }
    .is-visible & {
        opacity: 1;
    }
    .is-exiting & {
        opacity: 0;
    }
}





.subspecialty-rows {
    position: relative;
    z-index: 4;
}

.subspecialty-row {
    width: 100%;
    display: flex;
    align-items: center;
    //border-bottom: 1px solid #cecece;
    position: relative;
    //padding: 10rem 0;

    &:first-child{
       // margin-top: 10rem;
    }

    @for $i from 1 through 16 {

        &:nth-child(#{$i}){
            z-index: 30 - $i;
        }
    }

    //&:nth-child(even){
    //    &::before{
    //        content: '';
    //        display: block;
    //        width: 100%;
    //        height: 10%;
    //        position: absolute;
    //        left: 0;
    //        top: 0;
    //        opacity: .1;
    //        background: linear-gradient(to bottom, var(--color-primary) 0%,rgba(255,255,255,0) 100%);
    //
    //    }
    //    &::after{
    //        content: '';
    //        display: block;
    //        width: 100%;
    //        background-color: red;
    //        height: 10%;
    //        position: absolute;
    //        left: 0;
    //        bottom: 0;
    //        opacity: .1;
    //        background: linear-gradient(to top,  var(--color-primary)  0%,rgba(255,255,255,0) 100%);
    //
    //
    //    }
    //}




    > .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        @include desktop-below{
            flex-direction: column;
        }
    }
    &:nth-child(even) {
        > .wrapper {
            flex-direction: row-reverse;
            @include desktop-below{
                flex-direction: column;
            }
        }

        .subspecialty-row__proof {

        }
    }

    .subspecialty-row__content {
        @include column(5.5);
        float: none;
        padding: 15rem 0 15rem 0;
        @include desktop-small-below{
            padding: 15rem 0 0 0;
        }
        @include desktop-below{
            @include column(12);
        }
        @include phone-large-below{
            padding: 8rem 5rem 0rem 5rem;
        }
    }




    &.is-in-view{
        p{
            opacity: 1;
            .is-animating &{
                opacity: 0;
            }
        }
        p strong::before{
           width: 5rem;
            .is-animating &{
                width: 0;
            }
        }
    }

    p {
        font-weight: 500;
        font-size: 2rem;
       // opacity: 0;
        transition: opacity .5s ease-in-out .3s;
        color: var(--color-black);
        @include phone-large-below{
            font-size: 2.5rem;
        }
    }
    p strong {
        max-width: 85%;
        display: block;
        color: var(--color-secondary-saturated);
        @include phone-large-below{
            max-width: none;
        }
        &:before {
            content: '';
            display: block;
            height: .3rem;
            background-color:  var(--color-primary);
            margin-bottom: 2rem;
            width: 0;
            transition: width .9s cubic-bezier(.64,.39,.03,1.24) .5s;
        }
    }
}


.subspecialty-row__body{
    display: block;
}

.subspecialty-row__title {
    @extend %heading-two;
    margin-bottom: 2rem;
    color: var(--color-black);
    display: block;

    transition: opacity .3s ease-in-out;
    opacity: 1;
    .is-animating.is-leaving &{
        opacity: 0;
    }
}
.subspecialty-row__subtitle {
    color: #7232de;
    //text-transform: uppercase;
    margin-bottom: 1.3rem;
    display: block;
    color: var(--color-secondary-saturated);

    @extend %heading-three;
    transition: opacity .3s ease-in-out;
    opacity: 1;
    .is-animating.is-leaving &{
        opacity: 0;
    }
}



.specialty-header-placeholder{
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    body[class*="page-specialty-"] &{
        display: none;
    }

}

.specialty-header, .specialty-header-placeholder{
  //  height: 850px;
    padding: 15rem 0 8rem 0;
    @include desktop-medium-below{
      //  height: 80vh;
    }
    overflow:hidden;

}

.specialty-fixed-header{
    width:100%;
    z-index: 1;
   // position: fixed;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 2rem 0;
    transition: transform .5s ease-in-out;
    //display: none;
    //opacity: 0;

    transform: translateY(-100%);
    top: -200px;
    body:not(.mobile-menu-open) & {
        .header--is-fixed & {
            transform: translateY(-100%);
            top: 100%;
        }
    }
    &.is-visible{
       // opacity: 1;
        transform: translateY(0);
        //top: 77px;
    }
    &.analytics-and-engagement-marketing-automation{
        background-color: $motum-yellow;
    }
    &.branding-and-design-strategic-marketing{
        background-color: $motum-green;
    }
    &.b2b-websites-and-apps{
        background-color: $motum-blue;
    }
    &.content-and-creative-b2b-communications{
        background-color: $motum-red;
    }
    &.b2b-marketing-process-and-approach{
        background-color: $motum-lightgrey;
    }
}



.specialty-fixed-header__title{
    font-size: 3.5rem;
    font-weight: 800;
  //  padding-bottom: 1rem;
    //margin-bottom: 3rem;
    text-align: left;
    overflow: hidden;
    margin-top: 0;


    > span{
        display: block;
        position: relative;
    }
    @include desktop-large-below{

    }
    @include desktop-small-below{
        font-size: 8rem;
    }
    @include tablet-below{
        padding-right: 0;
        text-align: center;
    }
    @include phone-large-below{
        font-size: 8.5rem;
    }

    .specialty-header.static &{
        margin-bottom: 1rem;
    }


    .specialty & {
        padding-right: 10.5rem;
        @include tablet-below{
            padding-right: 0;
        }
    }
}


.specialty-header{
    position:relative;
    width:100%;
   // height:auto;

    z-index:3;
    margin:0 auto;

    background-position: center center;
    background-size: cover;

    //padding-bottom: 10rem;

    display: flex;
    align-items: center;

    min-height: 100vh;
    @media (min-height: 1100px) {
        //min-height: auto;
    }

    .wrapper{
        position: relative;
        z-index: 1;
    }

    //
    background-color: var(--color-primary);


    //.is-animating &{
    //    background: transparent;
    //}


}


.specialty-header__illustration{
    display: block;
}



.specialty-header__image{
    //@include column(4.2);
    //padding-right: 8rem;
   // @include column(4);
    width: 50rem;
   // margin-right: 5rem;
   // margin-left: 5rem;
    transform: translateX(0%);


    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    //width: 500px;
    //height: 500px;
    &.desktop-only {
        padding-top: 3rem;
    }
    @include desktop-small-above{
        margin-left: 10rem;
    }
    @include desktop-xlarge-above{
        margin-right: -15rem;
    }
    @include tablet-below{
        @include column(7.5);
        float: none;
        margin: 0 auto;
        padding: 0;
        //max-width: 250px;
       // margin-top: 2rem;
    }
    @include phone-large-below{
        margin-bottom: 3rem;
    }

    svg{
        position: relative;
        z-index: 1;
    }
    img{
        margin-top: -2rem;
        max-width: 100%;
        z-index: 0;
    }

    @include phone-large-below{
        @include column(9);
        float: none;
    }
}



.specialty-header__btn{
    position: absolute;
    top: 50%;
    font-size: 6rem;
    z-index: 10;
    overflow: hidden;

    display: none;
    @include desktop-small-below{
        display: block;
    }

    i{
        display: block;
        transform: translateX(0);
    }

    &--prev{
        transform: translateX(-150%) translateY(-50%);
        i{
            transition: transform 0.5s cubic-bezier(0.64, 0.39, 0.03, 1.24) .1s;
        }
        .is-animating &{
            i{
                transform: translateX(100%);
            }
        }
        .is-animating.is-leaving &{
            i{
                transform: translateX(-100%);
            }
        }
    }
    &--next{
        transform: translateX(150%) translateY(-50%);
        right: 0;
        i{
            transition: transform 0.5s cubic-bezier(0.64, 0.39, 0.03, 1.24) .2s;
        }
        .is-animating &{
            i{
                transform: translateX(-100%);
            }
        }
        .is-animating.is-leaving &{
            i{
                transform: translateX(100%);
            }
        }
    }



}


.specialty-header__pretitle {
    @extend %heading-three;
    margin-bottom: 1rem;
    display: none;
    overflow: hidden;

    > span {
        display: block;
        position: relative;
        transform: translateY(0);
        transition: transform .5s cubic-bezier(0.64, 0.39, 0.03, 1.24) 0.1s;
        .is-animating &{
            transform: translateY(100%);
        }
        .is-animating.is-leaving &{
            transform: translateY(-100%);
        }

    }

    @include desktop-small-below{
        display: block;
    }

}


.specialty-header__title{
    @extend %heading-one;
    //font-size: 10.5rem;
    //padding:0 0 15px;
    //font-weight: 700;
    //color: $motum-darkgray;
    padding-bottom: 1rem;
    // padding-right: 10.5rem;
    margin-bottom: 3rem;
    text-align: left;
    //@include slideInnerTextOnTransition();
    //overflow: visible;
    overflow: hidden;
    margin-top: 0;


    > span{
        display: block;
        position: relative;
    }
    @include desktop-large-below{

    }
    @include desktop-small-below{
        font-size: 8rem;
    }
    @include desktop-small-below{
        padding-right: 0;
        text-align: center;
    }
    @include phone-large-below{
        font-size: 8.5rem;
    }

    .specialty-header.static &{
        margin-bottom: 1rem;
    }


    .specialty & {
        padding-right: 10.5rem;
        @include tablet-below{
            padding-right: 0;
        }
    }
}


.specialty-header__subtitle{
    display: block;
    @extend %heading-three;
    position: relative;
    margin-bottom: 5rem;
    width: 100%;
    padding: 1.5rem 0;

    > span{
        display: block;
        overflow: hidden;
    }
    > span > span{
        display: block;
        position: relative;
        line-height: 1.2;
    }

}

.specialty-header__subtitle:before, .specialty-header__subtitle:after{
    position: absolute;
    width: 100%;
    height: 0;
    background-color: $motum-darkgray;
    display:block;
    content: '';
    left: 0;
    z-index: 2;
    transition: height .2s ease-in-out .1s;
    height: 4px;
    .is-visible &{

    }
    .is-animating &{
        height: 0;
    }

}



.specialty-header__subtitle:before{
    top: 0;
}

.specialty-header__subtitle:after{
    bottom: 0;
}




.specialty-header__orb{
    display: block;
    width: 90%;
    padding-bottom: 90%;
    //height: 300px;
    background-color: var(--color-secondary);
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: all .7s cubic-bezier(.64,.39,.03,1.24);


    .is-animating[class*="to-specialties-"] &{
        width: 0;
        padding-bottom: 0;
        transition-delay: .2s;
    }




}



.specialty-header-subspecialty{
    margin-top: 2rem;
    display: none;
    z-index: 0;
    transition: transform .5s cubic-bezier(.64,.39,.03,1.24);

    @include desktop-small-below{
        margin-top: 3rem;
    }

    p{
        font-size: 2rem;
    }

    .is-visible &{
        &.visible{
            opacity: 1;
            z-index: 1;
        }

    }
    .is-exiting &{
        //opacity: 0;
        //&.visible{
        //  opacity: 0;
        //}
    }
}




.specialties-nav{
    margin-top: -10rem;
    margin-bottom: 3rem;
    ul{
        display: flex;
    }
    li{
        margin-right: 8rem;
        @for $i from 1 through 25{
            &:nth-child(#{$i}) a{
                transition-delay: ((.05s * $i));
            }
        }
        @include desktop-medium-below{
            margin-right: 6rem;
        }
        @include desktop-small-below{
            margin-right: 4rem;
        }
    }
    @include desktop-small-below{
        display: none;
    }
}

.specialty-nav-link{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-top: 1rem;
    //line-height: 1;

    .is-changing &{
        pointer-events: none;
    }

    transition: all .5s cubic-bezier(.53,-.01,0,1.03);
    // Only transition navlinks if not going to another specialty

    //.is-animating:not([class*=" to-specialties-"]) &{
    //.is-animating &{
    //    opacity: 0;
    //    transform: translateY(1rem);
    //}

    li:not(.active) &{
        &:hover, &:focus{
            > span:after, > span:before{
                width: 100%;
                left: 0;
            }
            > span > span > span {
                color: rgba(58, 54, 60, 1);
            }
        }
    }

    &::before{
        content: '';
        display: block;
        background-color: var(--color-secondary);
        width: 0;
        height: 3px;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 1s cubic-bezier(.53,-.01,0,1.03) .5s, background-color .5s ease-in-out .2s;
        //.theme-yellow &, .to-specialties-analytics-and-engagement-marketing-automation &{
        //    background-color: $motum-green;
        //}
        //.theme-blue &, .to-specialties-b2b-websites-and-apps &{
        //    background-color: $motum-yellow;
        //}
        //.theme-green &, .to-specialties-branding-and-design-strategic-marketing &{
        //    background-color: $motum-purple;
        //}
        //.theme-red &, .to-specialties-content-and-creative-b2b-communications &{
        //    background-color: $motum-blue;
        //}
        //.theme-purple &, .to-specialties-b2b-marketing-process-and-approach &{
        //    background-color: $motum-purple;
        //}


        @include desktop-small-below{

            height: 2px;
        }

        .is-animating &{
            transition-delay: 0s;
            width: 0;
        }

    }

    .active &{
        cursor: default;
        > span > span > span {
            color: rgba(58, 54, 60, 1);
        }
        &::before{
            width: 3rem;
        }

    }
}

// Overflow box
.specialty-nav-link > span{
    display: block;
    position: relative;
    //  height: 25px;
    line-height: 20px;
    margin-bottom: 0.1rem;
    transition: color .2s ease-in-out;
    @include desktop-medium-below{
        margin-bottom: -.1rem;
    }
    @include desktop-small-below{
        margin-bottom: -.5rem;
    }
    .is-visible &{
        &:after {
            height: 2rem;
        }
    }

    &:nth-child(2){
        &:before{
            transition-delay: .075s;
        }
        &:after{
            transition-delay: .275s;
        }
    }

    &:before {
        background-color: rgba($motum-darkgray, .1);
        transition: all .25s cubic-bezier(.694, .048, .335, 1);
        pointer-events: none;
        z-index: 2;
    }

    &:before, &:after{
        content: '';
        display: block;
        position: absolute;
        right: 0;
        left: auto;
        bottom: -2px;
        width: 0;
        .is-visible & {
            height: 3px;
        }
        .is-exiting & {
            height: 0;
        }
    }

    &:after {
        background-color: rgba($motum-darkgray, .1);
        z-index: 1;
        transition: all .15s cubic-bezier(.694, .048, .335, 1) .2s;
        pointer-events: none;
    }


}

.specialty-nav-link > span > span{
    overflow: hidden;
    display: block;
}


.specialty-nav-link > span > span > span{
    display: inline-block;
    position: relative;
    top: 0;

    color: rgba(58, 54, 60, 0.5);

    font-size: 2.2rem;
    font-weight: 800;
    line-height: 1;
   // text-transform: uppercase;
  //  letter-spacing: .05rem;

    transition: transform 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24), color 0.3s ease-in-out;
    transform: translateY(110%);
    .first-load & {
        transform: translateY(0);
    }

    @include desktop-below{
        font-size: 1.6rem;
    }

    .is-animating &{
        //opacity: 0;
       // transform: translateY(1rem);
        transform: translateY(-100%);
    }

    //.is-exiting & {
    //    transform: translateY(-110%);
    //}





}




.subspecialty-row__proof {
    @include column(6);
    float: none;
    margin: 5rem -10rem 5rem 0;
    display: flex;
    flex-direction: column;


    .subspecialty-row:first-child &{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .subspecialty-row:last-child &{
        margin-bottom: 5rem;
    }

    @include desktop-large-below{
        @include column(5.5);
        margin-right: -5rem;
    }
    @include desktop-small-below{
        margin-right: 0;
    }
    @include desktop-below{
        @include column(12);
    }
    @include desktop-below{
        flex-direction: row;
    }
    @include phone-large-below{
        margin-bottom: 5rem;
        padding: 0 4rem;
        flex-direction: column;
    }

    a{
        transition: opacity .6s ease-in-out, transform .5s ease-in-out;
        @include desktop-below{
            @include column(6);
        }
        opacity: 0;
        .is-in-view & {
            opacity: 1;
            .is-animating &{
                opacity: 0;
            }
        }

    }




    @include desktop-xlarge-above{
        .subspecialty-row:nth-child(even) &{
            margin-right: auto;
            margin-left: -8rem
        }
    }




    a:first-child {
        @include desktop-above{
            transform: translateX(-6rem);
            .is-in-view &{
                transform: translateX(-3rem);
                .is-animating &{
                    transform: translateX(-6rem);
                }
            }
        }
    }
    a:last-child{
        @include desktop-above{
            transform: translateX(0rem);
            transition-delay: .3s;
            .is-in-view & {
                transform: translateX(3rem);
                .is-animating &{
                    transform: translateX(0rem);
                }
            }
        }
    }





}





.specialty-header__transit-circle-next{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    // width: .1vw;
    //height: .1vh;
    //width: 200px;
    //height: 200px;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    //border-radius: 100%;
    z-index: 0;



    transition: all .2s cubic-bezier(.48,.24,.53,.7);

    width: .1vw;
    height: .1vh;


    .is-animating &{
        transition-duration: 1s;
        width: 350vw;
        height: 350vw;
    }

    .page-specialties &{
       // transition-duration: 0s;
    }

    .is-animating.is-exiting &{
        width: .1vw;
        height: .1vh;
    }


    .is-animating.to-specialties{
        opacity: 0;
    }


    .is-animating:not(.is-leaving){
        transition-duration: 0s;
    }






    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    }

    circle{
        fill: transparent;

        .page-analytics-and-engagement-marketing-automation &, .to-specialties-analytics-and-engagement-marketing-automation &{
            fill: $motum-yellow;
        }
        .page-branding-and-design-strategic-marketing &, .to-specialties-branding-and-design-strategic-marketing &{
            fill: $motum-green;
        }
        .page-b2b-websites-and-apps &, .to-specialties-b2b-websites-and-apps &{
            fill: $motum-blue;
        }
        .page-content-and-creative-b2b-communications &, .to-specialties-content-and-creative-b2b-communications &{
            fill: $motum-red;
        }
        .page-b2b-marketing-process-and-approach &, .to-specialties-b2b-marketing-process-and-approach &{
            fill: $motum-lightgrey;
        }
        //fill: red;
    }



}


.specialty-header-placeholder__inner{
    position: absolute;
    min-height: 100vh;
    transform: translateY(-50%);
    z-index: 10;

}



//
//
//
//.specialty-header__transit-circle-exit{
//    position: absolute;
//    display: block;
//    left: 50%;
//    transform-origin: center center;
//    transform: translate(-50%, -50%);
//    z-index: 0;
//
//
//
//    transition: all .9s ease-in-out;
//    width: 150vw;
//    height: 150vw;
//
//    //.is-animating &{
//    //    width: .1vw;
//    //    height: .1vh;
//    //}
//
//
//
//
//    .is-animating.to-specialties{
//        opacity: 0;
//    }
//
//
//    .is-animating:not(.is-leaving){
//        transition-duration: 0s;
//    }
//
//
//
//    circle{
//        .page-specialties-analytics-and-engagement-marketing-automation &, .to-specialties-analytics-and-engagement-marketing-automation &{
//            fill: $motum-yellow;
//        }
//        .page-specialties-branding-and-design-strategic-marketing &, .to-specialties-branding-and-design-strategic-marketing &{
//            fill: $motum-green;
//        }
//        .page-specialties-b2b-websites-and-apps &, .to-specialties-b2b-websites-and-apps &{
//            fill: $motum-blue;
//        }
//        .page-specialties-content-and-creative-b2b-communications &, .to-specialties-content-and-creative-b2b-communications &{
//            fill: $motum-red;
//        }
//        .page-specialties-b2b-marketing-process-and-approach &, .to-specialties-b2b-marketing-process-and-approach &{
//            fill: $motum-lightgrey;
//        }
//        //fill: red;
//    }
//
//
//
//}
//




.specialty-header-cta{
    float: right;
    display: none;
    //position: absolute;
    //right: 0;
    //bottom: -16rem;
    //transition: all 1s cubic-bezier(.53,-.01,0,1.23);
    //transform: scale(1.4);
    //transform-origin: center;
    //.is-visible &{
    //  transform: scale(1);
    //}
    > span{
        display: inline-block;
        padding: 1.1rem 1.5rem;
        font-weight: 700;
        font-size: 3rem;
        color: $motum-darkgray;
        position: relative;
        transition: all .5s cubic-bezier(.53,-.01,0,1.23);
        &:last-child{
            margin-top: -1rem;
            transition-delay: .04s;
            z-index: 2;
        }
        i{
            font-size: 2rem;
        }
    }

    >span:before, >span:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;

    }
    > span:before{
        transition: all .5s cubic-bezier(.53,-.01,0,1.23) .3s;


        .content-and-creative-b2b-communications &{
            background-color: $motum-blue;
        }
        .b2b-websites-and-apps &{
            background-color: $motum-yellow;
        }
        .analytics-and-engagement-marketing-automation &{
            background-color: $motum-green;
        }
        .branding-and-design-strategic-marketing &{
            background-color: $motum-purple;
        }

        width: 100%;
        .is-animating &{
            transition-delay: 0s;
            width: 0;
        }
        .b2b-marketing-process-and-approach & {
            background-color: $motum-purple;
        }
        .analytics-and-engagement-marketing-automation & {
            background-color: $motum-green;
        }
        .branding-and-design-strategic-marketing & {
            background-color: $motum-purple;
        }
        .content-and-creative-b2b-communications & {
            background-color: $motum-blue;
        }
    }
    > span:after{
        background-color: $motum-darkgray;
        transition: all .5s cubic-bezier(.53,-.01,0,1.00);

    }
    > span:last-child:before{
        transition-delay: .2s;
        .is-animating &{
            transition-delay: .2s;
        }
    }
    > span > span{
        position: relative;
        z-index: 3;

        display: block;

        transition: all .75s cubic-bezier(.53,-.01,0,1.03);

        opacity: 1;
        transform: translateX(0);


        .is-animating &{
            transform: translateX(-3rem);
            opacity: 0;
        }
    }
    > span:last-child > span{
        transition-delay: .1s;
    }

    &:hover > span{
        transform: translateX(2rem);
    }
    &:hover > span:after{
        width: 100%;
        .is-exiting &{
            width: 0;
        }
    }
    &:hover > span > span{
        color: #fff;
    }

    @include tablet-below{
        float: none;
        margin: 0 auto;
    }

}





// Specialties logo animation
.specialty__logos{
    ul{
        li{
            display:inline-block;
            img{
                transform:scale(0);
                // transition: all 1s cubic-bezier(.53,-.01,0,1.23);
                max-width:70px;
                @include tablet-below{
                    max-width:75px;
                }
                @include phone-large-below{
                    max-width:50px;
                }
            }
        }
    }
}
.is-in-view .specialty__logos{
    ul{
        li{
            img{
                animation: logoSize .7s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
            }
        }
        li {
            @for $i from 1 through 14{
                &:nth-child(#{$i}) > img{
                    animation-delay: .25s + (.075s * $i);
                    animation-duration: .7s;
                }
            }
        }
    }
}


// Specialties index

.department-items{
    // min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 15;

    > div{
        width: 100%;
    }

    h2 {
        overflow: visible;
    }
    //@include desktop-medium-below{
    //
    //    padding: 0 20px;
    //}
    @include tablet-below{
        //  text-align: center;
    }


}

.department-item{
    //margin-bottom: 1rem;
    color: $motum-darkgray;
    transition: all .2s ease;
    float: left;
    clear: left;
    position: relative;


    &:before{
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: $motum-grey;
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
    }



    @include tablet-below{
        //width: 100%;
        width: 100%;
        padding: 1.3rem 2rem 2.7rem 0;
        &:before{
            display: block;
        }
        &:last-child:before{
            display: none;
        }
    }








    &:hover{
        text-decoration: none;
        .department-item__subtitle{
            height: 4rem;
            @include tablet-below{
                height: auto;
            }
        }

        &.b2b-marketing-process-and-approach h2.department-item__title{
            color: $motum-hyperlink-purple;
            i {
                color: $motum-hyperlink-purple;
                border-color: $motum-hyperlink-purple;
                transform: translate(2rem, -33%);
            }
        }
        &.content-and-creative-b2b-communications h2.department-item__title{
            color: $motum-hyperlink-red;
            i {
                color: $motum-hyperlink-red;
                border-color: $motum-hyperlink-red;
                transform: translate(2rem, -33%);
            }
        }
        &.b2b-websites-and-apps h2.department-item__title{
            color: $motum-hyperlink-blue;
            i {
                color: $motum-hyperlink-blue;
                border-color: $motum-hyperlink-blue;
                transform: translate(2rem, -33%);
            }
        }
        &.analytics-and-engagement-marketing-automation h2.department-item__title{
            color: $motum-hyperlink-yellow;
            i {
                color: $motum-hyperlink-yellow;
                border-color: $motum-hyperlink-yellow;
                transform: translate(2rem, -33%);
            }
        }
        &.branding-and-design-strategic-marketing h2.department-item__title{
            color: $motum-hyperlink-green;
            i {
                color: $motum-hyperlink-green;
                border-color: $motum-hyperlink-green;
                transform: translate(2rem, -33%);
            }
        }


    }

}

@keyframes departmentItemSlideFadeIn{
    to{
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes departmentItemSlideFadeOut{
    to{
        opacity: 0;
        transform: translateX(-40px);
    }
}




.department-item__title{
    //font-size: 7rem;
    font-weight: 900;
    margin-bottom: -1rem;
    display: block;
    transition: color .2s ease-in-out;
    line-height: 1.3;
    position: relative;
    //background-color: yellow;
    padding-right: 10rem;

    $map: (400px: 20px, 550px: 25px, 768px: 42px, 1200px: 70px, 1600px: 80px);
    @include poly-fluid-sizing('font-size', $map);

    i {
        font-size: 2.75rem;
        color: #adadad;
        margin: 0 0 0 5rem;
        border: 3px solid #adadad;
        border-radius: 50%;
        padding: 7.5px;
        position: absolute;
        top: 50%;
        right: 3rem;
        transition-duration: 0.75s;
        transition-delay: 0.075s;
        transform: translate(0, -33%);
        //transition-timing-function: cubic-bezier(0,.56,.18,.98);
        .is-active & {
            color: #adadad;
            border-color: #adadad;
        }
        @include tablet-below {
            right: 0;
            color: $motum-darkgrey;
            border-color: $motum-darkgrey;
        }

    }


    @include tablet-below{
        margin-bottom: .2rem;
    }

    @include phone-large-below{
        margin-bottom: .5rem;
    }
    //@include responsive-font(6vw, 25px, 70px);

    .is-active &{
        color: #adadad;
        color: lighten($motum-grey, 40%);
    }

    //@include height-medium{
    //  $map: (576px: 22px, 1200px: 40px, 1600px: 60px);
    //  @include poly-fluid-sizing('font-size', $map);
    //}



    //@include height-small{
    //  @include responsive-font(3vw, 25px, 70px);
    //}


    //@include desktop-medium-below{
    //    font-size: 8rem;
    //}
    //@include tablet-below{
    //    font-size: 7rem;
    //}
    //@include phone-large-below{
    //    font-size: 5rem;
    //}


}

.department-item__subtitle{
    font-weight: 500;
    display: block;
    height: 0;
    overflow: hidden;
    transition: height .2s ease-in-out;
    text-align: left;
    padding-right: 10rem;

    @include tablet-below{
        height: auto;
        overflow: visible;
        color: $motum-grey;
        font-weight: 400;
    }

    $map: (400px: 12px, 550px: 15px, 768px: 22px, 1600px: 30px);
    @include poly-fluid-sizing('font-size', $map);

    //@include tablet-below{
    //    font-size: 2rem;
    //}




}





.transition-department-item{
    opacity: 1;
    transform: translateX(0);
    transition: all .5s cubic-bezier(.53,-0.01,0,1.03);


    @for $i from 1 through 5{
        &:nth-child(#{$i}){
            transition-delay: (.045s * $i) !important;
            //animation-duration: 1s !important;
        }
    }

    .is-animating &{
        opacity: 0;
        transform: translateX(-40px);
    }
}


.transition-specialties-header-bgcircle{

}


.subspecialty__pattern{
    width: 13.5rem;
    position: absolute;
    top: 20%;
    .subspecialty-row:nth-child(odd) &{
        left: -10rem;
    }
    .subspecialty-row:nth-child(even) &{
        right: -10rem;
    }
    .subspecialty-row:nth-child(1) &{

    }
    polygon{
        transition: opacity .5s ease-in-out;
        opacity: 0;
        .is-in-view &{
            opacity: 1;
        }

        .theme-green &{
              fill: rgba($motum-purple, .45);
        }
        .theme-blue &{
              fill: rgba($motum-blue, .45);
        }
        .theme-red &{
              fill: rgba($motum-blue, .45);
        }
        .theme-yellow &{
              fill: rgba($motum-green, .45);
        }
        .theme-purple &{
              fill: rgba($motum-purple, .45);
        }

        //animation:  5s ease-in-out steps(5, start) forwards load ;
        //transform-origin: center;
        //transform-box: fill-box;
        //animation: rotate 6s ease-in-out 1 forwards;
        @for $i from 1 through 30 {
            &:nth-child(#{$i}) {
                transition-delay: #{$i * .05}s;
            }
        }
    }
}
//
//@keyframes rotate {
//    0%{
//        transform: rotate(0deg);
//    }
//    15% {
//        transform: rotate(1deg);
//    }
//    30%{
//        transform: rotate(1deg);
//    }
//    45%{
//        transform: rotate(2deg);
//    }
//    60%{
//        transform: rotate(2deg);
//    }
//    75%{
//        transform: rotate(3deg);
//    }
//    90%{
//        transform: rotate(3deg);
//    }
//
//}



.specialty-header-scroll-btn{
    font-size: 2.35rem;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-family: $font-body;
    //max-width: 35rem;
    text-align: left;
    background: transparent;
    border: 0;
    color: $motum-darkgrey;
    cursor: pointer;
    .is-animating &{
        pointer-events: none;
    }
    &:hover{
        .specialty-header-scroll-btn__orb{
            transform: scale(1.1);
           // background-color: var(--color-secondary);
            &::before{
                transform: translateX(-50%) translateY(300%);
            }
            &::after{
                transform: translateX(-50%) translateY(-50%);
              //  color: #fff;
              //  color: var(--color-secondary);
            }
        }
        .specialty-header-scroll-btn__text{
            transform: translateX(1rem);
        }
    }
}
.specialty-header-scroll-btn__text{
    transition: all 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24);
    .is-animating &{
        transform: translateX(-2rem);
        opacity: 0;
        transition-delay: .2s;
    }
    .is-animating.is-leaving{
        transition-delay: .2s;
    }
   strong{
       display: inline-block;
       position: relative;
       margin-left: .2rem;
       color: $motum-darkgrey;
       &::before{
           content: '';
           display: block;
           width: 100%;
           position: absolute;
           bottom: -.3rem;
           left: 0;
           height: .3rem;
           background-color: $motum-darkgrey;
       }
   }
}
.specialty-header-scroll-btn__orb{
    display: block;
    width: 8rem;
    height: 8rem;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    flex-shrink: 0;
    transition: all 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24);
    position: relative;
    overflow: hidden;
    .is-animating &{
        transition-delay: .3s;
       transform: scale(0);
    }
    &::before, &::after{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24);
        .is-animating &{
            transform: translate(-50%, 300%);
        }
        .is-animating.is-leaving &{
            transform: translate(-50%, -300%);
        }
    }
    &::before{
        content: '\e918';
        font-family: 'icoMoon';
        font-size: 4rem;
    }
    &::after{
        content: '\e916';
        font-family: 'icoMoon';
        font-size: 3rem;
        transform: translateX(-50%) translateY(-300%);
    }

}

.specialty-cta-row {
    margin-top: 8rem;
    padding: 5rem 0;
    position: relative;
    @include desktop-small-below {
        margin-top: 12rem;
    }
    .wrapper {
        display: flex;
        align-items: center;
        @include desktop-small-below {
            flex-direction: column;
            align-items: center;
        }
    }
    &__background {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 0;
    }
    .specialty-cta__btn {
        padding: 1.5rem 2.8rem;
        font-size: 1.8rem;
        font-weight: 700;
        border: solid 3px transparent;
    }
    .specialty-header__image {
        margin-right: 12rem;
        margin-top: -9rem;
        margin-left: 0;
        @include desktop-small-below {
            margin-right: 0;
        }
    }
    &__text {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem;
        overflow:hidden;
        @include desktop-small-below {
            align-items: center;
            margin: 4rem 0 3rem;
            h2 {
                text-align:center;
            }
        }
        h2 {
            font-size: 7rem;
        }
        p {
            font-weight: 700;
        }
    }

    .yellow-theme & {
        .specialty-cta-row__background {
            background-color: $motum-yellow;
        }
        .specialty-cta__btn {
            background-color: $motum-green;
        }
    }
    .red-theme & {
        .specialty-cta-row__background {
            background-color: $motum-red;
        }
        .specialty-cta__btn {
            background-color: $motum-blue;
        }
    }
    .blue-theme & {
        .specialty-cta-row__background {
            background-color: $motum-blue;
        }
        .specialty-cta__btn {
            background-color: $motum-yellow;
        }
    }
    .green-theme & {
        .specialty-cta-row__background {
            background-color: $motum-green;
        }
        .specialty-cta__btn {
            background-color: $motum-purple;
        }
    }
}



.is-in-view.specialty-cta-row {
    .specialty-header__orb {
        display: block;
        width: 90%;
        padding-bottom: 90%;
        //height: 300px;
        background-color: var(--color-secondary);
        border-radius: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
        transition: all 0.8s cubic-bezier(.64,.39,.03,1.24);
        z-index: 0;
    }
    .specialty-header__illustration {
        transform: translateY(0);
        transition: all 0.4s cubic-bezier(0.694, 0.048, 0.335, 1) 0.3s;
        opacity: 1;
    }
    .is-in-view.specialty-cta-row__text {
        .specialty-cta__btn {
            transform: translateY(0);
            transition: transform 0.5s cubic-bezier(0.64, 0.39, 0.03, 1.24) 0.2s;
        }
        h2, p, a {
            span {
                transform: translateY(0);
                transition: transform 1s cubic-bezier(0.64, 0.39, 0.03, 1.24) 0.1s;
            }
        }
    }
} 

.specialty-cta-row {
    .specialty-header__orb {
        transform: translate(-50%, -50%) scale(0.05);
    }
    .specialty-header__illustration {
        transform: translateY(50px);
        opacity: 0;
        transition: all 0.4s cubic-bezier(0.694, 0.048, 0.335, 1) 0.2s;
    }
    .specialty-cta__btn {
        transform: translateY(100px);
    }
    .specialty-cta-row__text h2, .specialty-cta-row__text p, .specialty-cta-row__text a {
        overflow: hidden;
        span {
            display: block;
        }
    }
    .specialty-cta-row__text h2 {
        padding-bottom: 0.5rem;
    }
    .specialty-cta-row__text p span, .specialty-cta-row__text a span {
        transform: translateY(80px);
    }
    .specialty-cta-row__text h2 span {
        transform: translateY(140px);
        transition: transform 0.5s cubic-bezier(0.64, 0.39, 0.03, 1.24) 0.1s;
    }
}

