
.splash{
    text-align: center;
    // background-color: #2a303b;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    @include phone-large-below{
        padding: 0 2rem;
    }
    // @include tablet-below{
    //     background-image: url('/images/interface/home_splash_bg2.jpg');
    //     background-size: cover;
    // }

    .wrapper{
        z-index: 10;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include desktop-small-below{
            padding: 2rem;
        }
        @include phone-large-below {
            top: 40%;
        }
    }





    .page-ready &{
        .splash__title > span, .splash__subtitle > span, .splash__definition > span{
            animation: peekIn 1s cubic-bezier(0,.56,.18,.98) 0.2s 1 forwards normal;
        }
        .splash__subtitle > span{
            animation-delay: .6s;
        }
    }


}


//
//.splash__btn{
//    position: relative;
//    z-index: 99;
//    cursor: pointer;
//    background-color: $motum-blue;
//    font-weight: 700;
//    color: #fff;
//    font-size: 2.5rem;
//    padding: 1rem 2.5rem;
//    border: 0;
//    font-family: $font-body;
//    transition: all .15s ease;
//
//    &:hover{
//        background-color: darken($motum-blue, 10%);
//    }
//}
//

.splash-btn-container{
    display: flex;
    justify-content: center;

    margin-bottom: -10rem;
}



.splash-btn{
    font-size: 2.35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-family: $font-body;
    max-width: 30rem;
    text-align: left;
    background: transparent;
    border: 0;
    color: $motum-darkgrey;
    cursor: pointer;
    .is-animating &{
        pointer-events: none;
    }
    &:hover{
        .splash-btn__orb{
            background-color: #fff;
            transform: scale(1.1);
            // background-color: var(--color-secondary);
            &::before{
                transform: translateX(300%) translateY(-50%);
            }
            &::after{
                transform: translateX(-50%) translateY(-50%);
                //  color: #fff;
                //  color: var(--color-secondary);
            }
        }
        .splash-btn__text{
            color: #fff;
          //  transform: translateY(1rem);
        }
    }
}
.splash-btn__text{
    color: $motum-yellow;
    transition: all 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24);
    white-space: nowrap;
    .is-animating &{
        transform: translateX(-2rem);
        opacity: 0;
        transition-delay: .2s;
    }
    .is-animating.is-leaving{
        transition-delay: .2s;
    }
    strong{
        display: inline-block;
        position: relative;
        margin-left: .2rem;
        color: $motum-darkgrey;
        &::before{
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            bottom: -.3rem;
            left: 0;
            height: .3rem;
            background-color: $motum-darkgrey;
        }
    }
}
.splash-btn__orb{
    display: block;
    width: 8rem;
    height: 8rem;
    background-color: $motum-yellow;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    flex-shrink: 0;
    transition: all 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24);
    position: relative;
    overflow: hidden;
    z-index: 0;

    .is-animating &{
        transition-delay: .3s;
        transform: scale(0);
    }
    &::before, &::after{
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 3rem;
        transform: translate(-50%, -50%);
        transition: all 0.7s cubic-bezier(0.64, 0.39, 0.03, 1.24);
        .is-animating &{
            transform: translate(300%, -50%);
        }
        .is-animating.is-leaving &{
            transform: translate(-300%, -50%);
        }
    }
    &::before{
        content: '\e904';
        font-family: 'icoMoon';
      //  font-size: 4rem;
    }
    &::after{
        content: '\e904';
        font-family: 'icoMoon';
       // font-size: 3rem;
        transform: translateX(-300%) translateY(-50%);
    }

}




.splash__video{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity .5s ease-in-out;
    opacity: 0;
    &.visible{
        opacity: .25;
    }
    // @include tablet-below{
    //     display: none;
    // }
    
}



.splash__content{
    @include column(12);
    margin: 0 auto;
    float: none;
}
.splash__definition{
    color: #cdcec0;
    font-size: 2.5rem;
    padding:0 0 1.5rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
    overflow: hidden;
    padding-bottom: 0;
    > span{
        display: block;
        position: relative;
        top: 0;
        padding-bottom: 1rem;
        // transform: translateY(100%);
    }
    > span > span{
        display: inline-block;
        position: relative;
        transform: translateY(.1rem);

        &:first-child{
            margin-right: 1rem;
        }
        &:last-child{
            margin-left: 1rem;
        }

        // transform: translateY(100%);
    }
}

.splash__subtitle{
    color:#ffffff;
    padding:0 0 0;
    font-weight: 700;
    //margin-bottom: 3rem;
    display: block;
    @include responsive-font(5vw, 21px, 60px);

    > span > span, > span{
        line-height: 1.2;
        @include phone-large-below{
            line-height: 1.1;
        }
    }

    @include desktop-small-below{
        > span:nth-child(2){
            margin-top: -1.5rem;
        }
    }
    @include desktop-below{
        > span:nth-child(2){
            margin-top: -1.5rem;
        }
    }


    > span{
        display: block;
        position: relative;
        top: 0;
        // transform: translateY(100%);
    }

    //@include desktop-large-below{
    //    font-size: 6.5rem;
    //    line-height: 0rem;
    //}
    //
    //@include desktop-medium-below{
    //    font-size: 5.5rem;
    //    line-height: 0rem;
    //}

}



.splash__subtitle{
    //color: #e2db19;
    margin: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin-bottom: 4rem;
    @include responsive-font(4.8vw, 21px, 60px);
}






.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    cursor: default;
}

