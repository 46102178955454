.svg-pattern-container {
	position: absolute;
	opacity: .1;
	&.style-one {
		width: 136px;
		height: 260px;
		top: 20%;
	}

	&.filled {
		[class$='svg-pattern__shape'] {
			fill: var(--color-primary)

		}
	}

	&.stroke {
		[class$='svg-pattern__shape'] {
			fill: none;
			stroke-width: 2;

			.theme-yellow & {
				stroke: rgba($motum-yellow, .45);
			}
			.theme-green & {
				stroke: rgba($motum-green, .45);
			}
			.theme-purple & {
				stroke: rgba($motum-purple, .45);
			}
			.theme-red & {
				stroke: rgba($motum-red, .45);
			}
			.theme-blue & {
				stroke: rgba($motum-yellow, .45);
			}
		}
	}

	.subspecialty-row:nth-child(odd) &{
        left: -10rem;
    }
    .subspecialty-row:nth-child(even) &{
        right: -10rem;
    }
}

[class$='svg-pattern'] {
	background: yellow;
	width: 50px;
	height: 50px;
}

[class$='svg-pattern__shape'] {
	transition: opacity .5s ease-in-out;
	opacity: 0;

	.is-in-view &{
        opacity: 1;
    }

	// @for $i from 1 through 30 {
 //        &:nth-child(#{$i}) {
 //            transition-delay: #{$i * .05}s;
 //        }
 //    }
}