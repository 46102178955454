/* Product grid */

$motum-blue-new: #00bef0;

.our-work-cards{
    min-height: 70vh;
    //@include phone-large-below{
    //    padding: 3rem;
    //}
}

.grid {
    position: relative;
    // overflow: hidden;
    //max-width: 1300px;
    margin: 0 auto;
    // padding: 1.5em 0 8em;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    margin-left: -1rem;
    margin-right: -1rem;
    //background-color: red;
    //width: 100%;
}

/* Loader */
.grid__loader {
    //margin: 15rem auto 0;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 15rem;
    pointer-events: none;
    opacity: 0;
    transform: translateX(-50%) scale(0);

}


.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: .5rem solid #ebebeb;
    border-right: .5rem solid #ebebeb;
    border-bottom: .5rem solid #ebebeb;
    border-left: .5rem solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.grid--loading .grid__loader {
    display: block;
    opacity: 1;
    transform: translateX(-50%) scale(1);
}

/* Clearfix */

.grid:after {
    content: '';
    display: block;
    clear: both;
}

/* Grid items */

.grid__sizer,
.grid__item {
    position: relative;
    float: left;
    width: 25%;
}


.grid__item--size-a {
    width: 50%;

}

.no-touch .grid__sizer,
.no-touch .grid__item {
    //  padding: .75em .75em 1.25em;
}

.grid--loading .grid__item {
    visibility: hidden;
}




/* Resize grid items on smaller screens */

@include desktop-small-below {
    .grid__sizer,
    .grid__item
    {
       @include column(4);
    }
    .grid__item--size-a{
        @include column(8);
    }
}

@include desktop-below {
    .grid__sizer,
    .grid__item
    {
        @include column(4);
    }
    .grid__item--size-a{
        @include column(7.9);
    }
}

@include tablet-below {
    .grid__sizer,
    .grid__item
    {
        @include column(6);
    }
    .grid__item--size-a{
        @include column(12);
    }
}
@include phone-large-below {
    .grid__sizer,
    .grid__item
    {
        @include column(12);
    }
    .grid__item--size-a{
        @include column(12);
    }
}
//
//@media screen and (max-width: 50em) {
//    .grid__sizer,
//    .grid__item,
//    {
//        @include column(4);
//    }
//    .grid__item--size-a{
//        @include column(7.96);
//    }
//}
//
//
//@media screen and (max-width: 25em) {
//    .grid {
//        max-width: 75%;
//    }
//    .grid__loader {
//        margin: 0 auto;
//    }
//    .grid__sizer,
//    .grid__item,
//    .grid__item--size-a {
//        width: 100%;
//    }
//}




.work-filters-container {
    margin-bottom: 2rem;
}

.work-filters-container__title {
    color: $motum-darkgray;
    font-weight: 800;
    letter-spacing: .13rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    display: block;
    position: relative;
    &::after {
        display: block;
        content: '';
        width: 3rem;
        background-color: #dadada;
        height: 2px;
        bottom: 0;
        position: absolute;
        left: 0;
    }
}

.work-filters {
    display: flex;
    @include phone-large-below {
        flex-wrap: wrap;
    }
}

.work-filter {
    background: transparent;
    position: relative;
    display: block;
    border: 0;
    color: #8d8d8d;
    font-family: $font-body;
    font-weight: 700;
    padding: 0 0 .7rem 0;
    margin-right: 4rem;
    font-size: 2.1rem;
    transition: .25s ease-in-out;
    cursor: pointer;
    text-align: left;
    //&::after {
    //    position: absolute;
    //    bottom: 0;
    //    left: 0;
    //    content: '';
    //    background-color: $motum-blue-new;
    //    width: 100%;
    //    height: 3px;
    //    opacity: 0;
    //    transition: opacity .15s ease-in-out;
    //}
    @include tablet-below{
        font-size: 1.7rem;
    }
    @include phone-large-below {
        margin-bottom:2rem;
        font-size: 2.1rem;
    }


    &::before{
        content: "";
        display: block;
        position: absolute;
        right: 0;
        left: auto;
        bottom: 0;
        width: 0;
        height: 3px;
        background-color: $bright-purple;
        transition: all .45s cubic-bezier(.694,.048,.335,1);
        pointer-events: none;
        z-index: 2;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        right: 0;
        left: auto;
        bottom: 0;
        width: 0;
        height: 3px;
        background-color: $motum-purple;
        z-index: 1;
        transition: all .35s cubic-bezier(.694,.048,.335,1) .2s;
        pointer-events: none;
    }

    &.theme-yellow{
        &::before{
            background-color: $bright-yellow;
        }
        &::after{
            background-color:  lighten($bright-yellow, 10%);
        }
    }
    &.theme-green{
        &::before{
            background-color: $bright-green;
        }
        &::after{
            background-color:  lighten($bright-green, 10%);
        }
    }
    &.theme-purplem{
        &::before{
            background-color: $bright-purple;
        }
        &::after{
            background-color:  lighten($bright-purple, 10%);
        }
    }
    &.theme-red{
        &::before{
            background-color: $bright-red;
        }
        &::after{
            background-color:  lighten($bright-red, 10%);
        }
    }
    &.theme-blue{
        &::before{
            background-color: $bright-blue;
        }
        &::after{
            background-color: lighten($bright-blue, 10%);
        }
    }

    &:hover, &:focus, &.work-filter--selected {
        background: transparent;
        color: $motum-darkgray;
        outline: none;
        &::before{
            width: 100%;
            left: 0;
        }
        &::after{
            width: 100%;
            left: 0;
        }
    }
    &.active{

    }
    &.work-filter--selected {
        color: $motum-darkgray;
        &::after {
            opacity: 1;
        }
    }
}




.proof-preview__pre-title, .proof-preview__title {

    position: absolute;
    left: 0;
    top: 50%;
    line-height: 1;
    color: $motum-darkgrey;
    display: inline;
    white-space: pre-wrap;
    border: 0 solid #fff;
    border-width: 1rem 0;
    background: #fff;

    &::before {

        content: "";
        position: absolute;
        top: -1rem;
        right: 100%;
        bottom: -1rem;
        width: 1rem;
        background: #fff;
        border-left: 3px solid $motum-lightgrey;
    }

    &::after {

        content: "";
        opacity: 1;
        display: none;
        position: absolute;
        top: -1rem;
        // left: calc(100% + 1rem - 3px);
        left: -7px;
        bottom: -1rem;
        width: 0;
        background: rgba($motum-yellow, 0.5);
        animation-duration: 0.65s;
    }

    @keyframes titleSlide {

        0% {
            width: calc(100% + 7px);
            left: -7px;
            opacity: 0;
        }

        25% {

            opacity: 1;
        }

        // 50% {

        //   width: 0;
        // }

        100% {
            width: 0;
            left: 100%;
        }
    }

}

// .proof-preview__title {

//   &::after {

//     animation-delay: 0.1s;
//   }
// }

.proof-preview__pre-title span, .proof-preview__title span {

    position: relative;
    z-index: 1;
    padding-right: 1rem;
    transition: all 0.5s ease-in-out;
}

.proof-preview__pre-title span {

    @extend %heading-four;
    color: rgba($motum-grey, 0.75);
}

.proof-preview__title span {

    font-size: 4rem;

}

.our-work-secondary-row{
    background: #f0f0f0;
    padding: 7rem 0;
    margin: 5rem 0 0 0;
    .wrapper{
        display: flex;
        flex-wrap: wrap;
    }
}


.our-work-secondary-row__title{
    @extend .decorative-title;

    &::before {
        background-color: $bright-red;
    }
}

.our-work-clients{
    @include column(7);
    img{
        margin-top: 5rem;
    }

    .our-work-secondary-row__title {
        margin-bottom: 5rem;
    }

    @include desktop-below {
        @include column(6);
    }

    @include tablet-below {
        @include column(5.5);
        padding-right: 2rem;
    }

    @include tablet-small-below {
        @include column(12);
        padding-right: 0;
        margin-bottom: 5rem;
    }
}
.our-work-quotes{
    @include column(5);

    .our-work-secondary-row__title {
        margin-bottom: 5rem;
    }

    @include desktop-below {
        @include column(6);

        .quote {
            padding-right: 0;
        }
    }

    @include tablet-below {
        @include column(6.5);
    }

    @include tablet-small-below {
        @include column(12);
    }
}

.our-work-secondary-row--home, .our-work-secondary-row--specialtiesIndex {
    margin-top: 0;
}