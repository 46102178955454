// related entries
.related-entries {
    margin: 3rem 0;
    padding-top: 3rem;
    border-top: solid 1px $motum-lightgrey;

    .small-title {
        padding-bottom: 1.5rem;
        color: $motum-darkgrey;
        letter-spacing: 2.5px;
        font-weight: 900;
    }
}

.related-entries__content {
    width: 100%;
    margin: 0 -1rem;
    display: flex;
    // justify-content: space-between;

    a {
        opacity: 0;
        transform: translateY(4rem);
        transition: 0.5s cubic-bezier(0.64, 0.39, 0.03, 1.05) 0.1s;

        .is-visible & {
            opacity: 1;
            transform: translateY(0);
        }

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                transition-delay: #{$i * .1}s;
            }
        }
    }

    @include desktop-below {
        flex-wrap: wrap;

        a:nth-child(3) {
            display: none;
        }
    }

    @include tablet-below {
        a:nth-child(3) {
            display: block;
        }
    }
}