.specialties-index-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10rem;
}


.specialties-index-cards{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
}


.specialties-index-card{
   // border: 4px solid $motum-darkgray;

    position: relative;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;

    &.is-active{
        z-index: 999;
    }



    &:not(.specialties-index-card--small){
        @include column(2.9);
        @include tablet-below{
            @include column(5.9);
            margin-bottom: 2rem;
        }
        @include phone-large-below{
            @include column(12);
            text-align: center;
        }
    }
    &--small{
        margin-top: 1rem;
    }


    &:hover{
        .specialties-index-card__bgcircle{
            width: 175%;
            //animation: specialtiesIndexCardBgGrow .3s ease-in-out 1 forwards normal;
           // height: 160%;
        }
    }
    &.theme-blue{
      > div::after{
          box-shadow: 0 20px 40px -10px $motum-blue, 0 50px 80px 0 $motum-blue;
      }
    }
    &.theme-yellow{
        > div::after{
          box-shadow: 0 20px 40px -10px $motum-yellow, 0 50px 80px 0 $motum-yellow;
      }
    }
    &.theme-green{
        > div::after{
          box-shadow: 0 20px 40px -10px $motum-green, 0 50px 80px 0 $motum-green;
      }
    }
    &.theme-red{
        > div::after{
          box-shadow: 0 20px 40px -10px $motum-red, 0 50px 80px 0 $motum-red;
      }
    }

    > div{
        display: flex;
    }



    div.specialties-index-card__orb-wrapper{
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &::after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 0px 4px $motum-darkgray;
            z-index: 0;
            @include desktop-small-below{
                box-shadow: inset 0px 0px 0px 3px $motum-darkgray;
            }
        }
        &::before{
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            width: 80%;
            height: 20px;
            background-color: #fff;
            display: block;
            transition: width .3s ease-in-out;
            transform: translateX(-50%);
            z-index: 1;
            @include tablet-below{
                display: none;
            }
        }
    }
  > div::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0 20px 40px -10px rgba(0,0,0,.6), 0 50px 80px 0 rgba(0,0,0,.3);
      z-index: -1;
      //transform: translateZ(-50px);
      transition: opacity .5s ease-in-out;
      opacity: 0;
  }
    &:hover{
        > div::after{
            opacity: .4;
        }
        div.specialties-index-card__orb-wrapper::before{
            width: 0;
        }
        .specialties-index-card__orb{
            width: 80%;
            padding-bottom: 80%;
        }
        .specialties-index-card__illustration svg{
            transform: scale(.9);
        }

        &.is-active{
            .specialties-index-card__orb{
                width: 0;
                padding-bottom: 0;
            }
        }
    }

}

.specialties-index-card__inner{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
    padding: 4rem;
    transition: all .5s ease-in-out;

    .is-active &{
        opacity: 0;
        transform: translateY(-2rem);
    }
}

@keyframes specialtiesIndexCardBgGrow{
    0%{
        width: .1vw;
    }
    100%{
        width: 175%;
    }
}

.specialties-index-card__bgcircle{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease-in-out;
    width: .01vw;
    height: auto;
    z-index: 2;

   circle{
       .theme-blue &{
           fill: $motum-blue;
       }
       .theme-yellow &{
           fill: $motum-yellow;
       }
       .theme-green &{
           fill: $motum-green;
       }
       .theme-red &{
           fill: $motum-red;
       }
   }
}

.specialties-index-card__illustration{
    margin-top: -10rem;
    //background-color: #fff;
   // transform: translateZ(20px);
    position: relative;


    @include tablet-below{
        margin-top: 0rem;
    }
    svg{
        z-index: 1;
        position: relative;
        transform: scale(.8);
        transition: transform .7s cubic-bezier(.64,.39,.03,1.24);
        width: 100%;
    }
}

.specialties-index-card__title{
    @include responsive-font(3vw, 25px, 60px);
    line-height: 1;
    margin-bottom: 3.5rem;

    transition: transform .5s ease-in-out, opacity .5s ease-in-out;

    @include phone-large-below{
        margin-bottom: 1.5rem;
    }

    .specialties-index-card--small &{
        margin-bottom: 0;
    }
    .is-active &{
        opacity: 0;
        transform: translateY(-2rem);
    }
}

.specialties-index-card__orb{
    display: block;
    width: 0;
    padding-bottom: 0;
    background-color: $motum-purple;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: all .5s cubic-bezier(.64,.39,.03,1.24) .1s;
    .theme-yellow &{
        // background-color: $motum-green;
        background-color: $motum-purple;
    }
    .theme-green &{
        background-color: $motum-purple;
    }
    .theme-red &{
        background-color: $motum-blue;
    }
    .theme-blue &{
        background-color: $motum-yellow;
    }

}



.specialties-index-card__subtitle{
    @extend %heading-four;

    display: block;
    @include responsive-font(2vw, 14px, 25px);

    transition: opacity .5s ease-in-out .2s, transform .5s ease-in-out .2s;
    .is-active &{
        opacity: 0;
        transform: translateY(-2rem);
    }
}




.specialties-menu-transit-circle-next{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    z-index: 0;

    transition: all .9s ease-in-out;

    width: .1vw;
    height: .1vh;
    .is-animating &{
        width: 150vw;
        height: 150vw;
    }
    //
    //.is-animating.to-specialties{
    //    opacity: 0;
    //}


    .is-animating:not(.is-leaving){
      //  transition-duration: 0s;
    }

    circle{
        fill: #fff;
    }



}