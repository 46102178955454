// Footer

footer{
    width:100%;
    position: relative;
    padding: 7rem 0;
    background-color: $motum-darkgrey;
    p {
        color: #fff;
        letter-spacing: 0.5px;
    }

}



.footer{
    max-width:1496px;
    width:100%;
    display:block;
    margin:0 auto;
    position: relative;
    overflow:hidden;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.footer__left{
    @include column(5);
    display: flex;
    flex-direction: column;
    position: relative;
    @include desktop-below{
        @include column(7);
    }
    @include tablet-below{
        @include column(9);
        .navigation{
            width:80%!important;
        }
    }
    @include phone-large-below{
        @include column(12);
        padding-bottom: 4rem;
        margin-bottom: 3rem;
    }
    .footer__navigation {
      //  margin-bottom: 5rem;
    }

}
.footer__careers {
    margin-top: 1rem;
    margin-bottom: 4rem;
    a {
        //text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        //border: solid 3px $motum-hyperlink-purple;
        padding: 1rem 2rem;
        transition: 0.75s ease all;
        //box-shadow: inset 0 0 0 0 $motum-yellow;
        background: lighten($motum-grey, 52%);
        color: $motum-darkgrey;
        overflow: hidden;
        position: relative;
        z-index: 10;
        display: inline-block;
        span {
            z-index: 11;
        }
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            // background-color: $motum-yellow;
            background: $motum-purple;
            position: absolute;
            top: 0;
            right: calc(100% + 3px);
            transition: 0.35s ease all;
            z-index: -1;
            // border-right: 100px solid $bright-purple;
        }
        &:hover, &:active, &:focus {
            &:before {
                right: 0;
                // border-right: 5px solid $bright-purple;
            }
        }
        @include phone-large-below {
            padding: 0.5rem 1.5rem;
        }
    }
}
.footer__center{
    display: none;
    //background-color: lightgreen;
    @include column(6);
    min-height:1px;
    @include desktop-below{
        @include column(2);
    }
    @include tablet-below{
        @include column(0);
    }
}
.footer__right{
    @include column(2);
    //background-color: lightpink;
    @include tablet-below{
        @include column(3);
    }
    @include phone-large-below{
        @include column(6);   

    }
    .footer__logo{
        float:left;
        width: 100%;
        svg{
            max-width:100%;
            width:100%;
            height: 40px;
        }
        path {
            // fill: $motum-lightgrey;
            fill: #fff;
        }
    }
    .footer__address{
        margin:1.5rem 0;
        display:block;
        float:left;
        clear:both;
        p{
            font-size:1.4rem;
            margin-bottom:0.6rem;
            font-weight: 500;
        }
        a{
            font-size:2rem;
            font-weight: 700;
            color:#fff;
            opacity: 1;
            transition: opacity .5s ease;
            position:relative;
            display:block;
            padding-top:1rem;
            &::before{
                position: absolute;
                width: 100%;
                height: 1.5px;
                content: '';
                display: block;
                background-color:#fff;
                top: 0;
                left: 0;
                transform: translateY(-50%);
            }
            &:hover {  
                color: $motum-red;
            }
        }

    }
}


.footer__social{
    margin-right: 2rem;
    display: flex;
    align-items: center;
    a{
        color: #fff;
        font-size: 1.6rem;
        opacity: 1;
        transition: opacity .15s ease;
        display: inline-block;
        margin-left: 1.5rem;
        // &:last-child, &:nth-child(2){
        //     margin-left: 1.5rem;
        // }
        &:hover{
            color: $motum-red;
        }
        // .page-index-index &{
        //     color: #fff;
        // }
    }
}
.footer__stay-informed {
    margin-bottom: 4rem;
    p {
        font-weight: 600;
        font-size: 1.5rem;
        //text-transform: uppercase;
        padding-right: 2rem;
        @include tablet-small-below {
            //padding-bottom: 0.5rem;
            margin-bottom: 0;
        }
    }

}




.footer__ancillary{
    display: flex;
    align-items: center;

}

.footer-ancillary-nav{
    border-left: 2px solid #ccc;
    padding-left: 2rem;
    ul{
        display: flex;
    }

    ul li{
        margin-right: 2rem;
        margin-bottom: 0;
    }

    ul li a{
        color: #cccccc;
        font-size: 1.6rem;
        text-decoration: underline;

        &:hover{
            color: #fff;
        }
    }
}