
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;0,900;1,500;1,700&display=swap');

// ===== Typography =====


.typography-styles{
	//> * + *{ margin-top: 1.5rem;}
	//> img + *, > ul + *{ margin-top: 3rem;}
	//> * + h2,  > * + h3 { margin-top: 4rem;}
	//> h2 + *, > h3 + *{ margin-top: 2rem;}
	//> p + ul{margin-top: 4rem;}
	//> * + .inline-cta{
	//	margin-top: 4.5rem
	//}
	//> p.large + p{
	//	margin-top: 3rem;
	//}
    //
	//p.placeholder{
	//	color: red;
	//}
    //
	//> ul{
	//	@extend .list-styles;
	//}
    //
    //
	//hr{
	//	//@include column(12);
	//	border: 0;
	//	height: 1px;
	//	background-color: #e6e6e6;
	//	margin: 4rem 0;
	//	display: block;
	//}
}


em {
	font-style: italic;
}

a {
    color: $motum-darkgrey;
}




%text-base{


    //&:is(h2) + p{
    //  margin-top: 2rem;
    //}



	&.darkgray{
		color: #353535;
	}
	&.thin{
		font-weight: 500;
	}
  &.large{
    font-size: 2.5rem;
    font-weight: 300;
  }
	&.uppercase{
		text-transform: uppercase;
	}

}
.text-title{
	& + p{
		// margin-top: -10px;
	}
}


%heading-xlarge{
  @extend %text-base;
  font-size: 9.5rem;
  font-weight: 700;
  // font-weight: 700;
  //overflow: hidden;
  position: relative;
  line-height: 1.05;

  @include desktop-large-below{
    font-size: 9.5rem;
  }
  @include tablet-below{
    line-height: 1.1;
    font-size: 8.5rem;
  }
}

%heading-one{
	@extend %text-base;
	font-size: 8.8rem;
	font-weight: 700;
	// font-weight: 700;
	//overflow: hidden;
	position: relative;
	line-height: 1.05;

	@include desktop-large-below{
		font-size: 9.5rem;
	}
	@include tablet-below{
		line-height: 1.1;
		font-size: 8.5rem;
	}
}

%heading-two{
	@extend %text-base;
	font-size: 5rem;
	font-weight: 700;
    margin-bottom: 2rem;
	//overflow: hidden;
	position: relative;
	line-height: 1;
	@include desktop-large-below{
		//font-size: 5rem;
	}
	@include tablet-below{
		line-height: 1.1;
		font-size: 6rem;
	}
}


%heading-pageheader{
	@extend %text-base;
	font-size: 2.6rem;
	font-weight: 600;
	line-height: 1.3;
  margin-bottom: 2rem;
	font-family: $font-header;

}


%heading-three{
  @extend %text-base;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 2rem;
  font-family: $font-header;

}


%heading-four{
	@extend %text-base;
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.1;
	font-family: $font-header;
}


%heading-five{
	@extend %text-base;
	font-size: 1.8rem;
	font-weight: 600;
	line-height: 1.1;
	font-family: $font-header;
}



%body-copy{
  @extend %text-base;
  //font-size: 1.8rem;
  line-height: 1.5;

  font-weight: 500;
  font-size: 2rem;

  margin-bottom: 2rem;
  font-family: $font-body;

  //@include desktop-medium-below{
  //  font-size: 2rem;
  //}
  //
  //@include desktop-medium-below{
  //  font-size: 1.8rem;
  //}
  //
  //@include tablet-below {
  //  font-size:2rem;
  //}
  //
  //@include phone-large-below {
  //  font-size:2rem;
  //}
}

.small-title {
  @extend %heading-three;

  .theme-yellow & {
    // color: $motum-green;
    color: $bright-purple;
  }
  .theme-green & {
    color: $bright-purple;
  }
  .theme-purple & {
    color: $bright-purple;
  }
  .theme-red & {
    color: $bright-blue;
  }
  .theme-blue & {
    color: $bright-blue;
  }
}



p, li{
  @extend %body-copy;
  @include phone-large-below{
    font-size: 2.2rem;
  }
}



h1 {
  @extend %heading-one;
}

h2 {
  @extend %heading-two;

}

h3 {
  @extend %heading-three;
}

h4 {
  @extend %heading-four;
}

h5 {
  @extend %heading-five;
}




.decorative-title {
	font-size: 2.2rem;
    font-weight: 600;
    letter-spacing: .15rem;
    text-transform: uppercase;
    &::before {
        content: '';
        display: block;
        width: 3rem;
        background-color: $motum-grey;
        margin-bottom: 1rem;
        height: 3px;
    }

    .theme-yellow & {
        // color: $motum-green;
        color: $bright-purple;
        &::before {
        	// background-color: $motum-green;
        	background-color: $bright-purple;
        }
    }
    .theme-green & {
        color: $bright-purple;
        &::before {
        	background-color: $bright-purple;
        }
    }
    .theme-purple & {
        color: $bright-purple;
        &::before {
        	background-color: $bright-purple;
        }
    }
    .theme-red & {
        color: $bright-blue;
        &::before {
        	background-color: $bright-blue;
        }
    }
    .theme-blue & {
        color: $bright-blue;
        &::before {
        	background-color: $bright-blue;
        }
    }
}



.post-content a, .fancy-link, p a {
        // white-space: nowrap;
        position: relative;
        display: inline;
      //  // overflow-x: hidden;
        margin-bottom: -7px;
        padding-bottom: 3px;
      //   transition: 1s ease all;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 1.5;
        color: var(--color-black);
        // word-break: break-all;
        text-decoration: none;
        background-size: 100% 3px, 0 3px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        // background-size: 100% 4px;
        transition: background-image .5s, background-size .5s;
        .theme-yellow & {
          // text-decoration-color: $bright-yellow;
          background-image: linear-gradient($bright-yellow, $bright-yellow), linear-gradient($motum-darkgrey, $motum-darkgrey);
        }
        .theme-green & {
          // text-decoration-color: $motum-green;
          background-image: linear-gradient($motum-green, $motum-green), linear-gradient($motum-darkgrey, $motum-darkgrey);
        }
        .theme-purple & {
          // text-decoration-color: $bright-purple;
          background-image: linear-gradient($bright-purple, $bright-purple), linear-gradient($motum-darkgrey, $motum-darkgrey);
        }
        .theme-red &, .red-theme & {
          // text-decoration-color: $bright-blue;
          background-image: linear-gradient($bright-blue, $bright-blue), linear-gradient($motum-darkgrey, $motum-darkgrey);
        }
        .theme-blue & {
          // text-decoration-color: $bright-yellow;
          background-image: linear-gradient($bright-yellow, $bright-yellow), linear-gradient($motum-darkgrey, $motum-darkgrey);
        }

        &:hover {
          background-size: 0 3px, 100% 3px;
        }
        //color: $motum-darkgrey;
        // .theme-yellow & {
        //     color: $bright-yellow;
        // }
        // .theme-green & {
        //     color: $motum-green;
        // }
        // .theme-purple & {
        //     color: $bright-purple;
        // }
        // .theme-red & {
        //     color: $motum-red;
        // }
        // .theme-blue & {
        //     color: $bright-blue;
        // }

        // &::before, &::after {
        //     content: '';
        //     display: block;
        //     height: .3rem;
        //     position: absolute;
        //     bottom: 0;
        //     transition: all .5s cubic-bezier(.53,-.01,0,1);
        // }

        // &::before {
        //     width: 100%;
        //     right: 0;
        //     z-index: -1;

        //     .theme-yellow & {
        //         background: $bright-yellow;
        //     }
        //     .theme-green & {
        //         background: $motum-green;
        //     }
        //     .theme-purple & {
        //         background: $bright-purple;
        //     }
        //     .theme-red &, .red-theme & {
        //         background: $bright-blue;
        //     }
        //     .theme-blue & {
        //         background: $bright-yellow;
        //     }

        //     .promoted-post & {
        //       z-index:0;
        //     }
        // }

        &::after {
            width: 0%;
            right: 0;
            background: $motum-darkgrey;
           // border-right: solid 300px;
            //left: -300px;
            z-index: -1;


            .promoted-post & {
              z-index:0;
              background: #fff;
            }
        }

        &:hover {
            // color: $motum-darkgrey;
            &::before {
              //  width: 0;
                // height: 20%;
            }
            &::after {
                left: 0;
                width: 100%;
               // border-right: solid 50px;
                // height: 20%;

                .theme-yellow & {
                    border-color: $motum-purple;
                }
                .theme-green & {
                    border-color: $motum-purple;
                }
                .theme-purple & {
                    border-color: $motum-grey;
                }
                .theme-red & {
                    border-color: $bright-blue;
                }
                .theme-blue & {
                    border-color: $bright-yellow;
                }
            }
        }
    }

    .post-content em a {
      padding-right: 2px;
    }
