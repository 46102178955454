.page-header.project {
	@include phone-large-below {
		padding: 14rem 0 2rem;
	}
}

.project-header__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;
}

.project-header__floating-shapes {
	width: 55%;
	height: 35rem;
	position: absolute;
	top: 0;
	left: -3rem;
	z-index: -1;
	display: none;

	//opacity: 1;
	transition: opacity .2s ease-in-out;
	//.is-animating &{
	//	opacity: 0;
	//}

	span {
		position: absolute;
		transition: all .5s cubic-bezier(.53,-.01,0,1);
		opacity: 0.8;
		//transform: scale(0);
		
		&:first-child {
			width: 6.5rem;
			height: 6.5rem;
			top: 49%;
			left: 0;
			transform: scale(1) rotate(-45deg);
			//transition-delay: 1s;

		}
		&:nth-child(2) {
			width: 3rem;
			height: 3rem;
			top: 88%;
			left: 15%;
			transform: scale(1) rotate(180deg);
			//transition-delay: .3s;
			transition-delay: .2s;
		}
		&:nth-child(3) {
			width: 5.5rem;
			height: 5.5rem;
			top: 79%;
			left: 21%;
			transform: scale(1) rotate(90deg);
			transition-delay: .4s;
			//transition-delay: .6s;
		}
		&:nth-child(4) {
			width: 23rem;
			height: 23rem;
			top: 7%;
			left: 42%;
			transform: scale(1) rotate(-25deg);
			opacity: 0.25;
			transition-delay: .6s;
			//transition-delay: .9s;
		}
		&:nth-child(5) {
			width: 8rem;
			height: 8rem;
			top: 64%;
			right: 10%;
			transform: scale(1) rotate(0deg);
			//transition-delay: 1.2s;
			transition-delay: .8s;
		}
		&:last-child {
			width: 2.5rem;
			height: 2.5rem;
			top: 20%;
			right: 0;
			transform: scale(1) rotate(25deg);
			//transition-delay: 1.5s;
			transition-delay: 1s;
		}

        .is-animating &{
        	opacity: 0;

			&:first-child {
				transform: rotate(-30deg);
			}

			&:nth-child(2) {
				transform: rotate(160deg);
			}

			&:nth-child(3) {
				transform: rotate(70deg);
			}

			&:nth-child(4) {
				transform: rotate(-20deg);
			}

			&:nth-child(5) {
				transform: rotate(10deg);
			}

			&:last-child {
				transform: rotate(5deg);
			}
        }
	}

	svg {
		width: 100%;
	}

	@include desktop-small-below {
		@include column(11);
	}

	@include tablet-below {
		@include column(12);
	}
}

[id*='design-gradient-1'] {
	.theme-yellow & {
		stop:first-child {
			stop-color: $bright-yellow;
		}
		stop:last-child {
			// stop-color: $motum-green;
			stop-color: $motum-purple;
		}
	}

	.theme-green & {
		stop:first-child {
			stop-color: $motum-green;
		}
		stop:last-child {
			stop-color: $motum-purple;
		}
	}

	.theme-purple & {
		stop:first-child {
			stop-color: $motum-purple;
		}
		stop:last-child {
			stop-color: $motum-lightgrey;
		}
	}

	.theme-red & {
		stop:first-child {
			stop-color: $motum-red;
		}
		stop:last-child {
			stop-color: $bright-blue;
		}
	}

	.theme-blue & {
		stop:first-child {
			stop-color: $motum-blue;
		}
		stop:last-child {
			stop-color: $motum-yellow;
		}
	}
}

[id*='design-gradient-2'] {
	.theme-yellow & {
		stop {
			stop-color: $motum-yellow;
		}
	}

	.theme-green & {
		stop {
			stop-color: $motum-green;
		}
	}

	.theme-purple & {
		stop {
			stop-color: $motum-purple;
		}
	}

	.theme-red & {
		stop {
			stop-color: $motum-red;
		}
	}

	.theme-blue & {
		stop {
			stop-color: $motum-blue;
		}
	}
}

.project-nav {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	// margin-top: 5rem;
	margin-top: 2rem;
	margin-bottom: 4rem;

	.project-nav__btn-row {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		a:last-child {
			margin-left: 3rem;
		}
	}

	@include tablet-below {
		display: block;

		.project-nav__btn-row {
			margin-top: 2rem;
		}
	}

	@include phone-large-below {
		display: flex; 
		align-items:center;	

		.project-nav__btn-row {
			display: block;
			margin-top: 0;
			max-width:60%;

			a:last-child {
				margin-left: 0;
			}
		}
	}

	@include phone {
		display: block;

		.project-nav__bnt-row {
			margin-top: 2rem;
		}
	}
}

.project-nav__btn, .project-prev__btn, .project-next__btn  {
	color: $motum-darkgrey;
	font-family: $font-body;
	font-size: 1.6rem;
	font-weight: 500;
	transition: 0.35s ease all;

	@include phone {
		width: 100%;
		display: block;
	}
}

.project-next__btn {
	&:hover, &:focus {
	
		.project-nav__icon {
			transform: translateX(1rem);
		}

		.project-nav__title::after {
			width: 100%;
		}
	}

	.project-nav__title::after, .project-nav__title::before {
		left: 0;
	}

	.project-nav__icon {
		margin-left: 1rem;
	}

	@include phone {
		margin-top: 2rem;
	}
}

.project-nav__btn, .project-prev__btn {
	&:hover, &:focus {
	
		.project-nav__icon {
			transform: translateX(-1rem);
		}

		.project-nav__title::after {
			width: 100%;
		}
	}

	.project-nav__title::after, .project-nav__title::before {
		right: 0;
	}

	.project-nav__icon {
		margin-right: 1rem;
	}

	.project-nav__span {
		text-align: right;
	}
}

.project-prev__btn {
	@include tablet-below {
		.project-nav__icon {
			display: none;
		}
		.project-nav__span {
			text-align: left;
		}
	}

	@include phone-large-below {
		display: none;
	}
}

.project-nav__span {
	display: block;
	text-transform: uppercase;
	color: #969696;
	padding-bottom: 2px;
	font-size: 1.3rem;
	transition: 0.35s ease all;
}

.project-nav__title {
	position: relative;
	padding-bottom: 2px;
	@include phone-large-below {
		font-size:2.2rem;
	}

	&::after, &::before {
		content: '';
		display: block;
		height: 2px;
		position: absolute;
		bottom: 0;
	}

	&::before {
		width: 100%;
		background: $motum-lightgrey;
	}

	&::after {
		width: 0%;
		transition: 0.25s ease all;

		.theme-yellow & {
            // background-color: $motum-green;
            background-color: $motum-purple;
        }
        .theme-green & {
            background-color: $motum-green;
        }
        .theme-purple & {
            background-color: $motum-purple;
        }
        .theme-red & {
            background-color: $motum-red;
        }
        .theme-blue & {
            background-color: $bright-yellow;
        }
	}
}

.project-nav__icon {
	transition: 0.2s ease all;
	transition-delay: 0.2s;
	display: inline-block;

	.theme-yellow & {
		// color: $motum-green;
		color: $motum-purple;
	}
	.theme-green & {
		color: $motum-green;
	}
	.theme-purple & {
		color: $motum-purple;
	}
	.theme-red & {
		color: $motum-red;
	}
	.theme-blue & {
		color: $bright-yellow;
	}
}

.project-header__tablet-title {
	display: none;
	visibility: hidden;
	width: 100%;
	transform: translateX(0);
    transition: 0.5s ease-in-out 0.1s;
    opacity: 0;

	h1 {
		margin: 0;
		line-height: 1;
		font-weight: 900;
		overflow: visible;
		font-size: 8.5rem;

		@media (max-width: 400px) {
			font-size: 7.5rem;
			margin: 0 0 2rem;
		}

		@include phone {
			font-size: 6rem;
			margin: 0 0 1rem;
		}

	}

	.page-header__subtitle {
		width: 100%;
		margin: 0 auto;
		
	}

	@include desktop-small-below {
		display: block;
		visibility: visible;
		opacity: 1;
	}

	@include desktop-below {
		h1 {
			text-align: center;
		}

		.page-header__subtitle {
			text-align: center;
			@include column(11);
		}
	}
}

.project-header__desktop-title {
	@include desktop-small-below {
		display: none;
		visibility: hidden;
	}
}

.project-header__content {
	@include column(6.75);
	padding-right: 2rem;
	transform: translateX(0);
    transition: 0.5s ease-in-out 0.1s;
    opacity: 1;

    &.full {
    	@include column(12);
    }

    &.third {
    	@include column(4);
    }

    &.two-third {
    	@include column(8);
    }

    &.center {
    	display: flex;
    	flex-wrap: wrap;
    	align-content: center;
    }

	h1 {
		margin: 0;
		line-height: 1;
		font-weight: 900;
		overflow: visible;
		font-size: 8.25rem;
	}

	p {
		font-size: 1.75rem;
		font-weight: 500;
	}

	strong {
		display: inline-block;
		position: relative;
		font-weight: 800;
		margin-right: .5ch;
		&::before {
			content: '';
			position: absolute;
			display: block;
			height: 0.2rem;
			bottom: 1px;
			
			//margin-bottom: 2rem;
			width: 100%;

			.theme-yellow & {
				// background: $motum-green;
				background: $bright-purple;
			}
			.theme-green & {
				background: $motum-green;
			}
			.theme-purple & {
				background: $bright-purple;
			}
			.theme-red & {
				background: $motum-red;
			}
			.theme-blue & {
				background: $bright-yellow;
			}
		}
	}

	.page-header__subtitle {
		font-size: 3rem;
		margin-bottom: 0;
		// font-size: 2.8rem;
	}

	@include desktop-medium-below {
		@include column(6.25);
	}

	@include desktop-small-below {
		@include column(6);
	}

	@include desktop-below {
		@include column(12);
		order: 2;
		padding-right: 0;

		p {
			font-size: 1.9rem;
		}
	}
	@include phone-large-below {
		p {
			font-size: 2.4rem;
		}
	}
}

.project-header__tags {
	padding-top: 1rem;
	padding-bottom: 2rem;
	font-size: 2rem;
	font-weight: 600;
	display: flex;

	p:first-child {
		padding-right: 2rem;
		margin-right: 2rem;
		min-width: auto;
		border-right: solid 1px $motum-lightgrey;
	}

	span {
		text-transform: uppercase;
		letter-spacing: 1px;
		color: #969696;
		font-size: 1.5rem;
		font-weight: 700;
		display: block;
		line-height: 1;
	}

	@include desktop-below {
		padding-bottom: 0;
		justify-content: center;

		p {
			margin-bottom: 0;
			text-align: center;
		}

		span {
			text-align: center;
		}
	}

	@include phone-large-below {
		display: block;

		p:first-child {
			padding-right: 0;
			margin-right: 0;
			border-right: 0;
		}
	}
}

.project-intro-copy {
	@include desktop-below {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.project-ask {
	ul {
		margin-bottom: 4rem;
    	break-inside: avoid;
		li {
			font-size: 1.75rem;
			position: relative;
    		padding-left: 3rem;
			&::after {
				content: '';
				display: block;
				width: 2.2rem;
				height: 3px;
				position: absolute;
				top: 12px;
				left: 0;
				z-index: -1;

				.theme-yellow & {
					background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
				}
				.theme-green & {
					background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
				}
				.theme-purple & {
					background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
				}
				.theme-red & {
					background: linear-gradient(90deg, rgba($motum-blue,1) 0%, rgba($motum-blue,0) 100%);
				}
				.theme-blue & {
					background: linear-gradient(90deg, rgba($motum-yellow,1) 0%, rgba($motum-yellow,0) 100%);
				}
			}
			@include desktop-below {
				font-size: 1.9rem;
			}
			@include phone-large-below {
				font-size: 2.4rem;
			}	
			> ul{
				margin-top: 1rem;
				margin-bottom: 2rem;
			}
			> ul > li{
				margin-bottom: .5rem;
			}
		}
	}
	@include desktop-below {
		@include column(4.5);
	}

	@media (max-width: 900px){
		@include column(12);
	}
}

.project-solution {
	p {
		// font-weight: 600;
	}
	ul {
		margin-bottom: 4rem;
    	break-inside: avoid;
		li {
			font-size: 1.75rem;
			position: relative;
    		padding-left: 3rem;
			margin-bottom: 2rem;
			&::after {
				content: '';
				display: block;
				width: 2.2rem;
				height: 3px;
				position: absolute;
				top: 12px;
				left: 0;
				z-index: -1;

				.theme-yellow & {
					background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
				}
				.theme-green & {
					background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
				}
				.theme-purple & {
					background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
				}
				.theme-red & {
					background: linear-gradient(90deg, rgba($motum-blue,1) 0%, rgba($motum-blue,0) 100%);
				}
				.theme-blue & {
					background: linear-gradient(90deg, rgba($motum-yellow,1) 0%, rgba($motum-yellow,0) 100%);
				}
			}
			@include desktop-below {
				font-size: 1.9rem;
			}
			@include phone-large-below {
				font-size: 2.4rem;
			}	
			> ul{
				margin-top: 1rem;
				margin-bottom: 2rem;
			}
			> ul > li{
				margin-bottom: .5rem;
			}
		}
	}
	@include desktop-below {
		@include column(7);
	}

	@media (max-width: 900px){
		@include column(12);
	}
}

.project-header__visual {
	@include column(5.25);
	display: flex;
	flex-wrap: wrap;
	transform: translateX(0);
    transition: 0.5s ease-in-out 0.1s;
    opacity: 1;
    transition-delay: 0.15s;
    align-content: center;

	.single-opening-img {
		align-self: center;
	}

	.large {
		width: 150%;

		@include desktop-large-below {
			width: 135%;
		}
		@include desktop-small-below {
			width: 125%;
		}
		@include desktop-below {
			width: 100%;
		}
	}

	.medium {
		width: 135%;

		@include desktop-large-below {
			width: 120%;
		}
		@include desktop-small-below {
			width: 115%;
		}
		@include desktop-below {
			width: 100%;
		}
	}

	.small {
		width: 120%;

		@include desktop-large-below {
			width: 115%;
		}
		@include desktop-small-below {
			width: 105%;
		}
		@include desktop-below {
			width: 100%;
		}
	}

	.fixed {
		width: 100%;
	}

	.is-visible & {
		// opacity: 1;
		// transform: translateX(0);
	}

	@include desktop-medium-below {
		@include column(5.75);
	}

	@include desktop-small-below {
		@include column(6);
	}

	@include desktop-below {
		@include column(9);
		margin: 0 auto;
		order: 1;
		min-height: 530px;
	}

	@include tablet-below {
		@include column(12);
	}

	@include tablet-small-below {
		min-height: 400px;
	}
	
	@include phone {
		min-height: 300px;
	}
}

// .project-head__img {
// 	border: solid 10px blue;
// }

.project-header__animation {

	height: 100%;

	&.large {
		width: 150%;

		@include desktop-large-below {
			width: 135%;
		}
		@include desktop-small-below {
			width: 125%;
		}
	}

	&.medium {
		width: 135%;

		@include desktop-large-below {
			width: 120%;
		}
		@include desktop-small-below {
			width: 115%;
		}
	}

	&.small {
		width: 120%;

		@include desktop-large-below {
			width: 115%;
		}
		@include desktop-small-below {
			width: 105%;
		}
	}

	&.fixed {
		width: 100%;
	}
}
.project-header__video-wrapper{
	@include column(11);
	@include push(1);
}

.project-header__img-gallery, .project-header__video-gallery {

	position: relative;
	margin-left: 2rem;
	width:100%;
	height:100%;

	.image-slideshow {
		display: flex;
		align-content: center;
		height: 100%;

		.cycle-slide {
			align-self: center;
			top: auto !important;
		}
	}

	.cycle-slideshow {
		overflow: visible !important;
		z-index: 1;
	}
}

.project-header__img-gallery-controls, .project-header__video-gallery-controls {
	//width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	position: absolute;
	top: 50%;
	left: -2rem;
	transform: translateY(-50%);
	//z-index: 2;
	z-index: 100;


	button {
		box-shadow: none;
		border: none;
		line-height: 0;
		padding: 1.25rem;
		font-size: 1.5rem;
		// transform: translateX(-50%);
		transition: all 0.5s cubic-bezier(.53,-.01,0,1.23);

		.theme-yellow & {
			// color: $bright-green;
			background: $bright-yellow;
		}
		.theme-green & {
			// color: $bright-purple;
			background: $motum-green;
		}
		.theme-purple & {
			// color: $motum-grey;
			background: $motum-purple;
		}
		.theme-red & {
			// color: $bright-blue;
			background: $motum-red;
		}
		.theme-blue & {
			// color: $bright-yellow;
			background: $bright-yellow;
		}

		i {
			position: relative;
			z-index: 1;
		}

		span {
			display: block;
			position: absolute;
			top: 0;
			width: 0;
			height: 100%;
			z-index: 0;
			transition: all 0.5s cubic-bezier(.53,-.01,0,1.23);
			transition-delay: .04s;

			.theme-yellow & {
				// background: $motum-green;
				background: $motum-purple;
			}
			.theme-green & {
				background: $motum-purple;
			}
			.theme-purple & {
				background: $motum-grey;
			}
			.theme-red & {
				background: $motum-blue;
			}
			.theme-blue & {
				background: $motum-blue;
			}
		}

		&#prev {
			margin-right: 1rem;

			span {
				right: 0;
			}
		}

		&#next {

			span {
				left: 0;
			}
		}

		&:hover, &:focus {
			outline: none;

			&#prev {
				transform: translateX(calc(-7px));
				span {
					width: 100%;
				}
			}
			&#next {
				transform: translateX(calc(7px));
				span {
					width: 100%;
				}
			}
		}

		&.focus-visible:focus {
			outline: 1px solid black;
		}
	}
}

.project-header__video {

	height: 100%;

	background: transparent;
	border: 0;
	cursor: pointer;

	img{

	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		//background-color: #000;
		box-shadow: 0 20px 40px -10px rgba(#000, .6), 0 50px 80px 0 rgba(#000, .3);
		z-index: -1;
		transform: translateZ(-50px);
		transition: .3s;
		opacity: .1;
	}



	&:hover {
		&:after{
			opacity: .15;
		}
		.play-video {
			.theme-yellow & {
				.play-video__play-btn::before {
					// background: rgba($motum-green, 0.75);
					background: rgba($bright-purple, 0.75);
				}
				.play-video__play-btn::after {
					// background: rgba($motum-green, 0.4);
					background: rgba($bright-purple, 0.4);
				}
			}

			.theme-green & {
				.play-video__play-btn::before {
					background: rgba($motum-purple, 0.5);
				}
				.play-video__play-btn::after {
					background: rgba($motum-purple, 0.5);
				}
			}

			.theme-purple & {
				.play-video__play-btn::after {
					background: rgba($bright-purple, 0.4);
				}
			}

			.theme-red & {
				.play-video__play-btn::before {
					background: rgba($motum-blue, 0.5);
				}
				.play-video__play-btn::after {
					background: rgba($motum-blue, 0.5);
				}
			}

			.theme-blue & {
				.play-video__play-btn::before {
					background: rgba($motum-blue, 0.5);
				}
				.play-video__play-btn::after {
					background: rgba($motum-blue, 0.5);
				}
			}
		}
	}

	img {
		width: 100%;
	}

	figure {
		height: 100%;
		display: flex;
		align-content: center;
		margin: 0;
	}

	>div {
		width: 100% !important;
		height: 100% !important;
	}
}

.play-video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;

	&:hover {
		.play-video__text {
			>span {
				transform: translateY(0);
			}
			span span {
				transform: translateX(0);
			}
		}
	}

	.theme-yellow & {
		.play-video__play-btn::before {
			background: rgba($bright-yellow, 0.75);
		}
		.play-video__play-btn::after {
			background: rgba($bright-yellow, 0.4);
		}
		.play-video__text >span {
			background: $bright-yellow;
		}
	}

	.theme-green & {
		.play-video__play-btn::before {
			background: rgba($motum-green, 0.5);
		}
		.play-video__play-btn::after {
			background: rgba($motum-green, 0.5);
		}
		.play-video__text >span {
			background: $motum-green;
		}
	}

	.theme-purple & {
		.play-video__play-btn::before {
			background: rgba($motum-purple, 0.5);
		}
		.play-video__play-btn::after {
			background: rgba($motum-purple, 0.5);
		}
		.play-video__text >span {
			background: $motum-purple;
		}
	}

	.theme-red & {
		.play-video__play-btn::before {
			background: rgba($motum-red, 0.5);
		}
		.play-video__play-btn::after {
			background: rgba($motum-red, 0.5);
		}
		.play-video__text >span {
			background: $motum-red;
		}
	}

	.theme-blue & {
		.play-video__play-btn::before {
			background: rgba($bright-yellow, 0.5);
		}
		.play-video__play-btn::after {
			background: rgba($bright-yellow, 0.5);
		}
		.play-video__text >span {
			background: $motum-yellow;
		}
	}
}

.play-video__play-btn-container {
	display: block;
	position: relative;
}

.play-video__play-btn {
	display: block;
	position: relative;
	width: 13rem;
	height: 13rem;
	margin: 0 auto;

	&::before, &::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: rgba(#fff, 0.5);
		transition: 0.35s ease all;
	}

	&::before {
		width: 9rem;
		height: 9rem;
		border-radius: 50%;
		animation-delay: 0.15s;
		animation-name: playVideoBtnBefore;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;

	}

	&::after {
		width: 12rem;
		height: 12rem;
		border-radius: 50%;
		animation-name: playVideoBtnAfter;
		animation-duration: 1.5s; 
		animation-iteration-count: infinite;
	}
}

.play-video__play-btn-tri {
	width: 0; 
	height: 0; 
	border-top: 2rem solid transparent;
	border-bottom: 2rem solid transparent;
	border-left: 3.5rem solid $motum-darkgrey;
	left: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-left: 3px;
	z-index: 1;
	display: block;
}

@keyframes playVideoBtnAfter {
	0% {
		width: 12rem;
		height: 12rem;
	}
	75% {
		width: 13rem;
		height: 13rem;
	}
	100% {
		width: 12rem;
		height: 12rem;
	}
}

@keyframes playVideoBtnBefore {
	0% {
		width: 9rem;
		height: 9rem;
	}
	75% {
		width: 10rem;
		height: 10rem;
	}
	100% {
		width: 9rem;
		height: 9rem;
	}
}

.play-video__text {
	display: block;
	font-weight: 700;
	font-size: 2rem;
	color: $motum-darkgrey;
	margin: 0 auto;
	margin-top: 1.8rem;
	text-align: center;
	overflow: hidden;

	>span {
		display: block;
		background: #fff;
		padding: 0.75rem 1.25rem;
		transition: 0.35s ease all;
		transform: translateY(-100%);
	}

	span span {
		transform: translateX(calc(-100% - 2rem));
		transition: 0.35s ease all;
		transition-delay: 0.175s;
		display: block;
	}
}


.transition-project-header {
    transition: 0.6s ease-in-out 0.1s;
    opacity: 1;

    .is-animating & {
    	opacity: 0;
    }
}

.is-animating {
	.project-header__content, .project-header__tablet-title {
		transform: translateX(-10%);
        opacity: 0;
	}
	.project-header__visual {
		transform: translateX(10%);
        opacity: 0;
	}
}

.is-animating.is-leaving {
	.project-header__content, .project-header__tablet-title {
		transform: translateX(-10%);
        opacity: 0;
	}
	.project-header__visual {
		transform: translateX(10%);
        opacity: 0;
	}
}
 
.project-header__client {
	@include phone-large-below {
		margin-bottom:1.5rem;
	}
}



// .transition-project-header__content-slide {
//     transform: translateX(0);
//     transition: 0.5s ease-in-out 0.1s;
//     opacity: 1;
//     .is-animating &{
//         transform: translateX(-10%);
//         opacity: 0;
//     }
//     .is-animating.is-leaving &{
//         transform: translateX(-10%);
//         opacity: 0;
//     }
// }

// .transition-project-header__visual-slide {
//     transform: translateX(0);
//     transition: 0.5s ease-in-out 0.1s;
//     display: flex;
//     flex-wrap: wrap;
//     opacity: 1;
//     transition-delay: 0.1s;
//     .is-animating & {
//         transform: translateX(10%);
//         opacity: 0;
//     }

//     .align-center {
//     	display: flex;
//     	align-content: center;
//     }
// }




.project-contact-cta{
	background-color: $motum-yellow;
	padding: 2rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 6rem;

	&__title{
		margin: 0;
		@extend %heading-four;
		padding-right:1rem;
		@include phone-large-below {
			text-align: center;
			margin-bottom:1rem;
		}
	}

	a {
		flex-shrink:0;
	}

	@include tablet-below {
		margin-bottom:4rem;
	}
	@include phone-large-below {
		flex-direction: column;
	}



	.theme-yellow & {
		// color: $motum-green;
		background-color: $motum-purple;
		&::before {
			// background-color: $motum-green;
			background-color: $motum-purple;
		}
	}
	.theme-green & {
		background-color: $motum-purple;
		&::before {
			background-color: $motum-purple;
		}
	}
	.theme-purple & {
		background-color: $motum-purple;
		&::before {
			background-color: $motum-purple;
		}
	}
	.theme-red & {
		background-color: $motum-blue;
		&::before {
			background-color: $motum-blue;
		}
	}
	.theme-blue & {
		background-color: $motum-blue;
		&::before {
			background-color: $motum-blue;
		}
	}

}



