
// Fonts

$font-body: 	'Roboto', sans-serif;
$font-header: 	'Roboto', sans-serif;
$font-thin: 	'Roboto', sans-serif;


// Bezier
$smooth-bezier: cubic-bezier(.694,.048,.335,1);
$fast-bezier: cubic-bezier(.64,.39,.03,1.24);






// Greys
$motum-darkgrey: #3a363c;
$motum-darkgray: #3a363c;
$motum-lightgrey: #e8e8e8;
$motum-lightgray: #e8e8e8;
$motum-grey: #5e5e5e;


// Colors
// $motum-yellow: #ffdc62;
$motum-yellow: #ffe362;
$motum-green: #00ffb0;
$motum-purple: #c5a3ff;
// $motum-red: #ff917f;
$motum-red: #fd836f;
$motum-blue: #5fd4ff;


//link colours
$motum-hyperlink-yellow: #ffda30;
$motum-hyperlink-green: #01b67e;
$motum-hyperlink-purple: #8b4bd2;
$motum-hyperlink-red: #e7432d;
$motum-hyperlink-blue: #009fda;


//brightened colours
$bright-yellow: #ffdb31;
$bright-green: #00ffb0;
// $bright-purple: #7431e0;  7232de
$bright-purple: #7232de;
$bright-red: #e8442d;
$bright-blue: #00bef0;


$colour-primary:$motum-blue;
$colour-secondary:#353535;
$colour-primary-dark:#025290;
$colour-grey:#262626;
$colour-focus:#26af60;

// $comtech-green: #57e370;
$comtech-blue: #00aeef;
// $comtech-yellow: #f4cb00;
// $comtech-red: #ff6363;
// $comtech-gray: #505050;
// $comtech-orange: #ff7322;
// $comtech-purple: #ac67aa;


$admin-1: $comtech-blue;
$admin-2: $motum-red;


$text-grey: #5e5e5e;
$text-gray: #5e5e5e;

// Z-index rules for elements. Will stack according to order in list.
$elements: header__logo, menu-subnav, home-slideshow, home-slideshow-image, home-slideshow-nav, home-slideshow-border, home-slideshow-arrow, header, side-nav-border, side-nav-active-line, footer;
$performance-interactive: image-line, image-title;

$phone-width: 320px;
$phone-large-width: 550px;
$tablet-small-width: 675px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-small-width: 1200px;
$desktop-medium-width: 1400px;
$desktop-large-width: 1600px;
$desktop-xlarge-width: 1800px;


$red-primary: #FD836F;
$red-saturated: #F14A2E;
$red-black: #43172a;

$green-primary: #00FFB0;
$green-saturated: #00BF84;
$green-black: #003042;

$yellow-primary: #FFE362;
$yellow-saturated: #D99C00;
$yellow-black: #3A2A00;

$blue-primary: #5FD4FF;
$blue-saturated: #009DD6;
$blue-black: #004058;


$purple-primary: #C5A3FF;
$purple-saturated: #7232DE;
$purple-black: #23005F;


