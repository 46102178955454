
/* MOBILE NAV */



// .mobile-nav-overlay{
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(244, 123, 41, 1);
//     left: 0px;
//     top: 0px;
//     z-index: 99999;
//     display: none;
// }

.mobile-nav-block{
  position: fixed;
  // left: 0px;
  // top: 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 101;
  opacity: 0;
  pointer-events: none;
  .mobile-menu-open &{
    opacity: 1;
    pointer-events: auto;
  }
  //display: none;
  // color: #ffffff;
  //@include tablet-below{
  //    display: block;
  //}

  // &__title{
  //     font-weight: 500;
  //     margin: 0px;
  //     font-size: 1.9em;
  //     padding: 2.5rem 1.8rem 2.6rem;
  //     display: block;
  // }

  // &__btn-close{
  //     position: absolute;
  //     right: 21px;
  //     top: 18px;
  //     width: 40px;
  //     height: 41px;
  //     background-color: transparent;
  //     border: 0px;
  //     cursor: pointer;
  //     padding: 0px;
  //     outline: 0px;
  //     color: #fff;
  //     opaity: .8;
  //     font-size: 3rem;
  //     transition: all .1s ease-in-out;
  //     &:hover{
  //         opacity: 1;
  //     }
  // }



}


//.mobile-menu-circle.expand{
//
//  /*   transition: all 500ms cubic-bezier(0.000, 0.995, 0.990, 1.000); */
//}
/* End of the code that is partly from: https://codepen.io/rodrigosousa/pen/MvMjdq – Thanks buddy */

/* mobile-nav */
.mobile-nav{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   position: relative; */
}
.mobile-nav ul{
  //top: 35%;
  //transform: translateY(-50%);
  position: relative;
}
.mobile-nav li{
  list-style: none;
  text-transform: uppercase;
  text-align: center;
  opacity: 0;
  transform: translateX(-40px);
  .mobile-menu-open &{
    animation: menuSlideRight 1s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
  }
  @for $i from 1 through 8{
    &:nth-child(#{$i}){
      animation-delay: (.045s * $i);
      animation-duration: 2s;
    }
  }
  > ul{
    display: none;
  }
}
@keyframes menuSlideRight{
  to{
    transform: translateX(0);
    opacity: 1;
  }
}
.mobile-nav ul li a, .mobile-nav ul li span{
  color: white;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 60px;
  &:hover, &:active, &:focus {
    color: $motum-yellow;
  }
}




// .mobile-nav .scroll-container > ul{
//     float: left;
//     width: 100%;
//     margin: 0px; padding: 0px;
//     list-style-type: none;
//     position: relative;
//     //height: 500px;

//     &:before{
//         position: absolute;
//         content: '';
//         display: block;
//         width: 100%;
//         background-color: lighten($motum-red, 7%);
//         height: 1px;
//         top: 0px;
//     }

//     > li{
//         position: relative;
//         float: left;
//         width: 100%;
//         transition: all .15s ease;
//         &:not(.active) ul.collapsed{height: 0px ;}

//         &.active, &.open{
//             background-color: #fff;
//             > a{
//                 color: #333;
//             }
//             button.open-subnav{
//                 i.icon-arrow-up{display: block;}
//                 i.icon-arrow-down{display: none;}
//             }

//         }

//         &:not(.active):not(.open):hover{
//             background-color: lighten($motum-red, 4%);
//         }

//         &.open{
//             button.open-subnav{
//                 i.icon-arrow-up{display: block;}
//                 i.icon-arrow-down{display: none;}
//             }
//         }


//         i{color: #ffbecc;}

//         > a{
//             width: 100%;
//             display: block;
//             padding: 1.8rem;
//             color: lighten($motum-red, 50%);
//             font-weight: 600;
//             position: relative;
//             &:hover{text-decoration: none;}
//         }

//     }



//     > li:after{
//         position: absolute;
//         content: '';
//         display: block;
//         width: 100%;
//         background-color: lighten($motum-red, 7%);;
//         height: 1px;
//         bottom: 0px;
//     }

// }

// .mobile-nav ul li button.open-subnav{
//     position: absolute;
//     right: 5px;
//     top: 50%;
//     transform: translateY(-50%);
//     display: block;
//     border: 1px solid lighten($motum-red, 30%);
//     color: lighten($motum-red, 30%);
//     width: 25px;
//     height: 25px;
//     cursor: pointer;
//     border-radius: 4px;
//     outline: 0;
//     background: transparent;
//     &:after{
//         font-family: 'icoMoon';
//         content: '\e906';
//         font-size: 1.8em;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//     }
// }

// .mobile-nav ul li.open button.open-subnav:after{
//     content: '\e900';
// }




// /* SUBNAV START */
// .mobile-nav ul li ul{
//     float: left;
//     width: 100%;
//     margin: 0px; padding: 0px;
//     list-style-type: none;
//     overflow: hidden;
//     transition: height .2s ease-in-out;

//     > li{
//         position: relative;
//         float: left;
//         clear: left;
//         width: 100%;
//         background-color: darken($motum-red, 10%);
//         transition: all .2s ease-in-out;
//         &:hover{
//             background-color: darken($motum-red, 15%);
//             a{color: #fff; text-decoration: none;}
//         }


//         > a{
//             width: 100%;
//             display: block;
//             padding: 1em 1rem 1rem 1.8rem;
//             color: lighten($motum-red, 60%);
//             font-weight: 400;
//             font-size: 1rem;
//             transition: all .2s ease-in-out;

//         }

//     }



//     li:after{
//         position: absolute;
//         //content: '';
//         display: block;
//         width: 100%;
//         background-color: #969696;
//         height: 1px;
//         bottom: 0px;
//     }

//     li.active{
//         background-color: darken($motum-red, 25%);
//         > a{
//             color: #fff;
//         }
//     }

// }
