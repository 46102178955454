/////////////////
// Semantic.gs // for SCSS: http://sass-lang.com/
/////////////////

// Defaults which you can freely override
$column-width: 80px;
$gutter-width: 20px;
$columns: 12;

// Utility function — you should never need to modify this
@function gridsystem-width($columns:$columns) {
	@return ($column-width * $columns) + ($gutter-width * $columns);
}

// Set $total-width to 100% for a fluid layout
$total-width: gridsystem-width($columns);

// Uncomment these two lines and the star-hack width/margin lines below to enable sub-pixel fix for IE6 & 7. See http://tylertate.com/blog/2012/01/05/subpixel-rounding.html
// $min-width: 999999;
// $correction: 0.5 / $min-width * 100;

// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
	*zoom:1;

	&:before,
	&:after {
	    content:"";
	    display:table;
	}
	&:after {
	    clear:both;
	}
}



//////////
// GRID //
//////////

body {
	width: 100%;
	@include clearfix();
}



@mixin row($columns:$columns) {
	display: block;
	width: $total-width*(($gutter-width + gridsystem-width($columns))/gridsystem-width($columns));
	margin: 0 $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1);
	// *width: $total-width*(($gutter-width + gridsystem-width($columns))/gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1)-$correction;
	@include clearfix();
}
@mixin column($x,$columns:$columns) {
	width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns));
	//float: left;

	// *width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns))-$correction;
}

@function column-val($x,$columns:$columns) {
	@return $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns));

	// *width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns))-$correction;
}


@mixin push($offset:1) {
	margin-left: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}
@mixin pull($offset:1) {
	margin-right: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}

$columns: 12;
$column-width: 80;
$gutter-width: 0;

$total-width: 100%; // Switch from pixels to percentages
