.video-block {
    max-width: 100%;
    margin: 4rem 0;
    &__video {
        width: 100%;
    }
    span {
        margin-top: 0.5rem;
        padding-top: 1rem;
        display: block;
        font-size: 1.6rem;
        color: var(--color-black);
        font-weight: 700;
    }
}

///////////////////////
/// YOUTUBE VIDEOS ///
/////////////////////

.yt-video-block {
    width: 75%;
    margin: 5rem auto;
    display: flex;
    @include tablet-below {
        width: 95%;
    }

    &__player {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
