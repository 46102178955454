
.our-work-card{
	padding: 1rem;
	position: relative;
	transition: opacity .2s ease-in-out;
	//opacity: 0;
	//.page-ready &{
	//    opacity: 1;
	//}

	//@for $i from 1 to 15 {
	//    &:nth-child(#{$i}) {
	//        transition-delay: $i * 0.2s;
	//    }
	//}
	.grid--loading &{
		opacity: 0;
		transform: scale(.75);
	}



	.subspecialty-row__proof &{
		width: 100%;
	}

	.hover & {
		opacity: .6;
		&:hover {
			opacity: 1;
		}
	}
	&.is-hovering{
		z-index: 3;
		opacity: 1;
	}

	&.theme-yellow{
		.our-work-card__title > span::after,
		.our-work-card__type-of-work::after,
		.our-work-card__btn{
			background-color: $bright-yellow;
		}
		.our-work-card__btn{
			color: darken($bright-yellow, 50%);
		}
	}
	&.theme-green{
		.our-work-card__title > span::after,
		.our-work-card__type-of-work::after,
		.our-work-card__btn{
			background-color: $motum-green;
		}
		.our-work-card__btn{
			color: darken($bright-green, 50%);
		}
	}
	&.theme-purple{
		.our-work-card__title > span::after,
		.our-work-card__type-of-work::after,
		.our-work-card__btn{
			background-color: $motum-purple;
		}
		.our-work-card__btn{
			color: darken($bright-purple, 50%);
		}
	}
	&.theme-red{
		.our-work-card__title > span::after,
		.our-work-card__type-of-work::after,
		.our-work-card__btn{
			background-color: $motum-red;
		}
		.our-work-card__btn{
			color: darken($bright-red, 50%);
		}
	}
	&.theme-blue{
		.our-work-card__title > span::after,
		.our-work-card__type-of-work::after,
		.our-work-card__btn{
			background-color: $bright-blue;
		}
		.our-work-card__btn{
			color: darken($bright-blue, 50%);
		}
	}

	//@include tablet-below {
	//	&:hover, &:focus {
	//		.our-work-card__btn {
	//			color: $motum-darkgrey;
	//		}
	//		.our-work-card__btn::before {
	//			width: calc(100% + 2rem);
	//			box-shadow: inset -2rem 0 0 0 $motum-darkgrey;
	//		}
	//		&.theme-yellow {
	//			.our-work-card__btn::before {
	//				// background: $bright-green;
	//				background: $bright-purple;
	//			}
	//		}
	//		&.theme-green {
	//			.our-work-card__btn::before {
	//				background: $motum-purple;
	//			}
	//		}
	//		&.theme-purple {
	//			.our-work-card__btn::before {
	//				background: $bright-yellow;
	//			}
	//		}
	//		&.theme-red {
	//			.our-work-card__btn::before {
	//				background: $bright-blue;
	//			}
	//		}
	//		&.theme-blue {
	//			.our-work-card__btn::before {
	//				background: $bright-yellow;
	//			}
	//		}
	//	}
	//}



}

.our-work-card > div {
	//  background-color: red;
	position: relative;
	transform-style: preserve-3d;
	transform: perspective(300px);

	img {
		//width: 100%;
		max-width: 100%;
		display: block;
		//object-fit: cover;
		//@include desktop-medium-above{
		//	@include responsive-height(25vw, 300px, 400px);
		//}
		//width: auto;
	}
	@include tablet-above{
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			//background-color: #000;
			box-shadow: 0 20px 40px -10px rgba(#000, .6), 0 50px 80px 0 rgba(#000, .3);
			z-index: -1;
			transform: translateZ(-50px);
			transition: .3s;
			opacity: 0;
		}
	}

	@include tablet-below{
		border: 1px solid #ebebeb;
		height: 100%;
	}

	&:hover::after {
		opacity: 1;
	}
}


.our-work-card__title-container{
	display: block;
	transform: translateZ(0px) translateX(0rem) translateY(-50%);
	top: 50%;
	left: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	transition: transform .3s ease-in-out;
	.is-hovering &, .is-in-view &{
		@include tablet-above{
			transform: translateZ(30px) translateX(0rem) translateY(-50%);
		}
		@include desktop-large-below{
			transform: translateZ(10px) translateX(0rem) translateY(-50%);
		}
		@include tablet-below {
			transform: translateZ(10px) translateX(3rem) translateY(-50%);
		}
	}
	@include tablet-below {
		transform: translateZ(10px) translateX(3rem) translateY(-50%);
	}

	//@include tablet-below{
	//	position: relative;
	//	transform: none;
	//	padding: 1.5rem;
	//	transform: none;
	//	top: 0;
	//	background: #fff;
	//}
}

.our-work-card__title {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	@include tablet-below{
		text-align: left;
	}
	> span{
	
		padding: 0rem 1.3rem;
		margin: 0;
		font-size: 3.5rem;
		font-weight: 500;
		line-height: 1.5;

		.featured &{
			font-size: 4rem;
		}
		position: relative;
		white-space: nowrap;
		color: $motum-darkgray;
		transform: translateX(-3rem);
		//background-color: #fff;
		@include tablet-above {
			.hide-titles &{
				opacity: 0;
			}
			background-color:initial;
		}

		@include tablet-below{
			display: inline;
			word-wrap: break-word;
			//padding: 0;
			white-space: normal;
			line-height: 1.2;


		}

		transition: all .75s cubic-bezier(.53,-.01,0,1.03) .1s;


		&:first-child{
			padding-bottom: 0.5rem;
			z-index: 2;
			@include tablet-below {
				margin-bottom:1rem;
			}
		}
		&:last-child{
			//  padding-top: 0;
			margin-top: -1rem;
			z-index: 3;
			transition-delay: .3s;
			&::before{
				transition-delay: .1s;
			}
			@include tablet-below {
				margin-top: -1.2rem;
			}
		}



		.is-hovering &, .is-in-view &{
			@include tablet-above {
				transform: translateX(0);
				opacity: 1;
				.is-animating &{
					transform: translateX(-3rem);
					opacity: 0;
				}
			}
		}

		&::after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			background-color: $bright-blue;
			width: 5px;
			height: 100%;
			transition: width .3s ease-in-out .7s;
			.hide-titles &{
				width: 0;
				@include tablet-below {
					width: 5px;
				}
			}
			.is-hovering &, .is-in-view &{
				@include tablet-above {
					width: 5px;
				}
			}
			//@include tablet-below {
			//	display:none;
			//}
		}


		&::before{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			background-color: #fff;
			transition: all 1.5s cubic-bezier(.53,-.01,0,1.23);
			z-index: -1;
			width: 100%;
			.hide-titles &{
				width: 0;
				@include tablet-below{
					width: 100%;
				}
			}
			.is-hovering &, .is-in-view &{
					width: 100%;
					.is-animating &{
						width: 0;
					}
			}

			
		}



	}
}
.our-work-card__type-of-work{
	color: #858585;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1.4rem;
	padding: 1rem 1rem 1rem 1.5rem;
	letter-spacing: 2px;
	// margin-bottom: -1.25rem;
	margin-bottom: -0.95rem;
	// z-index: 3;
	display: block;
	position: relative;
	.featured &{
		font-size: 1.6rem;
	}
	transform: translateX(-3rem);

	@include tablet-above {

		.hide-titles &{
			opacity: 0;
			transform: translateX(-5rem);
		}
	}
	@include tablet-below{
		//transform: translateX(-3rem);
		//padding: 1.5rem 0 1.25rem 0;
	}
	transition: all .75s cubic-bezier(.53,-.01,0,1.03);

	.is-hovering &, .is-in-view &{
		@include tablet-above {
			transform: translateX(0);
			opacity: 1;
			.is-animating &{
				transform: translateX(-3rem);
				opacity: 0;
			}
		}
	}
	&::before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		transition: all 1.5s cubic-bezier(.53,-.01,0,1.23) ;
		z-index: -1;
		.hide-titles &{
			width: 0;
			@include tablet-below{
				width: 100%;
			}
		}
		.is-hovering &, .is-in-view &{
			@include tablet-above {
				width: 100%;
			}
		}
	}
	&::after{
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $bright-blue;
		width: 5px;
		height: 100%;
		transition: width .3s ease-in-out .7s;
		.hide-titles &{
			width: 0;
			@include tablet-below{
				width: 5px;
			}
		}
		.is-hovering &, .is-in-view &{
			@include tablet-above {
				width: 5px;
			}
		}
	}
}


.our-work-card__btn {
	background-color: $bright-blue;
	display: block;
	color: darken($bright-blue, 30%);
	font-weight: 700;
	text-transform: uppercase;
	padding: 1.3rem 3.3rem 1.3rem 1.5rem;
	letter-spacing: 2px;
	margin-top: .75rem;
	position: relative;
	transition: all .75s cubic-bezier(.53,-.01,0,1.03) .4s;

	&::after{
		content: '\e904';
		font-family: 'icoMoon';
		position: absolute;
		top: 50%;
		right: 1rem;
		transform: translateY(-50%);
	}
	transform: translateX(-3rem);
	@include tablet-above{

		.hide-titles &, .is-animating &{
			opacity: 0;
			transform: translateX(-5rem);
		}

	}

	.is-hovering &, .is-in-view &{
		@include tablet-above {
			transform: translateX(0);
			opacity: 1;
			.is-animating &{
				transform: translateX(-3rem);
				opacity: 0;
			}
		}
	}



	@include tablet-below {
		z-index: 1;
		transition: all .5s cubic-bezier(.53,-.01,0,1.03);
		overflow: hidden;

		&::before {
			display: block;
			content: '';
			width: 0%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 1.25s cubic-bezier(.53,-.01,0,1.23);
			z-index: -1;
			box-shadow: inset -20rem 0 0 0 $motum-darkgrey;
		}
		&::after {
			z-index: 1;
		}
	}
}

.our-work-card__img {
	@include tablet-below {
		max-height: 250px;
		overflow: hidden;

		// img {
		// 	object-fit: cover;
		// 	object-position: center center;
		// 	display: block;
		// 	width: 100%;
		// }
	}
}







