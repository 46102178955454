.lastest-article {
    @include column(9);

    @include desktop-small-below {
        @include column(8);
    }

    @include desktop-below {
        @include column(12);
    }
}

.thinking-index__title {
    font-size: 2.5rem;
    font-weight: 700;
    display: block;
    // width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    // padding-bottom: 2rem;
    // border-bottom: solid 5px $motum-darkgrey;

    &.articles-title {
        margin-right: 3.5rem;
        margin-left: 1rem;
    }
}

.thinking-side-content {
    width: 25%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;

    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    .is-animating &{
        transform: translateY(2rem);
        opacity: 0;
    }

    &.wwr {
        margin-bottom: 4rem;
    }

    @include desktop-small-below {
        @include column(4);
    }

    @media (max-width: 850px) {
        @include column(6);
        padding-right: 4rem;
    }

    @media (max-width: 600px) {
        @include column(12);
    }
}

.thinking-side-content__nav {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: auto;

    .nav-btns {
        background: #fff;
        display: inline-block;
        padding: 0 1rem;
    }

    button {
        color: $motum-darkgrey;
        font-size: 2rem;
        text-align: center;
        border: none;
        background: none;
        transition: 0.35s ease all;

        &:first-child {
            padding-right: 5px;

            &:hover, &:focus {
                transform: translateX(-5px);
            }
        }
        &:last-child {
            padding-left: 5px;

            &:hover, &:focus {
                transform: translateX(5px);
            }
        }

        &:hover, &:focus {
            outline: none;
            color: $bright-blue;
        }
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        background: $motum-darkgrey;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: -1;
    }
}

.slide__container {
    width: 100%;
    //height: 435px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    @include tablet-below {
        height:auto;
    }
}

.thinking-side-content__slideshow {
    width: 100%;
}

.thinking-slide__content {
    margin-bottom: 2rem;
    width: 100%;

    a {
        font-size: 1.8rem;
        color: $motum-darkgrey;
        line-height: 1.2;
        font-weight: 700;
        transition: 0.35s ease all;

        &:hover, &:focus {
            outline: none;
            color: $bright-blue;
        }
    }

    .small-text {
        display: block;
        font-size: 1.35rem;
        color: rgba($motum-grey, 0.5);
        font-weight: 400;
        @include phone-large-below {
            font-size: 2rem;
        }
    }
}

.thinking-tags {
    width: 100%;
    padding: 3rem 0 1rem;
    margin-bottom: 2rem;
    border-top: solid 5px $motum-darkgrey; 
    border-bottom: solid 1px $motum-darkgrey;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include desktop-small-below {
        justify-content: flex-start;
    }
    @include tablet-below {
        justify-content: center;
    }

    a {
        position:relative;
        font-size: 2.1rem;
        // text-transform: uppercase;
        font-weight: 700;
        margin-right: 1rem;
        margin-bottom: 2rem;
        white-space: nowrap;
        color: #8d8d8d;
        transition: all .2s ease-in-out;
        &:hover{
            color: $motum-darkgray;
        }
        &.active {
            color: $motum-darkgray;
            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                left: auto;
                bottom: -6px;
                width: 0;
                height: 3px;    
                transition: all 0.25s cubic-bezier(0.694, 0.048, 0.335, 1);
                pointer-events: none;
                z-index: 2;
                width: 100%;
                left: 0;
                background-color: var(--color-secondary);
               
            }
        }
        @include desktop-small-below {
            margin-right:2.5rem;
        }
    }
}

.thinking-loader {
    //margin: 15rem auto 0;
    z-index: 0;
    position: absolute;
    left: 50%;
    top: 15rem;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
transition: opacity .2s ease-in-out;
    .is-animating &{
        opacity: 1;

    }

}


