
.people-intro {
    //padding: 2rem 0 0;
    //border-top: solid 3px $motum-darkgrey;
    p {
        padding-top: 2rem;
    }
}

.team-toggle{
    color: $motum-darkgrey;
    &:hover, &:active, &:focus{
        outline: none;
        cursor: pointer;
        text-decoration: none;
    }
    .team-toggle__list{
        padding: 1.5rem 3rem 1.5rem 1rem;
        background-color: $motum-yellow;
        max-width: 20rem;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        margin-top: 2rem;
        cursor: pointer;
        transition: 0.35s ease all;
        &:after{
            content: '+';
            display: block;
            position: absolute;
            right: 2rem;
            top: 28%;
            transform: translateY(-50%);
            font-size: 2rem;
            -ms-transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
        }
    }
    &.active{
        color: #fff;
        .team-toggle__list{
            background-color: $motum-darkgrey;
            &:after{
                top: 28%;
                transform: translateY(-50%);
                -ms-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                animation: activeAnimation 0.5s;
                @keyframes activeAnimation{
                    0%{
                        top: 28%;
                        -ms-transform: rotate(225deg);
                        -webkit-transform: rotate(225deg);
                        transform: rotate(225deg);
                    }
                    100%{
                        top: 28%;
                        -ms-transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}



.team-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .team-list__name{
        padding: 1.25rem 2rem 1.25rem 0.75rem;
        color: $motum-darkgrey;
        //width: 20rem;
     //   @include column(1.71);
        width: 16.65%;
        transition: background 0.35s ease-in-out, opacity .3s ease;
       // opacity: 0;
        background: transparent;
        border: 0;
        margin: 0;
        text-align: left;

        .team-list__img{
            display: none;
        }

        h3.team-list__title{
            font-weight: 700;
            font-size: 2rem;
            text-align: left;
            margin: 0 0 .5rem 0;
            transition: transform 0.15s ease-in-out;
        }
        span{
            margin-bottom: 0;
            font-size: 1.3rem;
            text-align: left;
            display: block;
            font-weight: 600;
            //color: #787878;
            transition: transform 0.15s ease-in-out;
        }
        i{
            display: none;
        }
        &:hover, &:active, &.active, &.focus-visible{
            // outline: none;
            text-decoration: none;
            box-shadow: -0.8rem 0 0 0 $motum-yellow;
            cursor: pointer;
            background-color: #dfdfdf;
            h3, span{
                transform: translateX(.5rem);
            }
            //h3{
            //    color: #fff;
            //}
            //span{
            //    color: #bbbbbb;
            //}
        }

        @include desktop-small-below{
            @include column(3);
            h3{
                font-size: 2.2rem;
            }
            span{
                font-size: 1.7rem;
            }
        }
        @include tablet-below{
            @include column(4);
            h3{
                font-size: 1.9rem;
            }
            span{
                font-size: 1.6rem;
            }
        }
        @include phone-large-below{
            @include column(12);
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            padding:0;

            &::after {
                position: absolute;
                content: '';
                @include column(12);
                height: 0.5rem;
                background-color: $motum-darkgray;
                bottom: 0;
                left: 0;
            }
            &:last-child::after{
                display: none;
            }
            .team-list__img{
                display: block;
                width: 35%;
                padding-top: 2rem;

                img {
                    max-height: 150px;
                    max-width: 100%;
                }
            }
            .team-list__info{
                width: 65%;
                padding-left: 1.7rem;
                h3{
                    font-size: 3.5rem;
                    text-align:left;
                    margin-bottom:0.5rem;
                }
                span{
                    font-size: 2.5rem;
                    font-weight: 400;
                    text-align:left;
                    color: $motum-grey;
                }
            }
            i{
                display: block;
                text-align:right;
                font-size: 4rem;
                font-weight: 700;
                margin-left: 1rem;
            }
        }
        @media (max-width: 390px){
            @include column(12);

        }
    }
}
.team-line{
    margin-top: auto;
    width: 100vw;
    //opacity: 0;
    transition: all .5s ease-in-out;
    &.is-visible{
      //  opacity: 1;
    }
    .team-line__scroll{
        float: left;
        width: 100%;
    }
    @include desktop-below{
        margin-top: 0;
        .team-line__scroll{
            float: none;
        }
    }
    @include phone-large-below {
        display: none;
    }
    .team-line__members{
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        width: 100%;

        > div{
            float: left;
        }
        @include desktop-below{
            //display: block;
            overflow: visible;
            flex-wrap: wrap;
            justify-content: space-around;
            //margin: 0 2rem 5rem;
            > div{
                float: none;
            }
        }
    }
    .team-line__member{
        transition: 0.35s all ease;
        margin-top: 7rem;
        position: relative;
       // opacity: .7;
        //background-color: #e8e8e8;
        &:nth-child(odd){
            z-index: 1;
        }
        //@for $i from 1 through 25{
        //    &:nth-child(#{$i}) .team-line__img{
        //        animation: membersFadeRiseIn 1s cubic-bezier(.53,-0.01,0,1.11) (.04s * $i) 1 forwards normal;
        //    }
        //}
        //@keyframes membersFadeRiseIn{
        //    0%{
        //        //transform: translateY(2rem);
        //        opacity: 0;
        //    }
        //    75%{
        //        opacity: 1;
        //    }
        //    100%{
        //        //transform: translateY(0);
        //        opacity: 1;
        //    }
        //}
        @include desktop-below{
            margin-left: 0;
        }
        .team-line__topper{
            position: relative;
            opacity: 0;
            transition: 0.5s all ease;
            img{
                position: absolute;
                left: 50%;
                top: -7rem;
                transform: translateX(-50%);
            }
        }
        .team-line__img{
            //transition: 0.5s all ease;
            // transform: scale(0);
            //transform: translateY(2rem);
            opacity: 1;

            img{
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                margin: 0 auto;
                display: block;

            }
        }
        .team-line__name{
            background-color: $motum-yellow;
            position: absolute;
            right: 0;
            top: 50%;
            padding: 1rem;
            opacity: 0;
            transition: 0.5s all ease;
            z-index: 2;
            .team-line__name-title {
                font-weight: 700;
                font-size: 1.9rem;
            }
            p{
                margin-bottom: 0;
                font-size: 1.3rem;
            }
            @include tablet-small-below {
                h3 {
                    font-size: 2.1rem;
                }
                p {
                    font-size: 1.6rem;
                }
            }
            @include phone-large-below {
                h3 {
                    font-size: 2.2rem;
                }
                p {
                    font-size: 1.7rem;
                }
            }

            // &::after{
            //     content: '';
            //     display: block;
            //     position: absolute;
            //     right: -1.5rem;
            //     bottom: -2.5rem;
            //     background-image: url(/images/interface/pointing-hand-icon.svg);
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     width: 3.5rem;
            //     height: 4rem;
            // }
        }
        &.empty{
            @include desktop-below{
                display: none;
            }
        }
        &:not(.empty):hover, &:not(.empty):active, &:not(.empty):focus, &:not(.empty).active{
            outline: none;
            z-index: 2;
            padding-left: 3rem;
            margin-right: 3rem;

            @include desktop-below{
                padding-left: 0;
                margin-right: 0;
            }
            cursor: pointer;
            opacity: 1;
            .team-line__img{
                img{
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                }
            }
            .team-line__topper{
                opacity: 1;
                animation: topperAnimation 0.45s;
                aniamtion-delay: 0.5s;
                @keyframes topperAnimation{
                    0%{
                        opacity: 0;
                        transform: scale(0.0);
                    }
                    65%{
                        opacity: 1;
                        transform: scale(1.15);
                    }
                    100%{
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
            .team-line__name{
                opacity: 1;
            }
        }
        @include desktop-below{
            @include column(4);
            padding: 0 2rem;
            .team-line__name{
                opacity: 1;
                width: 80%;
                left: 50%;
                transform: translateX(-50%);
                //  top: 95%;
                text-align: center;
                position: relative;
            }
            .team-line__img img{
                margin: 0 auto;
                display: block;
                max-width: 100%;
            }

            &:hover, &:active, &:focus, &.active{
                padding-left: 0;
                margin-right: 0;
            }
        }
        @include tablet-below{
            @include column(6);
        }
        @include phone-large-below{
            @include column(12);
        }
    }
    /* jScrollPane CSS start */
    .jspScrollable:focus{
        outline: none;
    }
    .jspContainer
    {
        overflow: hidden;
        position: relative;

        @include tablet-below{
            overflow: visible;
            width: 100% !important;
        }
    }

    .jspPane
    {
        position: absolute;

        @include tablet-below{
            width: 100% !important;
            position: static !important
        }
    }

    .jspVerticalBar
    {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 100%;
        // background: red;
    }

    .jspHorizontalBar
    {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        // background: red;
    }

    .jspCap
    {
        display: none;
    }

    .jspHorizontalBar .jspCap
    {
        float: left;
    }

    .jspTrack
    {
        // background: #dde;
        position: relative;
        display: none;
    }

    .jspDrag
    {
        //background: #bbd;
        position: relative;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    .jspHorizontalBar .jspTrack,
    .jspHorizontalBar .jspDrag
    {
        float: left;
        height: 100%;
    }


    .jspArrow.jspDisabled
    {
        cursor: default;
        // background: #80808d;
        opacity: 0;
        pointer-events: none;
    }

    .jspVerticalBar .jspArrow
    {
        height: 16px;
    }

    .jspHorizontalBar .jspArrow
    {
        display: block;
        cursor: pointer;
        padding: 0;
        margin: 0;
        width: 15rem;
        float: left;
        height: 100%;
        position: absolute;
        top: 0;
        border: 0;
        z-index: 5;
        transition: opacity 1s ease-in-out;


        &:after{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-family: 'icoMoon';
            font-size: 1.7em;
            color: #fff;
            z-index: 1;
            display: block;

        }

        &.jspArrowRight{
            right: 0;
            background-image: url('/images/interface/team-members_scroll-right.png');
            background-repeat: none;
            background-position: right;
            // &:after{
            //     right: 15px;
            //     content: '\e912';
            // }
        }
        &.jspArrowLeft{
            left: 0;
            background-image: url('/images/interface/team-members_scroll-left.png');
            // &:after{
            //     left: 15px;
            //     content: '\e913';
            // }
        }
    }



    .jspVerticalBar .jspArrow:focus
    {
        outline: none;
    }

    .jspCorner
    {
        background: #eeeef4;
        float: left;
        height: 100%;
    }
    /* jScrollPane CSS end */

}
.team-modal {
    // position: fixed;
    // z-index: 1000;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // overflow: auto;
    // background-color: rgba(white, 0.85);
    padding: 14rem 3rem;
    @include tablet-below {
        padding: 12rem 15px;     
    }
    @include phone-large-below {
        //overwriting bootstrap JS
        padding-left: 15px !important;
    }
    // display: none;
    &.color-theme-red .team-modal__content{
        border-color: $motum-red;
    }
    &.color-theme-purple .team-modal__content{
        border-color: $motum-purple;
    }
    &.color-theme-green .team-modal__content{
        border-color: $motum-green;
    }
    &.color-theme-yellow .team-modal__content{
        border-color: $motum-yellow;
    }
    &.color-theme-blue .team-modal__content{
        border-color: $motum-blue;
    }
    .team-modal__content{
        background-color: whitesmoke;
        max-width: 135rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        // //padding: 8rem 0;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 8px solid $motum-blue;
        position: relative;
        .close {
            position: absolute;
           //width: 100%;
            // display: flex;
            // justify-content: flex-end;
            cursor: pointer;
            border: 0;
            background: none;
            font-size: 3.5rem;
            top: 2rem;
            right: 2rem;
            z-index: 3;
        }
        .team-modal__img{
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding-right: 2rem;
            margin-top: 10rem;
            @include tablet-below{
                margin-top: 3rem;
                margin-left: 3rem;
                display: block;
                img{
                 //   display: none;
                }
            }
        }
        .team-modal__copy{
            width: 70%;
            padding-top: 7rem;
            padding-left: 7rem;
            position: relative;
            align-self: flex-start;
            img{
                max-width: 10rem;
            }
            h2 {
                font-weight: 600;
                font-size: 7.5rem;
                padding: 1.5rem 0 1rem;
                margin-bottom:0;
            }
            h3 {
                font-size: 2.5rem;
                padding-bottom: 6rem;
                margin-bottom:0;
            }
            p {
                font-size: 1.7rem;
                line-height: 3rem;
                max-width: 82.5rem;
                font-weight:400;
                padding-bottom:7rem;
            }
            @include desktop-below{
                width: 60%;
                padding-left: 4rem;
                h2{
                    font-size: 5.5rem;
                }
                p{
                    padding-bottom: 4rem;
                }
            }
            @include tablet-below{
                width: 100%;
                padding-top: 4rem;
                padding-right: 4rem;
                h3{
                    padding-bottom: 3rem;
                }
                p{
                    padding-bottom: 0;
                    line-height: auto;
                    font-size: 2.5rem;
                    line-height: 140%;
                }
            }
        }
    }
    .team-modal__exit{
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        // overflow: auto;
    }

    .team-modal__social-btn{
        font-weight: 700;
        font-size: 2rem;
        display:inline-block;
        margin-bottom:3rem;
        i{
            margin-right: 1rem;
        }
    }
}

.people-careers-callout{
    background-color: $motum-yellow;
    padding: 1rem 0;

    &__title{
        margin: 0;
      @extend %heading-four;
    }

    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}


.transition-team-member-name{
    opacity: 1;
    .is-animating &{
        opacity: 0;
    }
    @for $i from 1 through 25{
        &:nth-child(#{$i}){
            transition: background-color .2s ease-in-out, box-shadow 0.35s ease, opacity .3s ease ((.02s * $i));
        }
    }
}



.team-line__member {
    opacity: .7;
    .is-animating &{
        // !important added to fix small animation bug
        opacity: 0 !important;
    }
    @for $i from 1 through 25{
        &:nth-child(#{$i}){
            transition: box-shadow 0.35s ease, opacity .3s ease ((.02s * $i)), padding .2s ease-in-out, margin .2s ease-in-out;
        }
    }
}
