.accessibilityInquiry {
    padding: 0 12rem;
}

.freeform-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 3rem;
    width: 100%;
    @include tablet-below {
        margin-bottom: 0;
        fieldset {
            margin-bottom: 1.5rem;
        }
    }
    .freeform-column {
        flex: 1;
        margin-right: 1.5rem;
        @include tablet-below {
            flex:none;
            width: 100%;
            margin-bottom: 3rem;
        }
    }
    fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        // margin-bottom: 3rem;
        width: 100%;
    }
    legend {
        color: $motum-darkgrey;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        display: block;
        font-weight: 500;
    }
    label {
        
        color: $motum-darkgrey;
        font-size: 1.8rem;
        margin-bottom: .8rem;
        display: block;
        font-weight: 500;
    }
    input, textarea, select {
        width: 100%;
        font-size: 2rem;
        padding: 1.6rem;
        color: $motum-darkgrey;
        border: none;
        background: rgba($motum-lightgrey, 0.5);
        font-family: $font-body;

        &:focus {
            background: $motum-lightgrey;
            outline: none;
        }
    }
    textarea{
        resize: vertical;

    }
    // select {
    //     height: 56.80px;
    // }
    p.small-text {
        margin-top: -3rem;
    }
    //errors
    ul.freeform-errors li{
        color: $red-saturated;
        padding-left: 0;
        font-size: 1.5rem;
    }

    
}

.freeform-row .freeform-column .freeform-label.freeform-required:after {
    content: "*";
    margin-left: 5px;
    color: $bright-blue;
}
.freeform-form-has-errors{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: $red-saturated;
    text-align: center;
}
//     .s12 {
//         width: 100%;
//         margin-right: 1.5rem;
//     }

//     .freeform-column {
//         width: calc(50% - 1.5rem);
//         margin-right: 1.5rem;
//     }

//     .s4 {
//         width: calc(33.333% - 1.5rem);
//         margin-right: 1.5rem;
//     }


//     @include tablet-below{
//         .s6 {
//             width: 100%;
//             margin-right: 1.5rem;
//         }
//         .s4 {
//             width: 47%;
//         }
//     }
//     @include phone-large-below{
//         .s4, .s6, .s12 {
//             width: 100%;
//             margin-right: 0;
//         }
//     }
// }

// .options-style{
//     margin-bottom: 2rem;
// }

// .form-field {
//     width: 100%;
//     margin-bottom: 3rem;

//     label {
//         color: $motum-darkgrey;
//         font-size: 1.8rem;
//         margin-bottom: .8rem;
//         display: block;
//         font-weight: 500;
//     }

//     input, textarea, select {
//         width: 100%;
//         font-size: 2rem;
//         padding: 1.6rem;
//         color: $motum-darkgrey;
//         border: none;
//         background: rgba($motum-lightgrey, 0.5);
//         font-family: $font-body;

//         &:focus {
//             background: $motum-lightgrey;
//             outline: none;
//         }
//     }

//     textarea{
//         resize: vertical;

//     }

//     select {
//         height: 61.38px;
//     }
// }

// .main-content p.label-style {
//     color: $motum-darkgrey;
//     font-size: 1.8rem;
//     margin-bottom: 1rem;
//     display: block;
//     font-weight: 500;
// }

.general-consent, .radio-container, .fancy-checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 1.75rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.1;

    &:hover{
        span{
            background-color: $motum-lightgray;
        }
    }

    input:focus + span {
        background-color: $motum-lightgray;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.general-consent {
    padding-left: 35px;
    margin-bottom: 2.5rem;

    .custom-checkbox {
        position: absolute;
        top: -5px;
        left: 0;
        height: 25px;
        width: 25px;
        // background-color: rgba($motum-lightgrey, 0.5);
        background-color: transparent;
        border: solid 3px $motum-darkgrey;
        overflow: hidden;



        &::before, &::after {
            content: "";
            position: absolute;
            display: block;
        }

        &::before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            background: $motum-darkgrey;
            // border-radius: 50%;
            transition: 0.5s ease all;
            border: solid 0 $motum-grey;
        }

        &::after {
            opacity: 0;
            left: 8px;
            top: 0px;
            // width: 5px;
            // height: 10px;
            width: 8px;
            height: 16px;
            border: solid $motum-darkgrey;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: 0.25s ease all;
            // transition-delay: 0.25s;
        }
    }

    input:checked ~ .custom-checkbox {
        border: none;
    }

    input:checked ~ .custom-checkbox:before {
        width: 200%;
        height: 200%;
        border: solid 20px $motum-blue;
        background: #fff;
    }

    input:checked ~ .custom-checkbox:after {
        opacity: 1;
    }
}


// .radio-container {
//     padding-left: 35px;
//     margin-bottom: 2.5rem;

//     .radio {
//         position: absolute;
//         top: -5px;
//         left: 0;
//         height: 25px;
//         width: 25px;
//         // background-color: rgba($motum-lightgrey, 0.5);
//         background-color: transparent;
//         border: solid 3px $motum-darkgrey;
//         overflow: hidden;
//         border-radius: 50%;

//         &::before, &::after {
//             content: "";
//             position: absolute;
//             display: block;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//             width: 0;
//             height: 0;
//             border-radius: 50%;
//             transition: 0.5s ease all;
//         }

//         &::before {
//             background: $motum-darkgrey;
//             border: solid 0 $motum-grey;
//         }

//         &::after {
//             background: #fff;
//             transition-delay: 0.05s;
//         }
//     }

//     input:checked ~ .radio:before {
//         width: 200%;
//         height: 200%;
//         border: solid 20px $motum-darkgrey;
//     }

//     input:checked ~ .radio:after {
//         width: 50%;
//         height: 50%;
//     }
// }

.fancy-checkbox-container {
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    .checkmark {
        padding: 1.2rem 1.5rem;
        // color: darken($motum-blue, 20%);
        color: $motum-darkgrey;
        background-color: transparent;
        box-shadow: inset 0 0 0 3px $motum-darkgrey;
        display: block;

        overflow: hidden;
        transition: all 0.2s ease-in-out;
        // animation: 0.2s fancy-checkbox;
    }

    input:checked ~ label .checkmark {
        box-shadow: inset 0 0 0 0 $motum-darkgrey;
        background: $motum-darkgrey;
        color: #fff;
        animation: 0.25s fancy-checkbox-in ease-in-out;
    }
    input:not(:checked) ~ label .checkmark {
        animation: 0.25s fancy-checkbox-out ease-in-out;
    }

    body:not(.using-mouse) & input:focus ~ label {
        outline:3px solid $motum-purple;
        outline-offset: 1px;
    }
}

@keyframes fancy-checkbox-in {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fancy-checkbox-out {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

button[type=submit], input[type=submit] {
    background: transparent;
    border: none;
    box-shadow: none;
    display: inline-block;
    padding: 0;
    cursor: pointer;

    i {
        font-size: 2.2rem;
        margin-left: 1rem;
    }

    span {
        display: block;
        width: 100%;
        background: $motum-purple;
        padding: 1.1rem 1.6rem;
        font-weight: 700;
        font-size: 2.75rem;
        position: relative;
        transition: all 0.75s cubic-bezier(.53,-.01,0,1.23);
        transition-delay: 0.25s;
        color: $motum-darkgrey;
        z-index: 1;
    }

    span::after, span::before {
        content: '';
        display: block;
        width: 0%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.75s cubic-bezier(.53,-.01,0,1.23);
    }

    span::before {
        background: $bright-yellow;
    }

    span::after {
        background: $motum-darkgrey;
        transition-delay: 0.15s;
    }

    &:hover {

        span {
            color: #fff; 

            &::after, &::before {
                width: 100%;
            }
        }
    }
}

// .casl-wrapper{
//     border-top: 2px solid #ebebeb;
//     padding-top: 3rem;
//     .form-field{
//         margin-bottom: 0;
//     }
//     .options-style{
//         margin-bottom: 0;
//         label{
//             margin-bottom: 1rem;
//         }
//     }
//     .small-text{
//         margin-bottom: 2rem;
//     }
// }

form.ao-form {
    .ao-row {
        display: flex;
        gap: 1.5rem;
        margin-bottom: 1rem;
    }

    label.ao-form-label {
        color: $motum-darkgrey;
        font-size: 1.8rem;
        margin-bottom: .8rem;
        display: block;
        font-weight: 500;
        span.ao-required {
            margin-left: 5px;
            color: $bright-blue;
        }
    }

    input:not([type="radio"]):not([type="checkbox"]), select, textarea {
        width: 100%;
        font-size: 2rem;
        padding: 1.6rem;
        color: $motum-darkgrey;
        border: none;
        background-color: rgba($motum-lightgrey, 0.5);
        font-family: $font-body;

        &:focus {
            background: $motum-lightgrey;
            outline: none;
        }
    }

    //only for general contact fancy checkboxes
    .ao-row#row-r1732294212564 {
        .ao-combo-block {
            display: flex;
            flex-direction: column;  
            width: 100%;

            .ao-combo-layout#b1722026437913 {
                display: flex;  
                flex-wrap: wrap;
                justify-content: flex-start;
                align-content: flex-start;
                width: 100%;
                row-gap: 1rem;
                column-gap: 1.5rem;

                .ao-combo-label {
                    display: block;
                    position: relative;
                    padding: 1.2rem 0;
                    cursor: pointer;
                    margin-right: 0;

                    &:hover{
                        background-color: $motum-lightgray;
                    }

                    input[type="checkbox"] {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    span {
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        line-height: 1.1;
                        color: $motum-darkgrey;
                        font-size: 1.8rem;
                        margin-bottom: .8rem;
                        font-weight: 500;
                        background-color: transparent;
                        overflow: hidden;
                        padding: 1.2rem 1.5rem;
                        box-shadow: inset 0 0 0 3px $motum-darkgrey;
                        transition: all 0.2s ease-in-out;
                    }

                    input[type="checkbox"]:checked ~ span {
                        box-shadow: inset 0 0 0 0 $motum-darkgrey;
                        background: $motum-darkgrey;
                        color: #fff;
                        animation: 0.25s fancy-checkbox-in ease-in-out;
                    }
                    input[type="checkbox"]:not(:checked) ~ span {
                        animation: 0.25s fancy-checkbox-out ease-in-out;
                    }
                }
            }
        }
    }


    //only for general contact consent, for stay informed consent
    .ao-row#row-r1732294100047 {
        flex-direction: column;
        gap: 0;

        .ao-column-2 {
            width: 100%;

            .ao-combo-layout {
                .ao-combo-label {
                    display: inline-flex !important;
                    align-items: center;
                    gap: 1rem;

                    > span {
                        color: $motum-darkgrey;
                        font-size: 1.8rem;
                        margin-bottom: 0;
                        display: inline-block;
                        font-weight: 500; 
                    }

                    input[name="CASL Consent"] {
                        height: 25px !important;
                        width: 25px !important;
                        background-color: transparent !important;
                        border: solid 3px $motum-darkgrey !important;
                        overflow: hidden !important;
                        appearance: none;
                        margin: 0 !important;
                        &::before, &::after {
                            content: "";
                            position: absolute;
                            display: block;
                        }

                        &::before {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 0;
                            height: 0;
                            background: $motum-darkgrey;
                            // border-radius: 50%;
                            transition: 0.5s ease all;
                            border: solid 0 $motum-grey;
                        }

                        &::after {
                            opacity: 0;
                            left: 8px;
                            top: 0px;
                            width: 8px;
                            height: 16px;
                            border: solid $motum-darkgrey;
                            border-width: 0 3px 3px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                            transition: 0.10s ease all;
                        }

                        &:checked {
                            border: none !important;
                            &:before {
                                width: 200%;
                                height: 200%;
                                border: solid 20px $motum-blue;
                                background: #fff;
                            }
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .ao-column-10 {
            width: 100%;
        }
    }

    //only for stay informed consent
    .ao-row#row-r1741808367122 {
        flex-direction: column;
        gap: 0;

        .ao-column {
            width: 100%;

            .ao-combo-layout {
                .ao-combo-label {
                    display: inline-flex !important;
                    align-items: center;
                    gap: 1rem;

                    > span {
                        color: $motum-darkgrey;
                        font-size: 1.8rem;
                        margin-bottom: 0;
                        display: inline-block;
                        font-weight: 500; 
                    }

                    input[name="casl"] {
                        height: 25px !important;
                        width: 25px !important;
                        background-color: transparent !important;
                        border: solid 3px $motum-darkgrey !important;
                        overflow: hidden !important;
                        appearance: none;
                        margin: 0 !important;
                        &::before, &::after {
                            content: "";
                            position: absolute;
                            display: block;
                        }

                        &::before {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 0;
                            height: 0;
                            background: $motum-darkgrey;
                            // border-radius: 50%;
                            transition: 0.5s ease all;
                            border: solid 0 $motum-grey;
                        }

                        &::after {
                            opacity: 0;
                            left: 8px;
                            top: 0px;
                            width: 8px;
                            height: 16px;
                            border: solid $motum-darkgrey;
                            border-width: 0 3px 3px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                            transition: 0.10s ease all;
                        }

                        &:checked {
                            border: none !important;
                            &:before {
                                width: 200%;
                                height: 200%;
                                border: solid 20px $motum-blue;
                                background: #fff;
                            }
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .ao-column-10 {
            width: 100%;
        }
    }

    button[type=submit], input[type=submit] {
        background: $motum-purple !important;
        border-radius: 0 !important;
        border: none;
        box-shadow: none;
        display: inline-block !important;
        padding: 1.1rem 1.6rem!important;
        font-weight: 700 !important;
        font-size: 2.75rem !important;
        position: relative;
        transition: all 0.75s cubic-bezier(.53,-.01,0,1.23);
        transition-delay: 0.25s;
        color: $motum-darkgrey !important;
        z-index: 1;
        cursor: pointer;
        &::after,
        &::before {
            content: '';
            display: block;
            width: 0%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1 !important;
            transition: all 0.75s cubic-bezier(.53,-.01,0,1.23);
        }

        &::before {
            background: $bright-yellow !important;
        }
    
        &::after {
            background: $motum-darkgrey !important;
            transition-delay: 0.15s;
        }

        &:hover {
            color: #fff !important; 
    
            &::after, &::before {
                width: 100% !important;
            }
        }
    
        i {
            font-size: 2.2rem;
            margin-left: 1rem;
        }
    
        // span {
        //     display: block;
        //     width: 100%;
        //     // background: $motum-purple;
        //     // padding: 1.1rem 1.6rem;
        //     // font-weight: 700;
        //     // font-size: 2.75rem;
        //     // position: relative;
        //     // transition: all 0.75s cubic-bezier(.53,-.01,0,1.23);
        //     // transition-delay: 0.25s;
        //     // color: $motum-darkgrey;
        //     // z-index: 1;
        // }
    
        // span::after, span::before {
        //     content: '';
        //     display: block;
        //     width: 0%;
        //     height: 100%;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     z-index: -1;
        //     transition: all 0.75s cubic-bezier(.53,-.01,0,1.23);
        // }
    
        // span::before {
        //     background: $bright-yellow;
        // }
    
        // span::after {
        //     background: $motum-darkgrey;
        //     transition-delay: 0.15s;
        // }
    
        // &:hover {
        //     span {
        //         color: #fff; 
    
        //         &::after, &::before {
        //             width: 100%;
        //         }
        //     }
        // }
    }
}