

.page-header{
    position:relative;
    width:100%;
    height:auto;
    padding: 12rem 0 2rem 0;
    z-index:3;
    margin:0 auto;
    overflow:hidden;
    background-position: center center;
    background-size: cover;

    &.page-header--content-page{
        .wrapper{
            padding: 0 12rem;
        }
    }

    &.thin{
        @include column(8);
    }

    p{
        color:#3a3a3a;
    }
    strong{
        color: $motum-darkgray;
    }
    @include phone-large-below{
        padding: 16rem 0 2rem 0;
    }

}


.page-header__actions{
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 15;

    @include tablet-below{
        width: 100%;
        top: 90px;
    }
}

.page-header__nav-btn{
    color: #3a3a3a;
    display: inline-block;
    font-size: 4rem;
    padding-left: 1.5rem;
    //transform: scale(1, 1);
    transition: opacity .5s ease-in-out;

    opacity: 0;
    .is-visible &{
        opacity: 1;
        transition-delay: 0s;
        //animation: specialtiesArrowSpinIn .3s ease-in-out 0s 1 forwards normal;
    }
    //.is-exiting &{
    //  animation: specialtiesArrowSpinOut .3s ease-in-out 0s 1 forwards normal;
    //}
    .is-exiting &{
        opacity: 0;
    }
    &:nth-child(1){
    }
    &:nth-child(2){
        transition-delay: .1s;
    }

    &:hover{
        text-decoration: none;
    }
    @include tablet-below{
        font-size: 4.5rem;
        padding-left: 0;

        &:nth-child(1){
            position: absolute;
            left: 10px;
        }
        &:nth-child(2){
            position: absolute;
            right: 10px;
        }
    }

    @include phone-large-below{
        font-size: 6rem;
        &:nth-child(1){
            left: 0px;
        }
        &:nth-child(2){
            right: 0px;
        }
    }
    @include phone{
        font-size: 4rem;
    }
}

@keyframes specialtiesArrowSpinIn{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.5);
    }
    100%{
        transform: scale(1);
    }
}
//@keyframes specialtiesArrowSpinOut{
//  from{
//    opacity: 1;
//    transform: scale(1, 1);
//  }
//  to{
//    opacity: 0;
//    transform: scale(1, 0);
//  }
//}

.page-header__content{
    @include column(12);
    position: relative;
    z-index: 5;
    transition: opacity .5s ease-in-out;
    .is-animating.is-leaving:not([class*="to-thinking-category"]) &{
        opacity: 0;
    }


    .page-header--content-page &{
        border-bottom: solid 5px #3a363c;
        padding-bottom: 2rem;
        margin-bottom: 2rem;

    }

    @include desktop-medium-below{
        //@include column(9);
        // padding-left: 20px;
    }
    @include desktop-below{

        @include column(12);
        //padding: 0 40px;
    }
    @include tablet-below{
        text-align: center;
    }
    @include phone-large-below{
        //padding: 0 15px;
    }
    &.centered{
        text-align: center;
    }
}



.page-header__title{
    @extend %heading-one;
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    overflow: hidden;
    margin-top: 0;

    @include tablet-below{
        padding-right: 0;
    }
    @include phone-large-below{
        font-size: 5.5rem;
    }

    .page-header.static &{
        margin-bottom: 1rem;
    }

    &--peek-transition {
        overflow:hidden;
        > span {
            display: block;
            position: relative;
        }
    }


    //transform: translateY(20px);
    //opacity: 0;
    //animation: fadeRiseIn .5s ease-in-out .2s 1 forwards normal;
    .specialty & {
        padding-right: 10.5rem;
        @include tablet-below{
            padding-right: 0;
        }
    }
}

//.page-header__subtitle.clean > span > span{
//    opacity: 0;
//    transition: opacity .5s $fast-bezier .2s;
//    .is-visible &{
//        opacity: 1;
//    }
//}

.page-header__subtitle{
    display: block;
    @extend %heading-pageheader;
    position: relative;
    margin-bottom: 2rem;
    padding: 1.5rem 0;

    @include phone{
        .page-thinking-business-to-business-marketing & {
            margin-top: 3rem;
        }
        .page-people-toronto-agency & {
            height: 160px;
        }
    }

}
//
//.page-header.specialty .page-header__subtitle{
//    > span{
//        .is-visible &{
//            > span{
//                transform: translateY(100%);
//                //   opacity: 0;
//            }
//            &.visible > span{
//                transform: translateY(0);
//                opacity: 1;
//            }
//        }
//        .is-exiting &{
//            > span{
//                opacity: 0;
//            }
//            &.visible{
//                > span {
//                    transform: translateY(-110%);
//                    opacity: 1;
//                }
//
//            }
//        }
//    }
//}


//.page-header__subtitle:not(.clean):before, .page-header__subtitle:not(.clean):after{
//    position: absolute;
//    width: 100%;
//    height: 0;
//    background-color: $motum-darkgray;
//    display:block;
//    content: '';
//    left: 0;
//    z-index: 2;
//    transition: height .2s ease-in-out .1s;
//    .is-visible &{
//        height: 3px;
//    }
//    .is-exiting &{
//        height: 0;
//    }
//}
//


.page-header__subtitle:before{
    top: 0;
}

.page-header__subtitle:after{
    bottom: 0;
}


.page-header__image{
    @include column(4);
    padding-right: 3rem;
    &.desktop-only {
        padding-top: 3rem;
    }
    @include desktop-small-below{
        @include column(4);
    }
    @include tablet-below{
        margin-top: 2rem;
    }
    //span{
    //  display: block;
    //  position: relative;
    //}
    //span:before{
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //  width: 100%;
    //  height: 100%;
    //  border-radius: 100%;
    //  background-color: $motum-yellow;
    //}

    img{
        margin-top: -2rem;
        max-width: 100%;

        z-index: 0;


    }
    @include tablet-below{
        @include column(6.5);
        float: none;
        margin: 0 auto;
        padding: 0;
        max-width: 250px;
    }
    @include phone-large-below{
        @include column(9);
        float: none;
    }
}
.page-header-subspecialty{
    //column-count: 2;
    //column-gap: 5rem;
    margin-top: 2rem;
    // opacity: 0;
    display: none;
    // position: absolute;
    z-index: 0;
    //transform: translateY(10%);
    //display: none;
    transition: transform .5s cubic-bezier(.64,.39,.03,1.24);
    // @include fadeOutOnPageTransition();


    @include desktop-small-below{
        margin-top: 3rem;
        //column-count: 1;
    }

    p{
        font-size: 2rem;
    }

    .is-visible &{
        &.visible{
            opacity: 1;
            z-index: 1;
        }

        //transform: translateY(0);
    }
    .is-exiting &{
        //opacity: 0;
        //&.visible{
        //  opacity: 0;
        //}
    }
    //transition: all .3s $smooth-bezier .2s;

}


.page-header__body-nav{
    margin-top: 4.5rem;

    li{
        display: inline-block;
        margin-right: 1.25rem;
        padding-right: 1.5rem;

    }
    li button{
        color: $motum-darkgray;
    }




}



// .page-header__title_2{
//     font-size: 7.5rem;
//     font-weight: 700;
//     color: $motum-darkgray;
//     line-height: 7.5rem;
// }





.page-header__tall{
    height: 600px;

    >div:after{
        display: none;
    }
}




.page-header__transit-circle{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    // width: .1vw;
    //height: .1vh;
    //width: 200px;
    //height: 200px;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    //border-radius: 100%;


    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 420vw;
        height: 420vh;
    }

    circle{
        .theme-yellow &{
            fill: $motum-yellow;
        }
        .theme-green &{
            fill: $motum-green;
        }
        .theme-red &{
            fill: $motum-red;
        }
        .theme-purple &{
            fill: $motum-lightgrey;
        }
        .theme-blue &{
            fill: $motum-blue;
        }
    }


    transition: all .9s ease-in-out;
    width: 220vw;
    height: 220vh;
    .is-animating &{
        width: .1vw;
        height: .1vh;
    }
    .is-animating.is-leaving &{
        width: 220vw;
        height: 220vh;
    }
}



.page-header__transit-circle{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    // width: .1vw;
    //height: .1vh;
    //width: 200px;
    //height: 200px;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    //border-radius: 100%;


    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 420vw;
        height: 420vh;
    }

    circle{
        .theme-yellow &{
            fill: $motum-yellow;
        }
        .theme-green &{
            fill: $motum-green;
        }
        .theme-red &{
            fill: $motum-red;
        }
        .theme-purple &{
            fill: $motum-lightgrey;
        }
        .theme-blue &{
            fill: $motum-blue;
        }
    }
    //.is-visible &{
    //    transform: scale(0) translate(-50%, -50%);
    //}

    @keyframes transitionCircleGrow{
        to{
            width: 220vw;
            height: 220vh;
        }
    }

    transition: all .9s ease-in-out;

    width: 220vw;
    height: 220vh;
    .is-animating &{
        width: .1vw;
        height: .1vh;
    }
    .is-animating.is-leaving &{
        width: 220vw;
        height: 220vh;
    }

    //.container:not(.is-specialty-page) .is-entering &, .container:not(.is-specialty-page) .is-visible &{
    //.is-entering &, .is-visible &{
    //    animation: transitionCircleGrow .9s ease-in-out 0s 1 forwards normal;
    //    //transform: scale(150);
    //}



}

//body.transition-specialties-index{
//  .page.is-exiting .page-header__transit-circle{
//    width: 220vmax;
//    height: 220vmax;
//    animation: transitionCircleShrink 1.5s cubic-bezier(.53,-0.01,0,1.03) 0s 1 forwards normal;
//  }
