[data-aos="gentle-up"] {
  transform: translateY(4rem);
  opacity: 0;
  transition: all .5s ease-in-out;

  &.is-in-view {
   transform: translateY(0);
    opacity: 1;
  }
}


[class*="transition-"]{
  &[transition-delay='.15']{
    transition-delay: .15s;
  }
  &[transition-delay='.2']{
    transition-delay: .2s;
  }
  &[transition-delay='.25']{
    transition-delay: .25s;
  }
  &[transition-delay='.3']{
    transition-delay: .3s;
  }
  &[transition-delay='.35']{
    transition-delay: .35s;
  }
  &[transition-delay='.4']{
    transition-delay: .4s;
  }
  &[transition-delay='.45']{
    transition-delay: .45s;
  }
  &[transition-delay='.5']{
    transition-delay: .5s;
  }

  &[transition-delay='1']{
    transition-delay: 1s;
  }
  &[transition-delay='1.5']{
    transition-delay: 1.5s;
  }
  &[transition-delay='2']{
    transition-delay: 2s;
  }
}



.transition-fade{
  transition: opacity .5s cubic-bezier(0,.56,.18,.98) .5s;
  opacity: 1;



  .is-animating &{
    opacity: 0;
  }
}


.transition-peek-in{
  transform: translateY(0);
  transition: transform .5s cubic-bezier(0,.56,.18,.98);

  .is-animating &{
    transform: translateY(100%);
  }
}


.transition-specialties-index-card{
  transition: all .3s cubic-bezier(.64,.39,.03,1.1);
  opacity: 1;


  @for $i from 1 through 4{
    a.specialties-index-card:nth-child(#{$i}) &{
      transition-delay: ((.02s * $i)) + .3s;
    }
  }

  .is-animating &{
    opacity: 0;
    transform: scale(.8);
  }
}




.animated-trans-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
  span:first-child{
    display: block;
    position: absolute;
    left: 0;
    bottom: auto;
    width: 100%;
    height: 0;
    background-color: #3c363c;
    // background-color: $red-saturated;
    transition: height .4s cubic-bezier(.83,.16,.69,.91);

  }
  //span:last-child{
  //    display: block;
  //    position: absolute;
  //    left: 0;
  //    bottom: auto;
  //    width: 100%;
  //    height: 0;
  //    background-color: #fff;
  //    transition: height .4s cubic-bezier(.83,.16,.69,.91) .2s;
  //    .is-animating:not(.is-leaving) &{
  //        bottom: 0;
  //        height: 100%;
  //    }
  //    .page-specialties &{
  //        background-color: red;
  //    }
  //}

  span{
    .is-animating:not([class*="to-specialties-"]):not([class*="to-our-work-"]):not([class*="from-our-work-"]):not([class*="to-thinking-category"]) & {
      bottom: 0;
      height: 100%;
    }
  }

  #transition-loader{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 6rem;
    transition: all .5s cubic-bezier(0.64, 0.39, 0.03, 1.24);
    opacity: 0;
    .is-animating:not([class*="to-specialties-"]):not([class*="to-our-work-"]):not([class*="from-our-work-"]):not([class*="to-thinking-category"]) &{
      //body:not([class*="page-our-work-"]) &{
      opacity: 1;
      // }
    }

  }



}



.swup-progress-bar {
  height: 4px;
  background-color: #fff;
  background-color: var(--color-secondary);
}

