.post-header {
    padding: 12rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    @include tablet-small-below {
        flex-direction:column;
        align-items:flex-start;
    }

    transition: opacity .5s ease-in-out;
    //.quiz-visible &{
    //    opacity: 0;
    //}

    .promoted-post & {
        position: relative;
        padding-bottom: 20rem;
    }
}

.post-header__back-btn {
    display: block;
    width: 100%;
    margin-bottom: 3rem;

    a {
        font-size: 1.8rem;
        // line-height: 1.1;
        @include tablet-below {
            font-size: 2.2rem;
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
    }
}

.post-header__content {
    @include column(8.5);

    .page-header__title {
        line-height: 1;
        font-size: 9rem;
        @include phone-large-below {
            font-size:6rem;
        }
    }

    @include tablet-small-below {
        @include column(12);
    }
}

.post-header__sidebar {
    @include column(3);
    @include tablet-small-below {
        @include column(12);
    }
}

.post-content {
    padding: 6rem 12rem;

    .think-blog-post--sticky-nav & {
        @include phone-large-above {
            padding-right:6rem;
        }
    }

    &.post-content--short{
        padding: 3rem 12rem;
    }
    
    h2{
        color: var(--color-black);
    }

    h2, h3, h4, h5{
        color: var(--color-black);
        strong{
            color: inherit;
        }
    }

    h4, h5 {
        margin-bottom:1rem;
    }

    p strong {
     //   color: var(--color-secondary-saturated);
    }

    li, p {
        font-size: 2rem;
        color: var(--color-black);
    }

    p {
        margin-bottom: 4rem;
    }

    figure {
        img {
            max-width:100%;
            height:auto;
        }
    }

    .callout {
        position:relative;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3;

        .theme-yellow & > * {
            color: $purple-saturated;
        }
        .theme-green & > * {
            color: $purple-saturated;
        }
        .theme-purple & > * {
            color: $purple-saturated;
        }
        .theme-red & > * {
            color: $red-saturated;
        }
        .theme-blue & > * {
            color: $blue-saturated;
        }

        .callout-svg__wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .callout-svg {
            display: block;
            position: absolute;
            z-index: -1;

            &:nth-child(1) {
                width: 2rem;
                top: 0%;
                left: 20%;
                transform: rotate(-35deg);

                polygon {
                    stroke-width: 9;
                }

                polygon, rect {
                    stroke-width: 2;
                }
            }

            &:nth-child(2) {
                width: 3rem;
                top: 40%;
                left: 45%;
                transform: rotate(-25deg);

                polygon {
                    stroke-width: 7;
                }

                polygon, rect {
                    stroke-width: 1.5;
                }
            }

            &:nth-child(3) {
                width: 5rem;
                top: 15%;
                left: 55%;
                transform: rotate(25deg);

                polygon {
                    stroke-width: 5;
                }

                polygon, rect {
                    stroke-width: 1;
                }
            }

            &:nth-child(4) {
                width: 4rem;
                top: 75%;
                left: 80%;
                transform: rotate(-15deg);

                polygon {
                    stroke-width: 6;
                }

                polygon, rect {
                    stroke-width: 1.25;
                }
            }
        }

        polygon, rect {
            width: 100%;

            .theme-yellow & {
                fill: rgba($motum-yellow, 0.2);
                stroke: rgba($motum-yellow, 0.5);
            }
            .theme-green & {
                fill: rgba($motum-green, 0.2);
                stroke: rgba($motum-green, 0.5);   
            }
            .theme-purple & {
                fill: rgba($motum-purple, 0.2);
                stroke: rgba($motum-purple, 0.5);
            }
            .theme-red & {
                fill: rgba($motum-blue, 0.2);
                stroke: rgba($motum-blue, 0.5);
            }
            .theme-blue & {
                fill: rgba($motum-yellow, 0.2);
                stroke: rgba($motum-yellow, 0.5);
            }
        }

        circle {
            width: 100%;
            stroke-width: 0;

            .theme-yellow & {
                fill: rgba($motum-yellow, 0.5);
            }
            .theme-green & {
                fill: rgba($motum-green, 0.5);  
            }
            .theme-purple & {
                fill: rgba($motum-purple, 0.5);
            }
            .theme-red & {
                fill: rgba($motum-blue, 0.5);
            }
            .theme-blue & {
                fill: rgba($motum-yellow, 0.5);
            }
        }
    }

    .callout, .callout2 {
        
        &.full {
            
        }

        &.center {
            // font-weight: 500;
            text-align: center;
            @include column(9);
            margin: 0 auto;
            margin-bottom: 4rem;
            position: relative;
            padding: 4rem 0;
        }

        &.left {
            @include column(7);
            // padding: 0 2rem 2rem;
            margin-left: -12rem;
            float: left;
            padding: 2rem 5rem 2rem 2rem;
        }


        &.right {
            @include column(7);
            // padding: 0 2rem 2rem;
            margin-right: -12rem;
            float: right;
            padding: 2rem 2rem 2rem 5rem;
        }
        
    }
    

    .inline-img {
        @include column(7);
        padding: 0 4rem 2rem;
        position: relative;

        button {
            display: block;
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
            position: relative;

            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 4rem;
                height: 4rem;
                font-size: 1.8rem;
                background: var(--color-primary);
                color: var(--color-black);
                padding: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                transform-origin: top left;
            }

            &:hover{
                i {
                    transform: scale(1.2);

                    
                }
            }
        }

        img {
            width:100%;
            border: 1px solid #ddd;
        }

        span {
            margin-top:.5rem;
            display:block;
            font-size: 1.6rem;
            color: var(--color-black);
            font-weight: 700;
        }

        &.left {
            margin-left: -12rem;
            float: left;
            padding-left: 0;
        }

        &.right {
            margin-right: -12rem;
            float: right;
            padding-right: 0;
            .blog--has-sticky-nav & {
                margin-right:0;
                right: -10rem;
            }
        }

        &.center {
            margin:0 auto;
        }
    }

    .img-row {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 24rem);
        margin-left: -12rem;
        margin-bottom: 4rem;
        justify-content: space-between;
        align-items: center;

        &--1 {
            img {
                width: 100%;
            }
        }

        &--2 {
            img {
                width: calc(50% - 1rem);
            }
        }

        &--3 {
            img {
                width: calc(33.333% - 1rem);
            }
        }
    }

    .img-row-w-caption {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 24rem);
        margin-left: -12rem;
        margin-bottom: 4rem;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            flex-direction: column;
            position: relative;
            width: calc(50% - 1rem);
            img {
                width: 100%;
                border: 1px solid #ddd;
            }
            @include phone-large-below {
                width: 100%;
                margin-bottom: 1.5rem;
            }
        }

        button {
            display: block;
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;

            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 4rem;
                height: 4rem;
                font-size: 1.8rem;
                background: var(--color-primary);
                color: var(--color-black);
                padding: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

            }

            &:hover{
                i {
                    transform: scale(1.2);
                    transform-origin: top left;
                    
                }
            }
        }

        span {
            margin-top:.5rem;
            display:block;
            font-size: 1.6rem;
            color: var(--color-black);
            font-weight: 700;
        }

        @include desktop-below {
            width: calc(100% + 18rem);
            margin-left: -9rem;
        }

        @media (max-width: 800px) {
            width: calc(100% + 12rem);
            margin-left: -6rem;
        }

        @include tablet-small-below {
            width: 100%;
            margin-left: 0;
        }
    }

    .inline-blockquote {
        padding: 0 2rem 2rem;
        @include column(7);

        span {
            display: block;
            position: relative;
            font-weight: 500;
        }

        &.left {
            margin-left: -12rem;
            float: left;
            text-align: right;

            span {
                padding-right: 5rem;
            }

            .quote::before {
                content: '\e908';
                right: 0;
            }

            .credit::before {
                right: 0;
                transform: rotate(180deg);
            }
        }

        &.right {
            margin-right: -12rem;
            float: right;
    
            span {
                padding-left: 5rem;
            }

            .quote::before {
                content: '\e909';
                left: 0;
            }

            .credit::before {
                left: 0;
            }
        }

        &.center {
            margin-left:auto;
            margin-right:auto;
            span {
                padding-left: 5rem;
            }
            .quote::before {
                content: '\e909';
                left: 0;
            }
            .credit::before {
                left: 0;
            }
        }

        .quote {
            font-size: 2.5rem;
            line-height: 1.3;
            padding-right: 0;
            color: var(--color-black);

            &::before {
                display: block;
                font-family: 'icomoon';
                font-size: 3rem;
                position: absolute;
                top: 0;
            
                .theme-yellow & {
                    color: $bright-yellow;
                }
                .theme-green & {
                    color: $motum-green;
                }
                .theme-purple & {
                    color: $bright-purple;
                }
                .theme-red & {
                    color: $bright-red;
                }
                .theme-blue & {
                    color: $bright-blue;
                }
            }
        }

        .credit {
            margin-top: 4rem;
            font-size: 2rem;
            line-height: 1.3;
            color: var(--color-black);

            &::before {
                content: '';
                display: block;
                width: 4.5rem;
                height: 3px;
                position: absolute;
                top: 9px;
                z-index: -1;

                .theme-yellow & {
                    background: $motum-yellow;
                    background: linear-gradient(90deg, rgba($motum-yellow,1) 0%, rgba($motum-yellow,0) 100%);
                }
                .theme-green & {
                    background: $motum-green;
                    background: linear-gradient(90deg, rgba($motum-green,1) 0%, rgba($motum-green,0) 100%);
                }
                .theme-purple & {
                    background: $motum-purple;
                    background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
                }
                .theme-red & {
                    background: $motum-red;
                    background: linear-gradient(90deg, rgba($motum-red,1) 0%, rgba($motum-red,0) 100%);
                }
                .theme-blue & {
                    background: $motum-blue;
                    background: linear-gradient(90deg, rgba($motum-blue,1) 0%, rgba($motum-blue,0) 100%);
                }
            }
        }
    }

    ul {
        margin-bottom: 4rem;
        break-inside: avoid;
        column-gap: 2rem;

        &.two-col {
            column-count: 2;
        }
    }

    li {
        position: relative;
        padding-left: 3rem;

        &::after {
            content: '';
            display: block;
            width: 2.2rem;
            height: 3px;
            position: absolute;
            top: 12px;
            left: 0;
            z-index: -1;

            .theme-yellow & {
                background: $motum-yellow;
                background: linear-gradient(90deg, rgba($motum-yellow,1) 0%, rgba($motum-yellow,0) 100%);
            }
            .theme-green & {
                background: $motum-green;
                background: linear-gradient(90deg, rgba($motum-green,1) 0%, rgba($motum-green,0) 100%);
            }
            .theme-purple & {
                background: $motum-purple;
                background: linear-gradient(90deg, rgba($motum-purple,1) 0%, rgba($motum-purple,0) 100%);
            }
            .theme-red & {
                background: $motum-red;
                background: linear-gradient(90deg, rgba($motum-red,0.75) 0%, rgba($motum-red,0) 100%);
            }
            .theme-blue & {
                background: $motum-blue;
                background: linear-gradient(90deg, rgba($motum-blue,1) 0%, rgba($motum-blue,0) 100%);
            }
        }
    }

    .related-work a {
        background-image: none;
    }

    .related-work.center {
        @include column(12);
        padding-bottom: 4rem;

        img {
            width: 100%;
        }
    }

    .related-work, .related-specialty, .inline-caption-img {
        padding: 0 4rem 2rem;

        &.left {
            margin-left: -12rem;
            float: left;
            padding-left: 0;
        }

        &.right {
            margin-right: -12rem;
            float: right;
            padding-right: 0;
        }

        &.center {
            margin-left:auto;
            margin-right:auto;
            display:flex; 
        }

        .grid__item--size-a, .grid__item {
            width: 100%;
        }

        .our-work-card, .specialty-card {
            overflow: inherit;

            &::before, &::after {
                display: none;
            }
        }
    }

    .related-work {
        @include column(7);
    }

    .related-specialty {
        @include column(5);

        .specialty-card__container {
            min-height: 375px;
        }

        .specialty-card__visual {
            width: 23rem;
            height: 23rem;
        }
    }

    .inline-caption-img {
        @include column(7);
        // padding: 0 2rem 2rem;
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        align-items: flex-start;
        font-size: 1.8rem;
        line-height: 1.3;

        span {
            display: block;
        }

        img {
            @include column(8.5);
        }

        .caption {
            @include column(3.5);
            position: relative;
            padding: 3rem 0;

            .caption-svg {
                display: block;
                position: absolute;
                z-index: -1;

                &:nth-child(1) {
                    width: 2rem;
                    top: 0%;
                    left: 0%;
                    transform: translate(-100%, -100%);

                    polygon {
                        stroke-width: 9;
                    }

                    polygon, rect {
                        stroke-width: 2;
                    }
                }

                &:nth-child(2) {
                    width: 6rem;
                    top: 0%;
                    left: 50%;

                    polygon {
                        stroke-width: 4;
                    }

                    polygon, rect {
                        stroke-width: 0.75;
                    }
                }

                &:nth-child(3) {
                    width: 2.5rem;
                    top: 15%;
                    right: 0%;
                    transform: translateX(80%);

                    polygon {
                        stroke-width: 7;
                    }

                    polygon, rect {
                        stroke-width: 1.5;
                    }
                }

                &:nth-child(4) {
                    width: 4rem;
                    bottom: 0%;
                    left: 0%;
                    transform: translate(-50%, 75%);

                    polygon {
                        stroke-width: 6;
                    }

                    polygon, rect {
                        stroke-width: 1;
                    }
                }
            }

            polygon, rect, circle {
                width: 100%;

                .theme-yellow & {
                    fill: rgba($motum-yellow, 0.2);
                    stroke: rgba($motum-yellow, 0.5);
                }
                .theme-green & {
                    fill: rgba($motum-green, 0.2);
                    stroke: rgba($motum-green, 0.5);   
                }
                .theme-purple & {
                    fill: rgba($motum-purple, 0.2);
                    stroke: rgba($motum-purple, 0.5);
                }
                .theme-red & {
                    fill: rgba($motum-blue, 0.2);
                    stroke: rgba($motum-blue, 0.5);
                }
                .theme-blue & {
                    fill: rgba($motum-yellow, 0.2);
                    stroke: rgba($motum-yellow, 0.5);
                }
            }

            circle {
                width: 100%;
                stroke-width: 0;

                .theme-yellow & {
                    fill: rgba($motum-yellow, 0.5);
                }
                .theme-green & {
                    fill: rgba($motum-green, 0.5);  
                }
                .theme-purple & {
                    fill: rgba($motum-purple, 0.5);
                }
                .theme-red & {
                    fill: rgba($motum-blue, 0.5);
                }
                .theme-blue & {
                    fill: rgba($motum-yellow, 0.5);
                }
            }
        }

        .title {
            font-weight: 700;
            font-size: 2.5rem;
        }

        &.left {
            margin-left: -12rem;
            float: left;
            padding-left: 0;
            text-align: right;

            img {
                padding-left: 3rem;
            }

            .highlighted-cta-btn {
                transform: translateX(33.333%);
            }
        }

        &.right {
            margin-right: -12rem;
            float: right;
            padding-right: 0;

            img {
                padding-right: 3rem;
                order: 1;
            }

            .caption {
                order: 2;
            }

            .highlighted-cta-btn {
                transform: translateX(-33.333%);
            }
        }

        .theme-yellow & {
            color: $bright-purple;
        }
        .theme-green & {
            color: $bright-purple;
        }
        .theme-purple & {
            color: $bright-purple;
        }
        .theme-red & {
            color: $bright-red;
        }
        .theme-blue & {
            color: $bright-blue;
        }
    }

    .highlighted-cta-btn {
        text-transform: uppercase;
        margin-top: 3rem;
        letter-spacing: 2px;
        overflow: inherit;

        >span {
            font-size: 1.5rem;
        }

        i {
            padding-left: 1rem;
            font-size: 1.4rem;
        }
        &::before, &::after {
            display: none;
        }
    }

    @include desktop-below {
        padding: 6rem 9rem;

        .inline-img, .inline-blockquote, .related-work, .related-specialty, .inline-caption-img, .callout, .callout2 {
            &.left {
                margin-left: -9rem;
            }
            &.right {
                margin-right: -9rem;
            }
        }
        .inline-img {
            &.right {
                .blog--has-sticky-nav &{
                    margin-right: 0;
                    right: -3rem;
                }
            }
        }

        .related-specialty {
            @include column(7);
        }

        .inline-caption-img {
            .caption {
                @include column(4);
            }

            img {
                @include column(8);
            }
        }

        .img-row {
            width: calc(100% + 18rem);
            margin-left: -9rem;
        }
    }

    @media (max-width: 959px) {
        .related-work {
            @include column(12);
            margin-bottom: 4rem;
            padding: 0;

            &.left {
                margin-left: 0;
            }
            &.right {
                margin-right: 0;
            }
            &.center {
                padding-bottom: 1rem;
            }
        }
    }

    @media (max-width: 800px) {
        padding: 6rem 6rem;

        .inline-img {
            &.left {
                margin-left: -6rem;
            }
            &.right {
                margin-right: -6rem;
                .blog--has-sticky-nav &{
                    margin-right:0;
                    right:-3rem;
                }
            }
        }

        .inline-img, .inline-blockquote {
            @include column(8);
        }

        .related-work, .related-specialty, .inline-blockquote, .inline-caption-img, .callout, .callout2 {
            @include column(12);
            margin-bottom: 4rem;
            padding: 0;

            &.left {
                margin-left: 0;
            }
            &.right {
                margin-right: 0;
            }
        }

        .related-specialty {
            margin-bottom: 7rem;
            margin-top: 2rem;

            .specialty-card {
                padding: 0;
            }

            .specialty-card__container {
                min-height: auto;
                align-items: center;
            }

            .specialty-card__visual {
                margin-bottom: -3rem;
            }
        }

        .inline-blockquote {
            &.left {
                text-align: left;

                span {
                    padding-left: 5rem;
                    padding-right: 0;
                }

                .quote::before {
                    content: '\e909';
                    left: 0;
                }

                .credit::before {
                    left: 0;
                    transform: rotate(0);
                }
            }
        }

        .img-row {
            width: calc(100% + 12rem);
            margin-left: -6rem;

            &--3 {
                img {
                    width: calc(50% - 1rem);
                }
                img:first-child {
                    width: calc(100% - 12rem);
                    margin: 0 auto;
                    margin-bottom: 2rem;
                }
            }
        }

        ul.two-col {
            column-count: 1;
        }
    }

    @include tablet-small-below {
        padding: 6rem 3rem;

        .callout.center {
            width: 100%;
            padding: 2rem 0;
        }

        .inline-img {
            &.left  {
                margin-left: 0;
            }
            &.right {
                margin-right: 0;
                .blog--has-sticky-nav &{
                    right: 0;
                }
            }
        }

        .img-row {
            width: 100%;
            margin-left: 0;

            &--3 {
                img:first-child {
                    width: 100%;
                }
            }
        }
    }

    @include tablet-below {

        p, li {
            font-size: 2.25rem;
        }

        .inline-blockquote .credit {
            font-size: 2.5rem;
        }

        .callout.center, .inline-blockquote .quote {
            font-size: 3rem;
        }
    }

    @include phone-large-below {

        .inline-img {
            width: 100%;
            padding: 0 0 4rem;
        }

        .img-row {
            &--2 img, &--3 img {
                width: 100%;
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .inline-caption-img {
            .caption, img {
                width: 100%;
            }

            &.left {
                img {
                    padding-left: 0;
                }

                .highlighted-cta-btn {
                    transform: translateX(0);
                }
            }

            &.right {
                img {
                    padding-right: 0;
                }

                .highlighted-cta-btn {
                    transform: translateX(0);
                }
            }

            img {
                order: 1;
            }

            .title {
                font-size: 3rem;
            }

            .caption {
                order: 2;
                text-align: left;
                font-size: 2rem;
            }

        }
    }
}

.blog--has-sticky-nav {
    .post-header .social-share-buttons.fixed.sticky-nav--fixed {
        @media(min-width:768px) and (max-width:1650px) {
            flex-direction:row;
            .social-share-button {
                margin-left:1rem;
                margin-bottom:0;
            }
        }
    }
    &.think-blog-post > .wrapper {
        @include tablet-above {
            display:flex;
            flex-direction: row;
            align-items: flex-start;
        }
    }
}

.blog-sticky-nav {
    position: sticky;
    right:0;
    top: 16rem;
    width: 100%;
    max-width:21rem;
    flex-shrink:0;
    background-color:#fff;
    order:1;
    z-index:1;
    margin-top:4rem;
    margin-left:1rem;
    padding:1rem;
    @media(min-width:1650px){
        top:10rem;
    }
    @include tablet-below {
        position:relative;
        max-width:100%;
        top:0;
        padding: 2rem 6rem;
        background-color:#fafafa;
        margin:0;
    }
    @include tablet-small-below {
        padding: 0 1rem;
    }

    &__content {
        position:relative;
        padding:1rem;
        @include tablet-small-below {
            padding: 3rem;
        }
    }

    &__title {
        font-weight:700;
        font-size:2rem;
        margin-bottom: 1.5rem;
        @include tablet-below {
            font-size:2.2rem;
            margin-bottom: 2rem;
        }
    }

    &__list {
        list-style-type: none;
        @include tablet-below {
            margin-left: 1.5rem;
        }
    }

    &__item {
        // font-size: 1.8rem;
        // margin-bottom: 1rem;
        // margin-left: 0.3rem;
        // line-height:120%;
        margin-bottom: 1rem;
        position:relative;
        &::before {
            content:"";
            position:absolute;
            height:.4rem;
            width:.4rem;
            border-radius: .4rem;
            background-color:$motum-red;
            left:-1.4rem;
            top:1rem;
            transition: all .2s ease-out;
        }
        @include tablet-below {
            font-size:2rem;
        }
        &:hover {
            &::before {
                width:20px;
                left:-25px;
            }
            .blog-sticky-nav__link {
                font-weight:700;
                letter-spacing: -0.5px;
            }
        }
    }

    &__link {
        background: none;
        border: 0;
        box-shadow: none;
        font-size: 1.8rem;
        margin-left: 0.3rem;
        line-height:120%;
        text-align: left;
        cursor: pointer;
    }

    .active {
        @include tablet-above {
            .blog-sticky-nav__link {
                font-weight:700;
                letter-spacing: -0.5px;
            }
            &::before {
                width:20px;
                left:-25px;
            }
        }
    }
}
