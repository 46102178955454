
.error-page-container{
  background: red;
  min-width: 100vw;
  min-height: 100vh;
  background-color: $motum-darkgray;
  color: $motum-lightgrey;
}

//========== Error Page ===========//
.error-page {


}


.star {
  color: #e9e8e8;
  position: absolute;
  opacity: 0;
  animation: fade 0.15s linear infinite;
  z-index: 1;
}
@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.error-img-area {
  width: 100%;
  margin: 0 auto;
  position: relative;
  // height: 50vh;
  height: 625px;
  @include tablet-small-below {
    height: 500px;
  }
  .error-stars {
    position: absolute;
    width: 100%;
  }
  .error-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding: 75px;
    width: 100%;
    img {
      height: 45rem;
      position: relative;
      @include desktop-large-below {
        margin-left: 2rem;
      }
      @include tablet-below{
        height: 35rem;
        margin-top: 3rem;
        margin-left: 4rem;
      }
      @include phone-large-below {
        height: 25rem;
        margin-top: 5rem;
        margin-left: 7rem;
      }
      // left: 50%;
      // transform: translateX(-50%);
    }
  }
  .four {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 30rem;
    position: absolute;
    color: $motum-lightgrey;
    top: 50%;
    left: calc(50% - 14%);
    transform: translate(-50%, -50%);
    z-index: 2;
    user-select: none; /* Standard */
    @include desktop-large-below {
      left: calc(50% - 18%);
    }
    @include desktop-small-below {
      left: calc(50% - 25%);
    }
    @include desktop-below {
      left: calc(50% - 30%);
    }
  }
  #two{
    left: calc(50% + 11%);
    z-index: 2;
    @include desktop-large-below {
      left: calc(50% + 15%);
    }
    @include desktop-small-below {
      left: calc(50% + 22%);
    }
    @include tablet-below {
      left: calc(50% + 28%);
    }
  }
}
.error-copy {
  padding: 0 3rem 22rem;
  user-select: none; /* Standard */
  a {
    color: $motum-lightgrey;
    border-bottom: 0.4rem solid $motum-lightgrey;
    transition: 0.35s ease all;
    &:hover, &:active, &:focus {
      outline: none;
      color: $motum-red;
      border-bottom-color: $motum-red;
    }
  }
}
.error-copy h1, .error-copy h2 {
  color: $motum-lightgrey;
  font-size: 6rem;
  text-align: center;
  @include tablet-small-below {
    font-size: 4.5rem;
  }
}
.error-copy p {
  text-align: center;
  color: $motum-lightgrey;
  font-size: 3rem;
  font-weight: 500;
  padding-top: 5rem;
  @include tablet-small-below {
    font-size: 3.5rem;
  }
}

