//Fancybox is right-aligning images with transform. This counters that to make it centered.


// .fancybox-slide--image.fancybox-slide--current {
//     display:flex;
//     justify-content: flex-start;
// }

.modal-backdrop {
    body:not(.modal-open) & {
        opacity: 0;
        pointer-events: none;
    }
}