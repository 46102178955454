
.main-nav-container {
  @include column(6.5);


  @include desktop-small-below {
    @include column(8);
  }

  @include desktop-below {
    @include column(8);
  }

  .mobile-menu-open & {
    pointer-events: auto;
  }

  @include tablet-below {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    overflow: hidden;
    pointer-events: none;
  }

}

// ===== TOP NAVIGATION =====


.main-nav {
  width: 100%;
  // margin-top:0.2rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  @include tablet-below {
    margin-top: 15px;
    display: none;
  }


  @include tablet-below {
    // display:none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

.main-nav__social-icons {
  position: relative;
  padding-left: 1.5rem;
  margin-left: 1.8rem;
  transform: translateY(-5px);
  position: relative;
  display: flex;
  align-items: center;
  .header-fixed & {
    transform: translateY(1px);
  }
  .header-dark & {
    transform: translateY(-2px);
  }
  .header-dark.header-fixed & {
    transform: translateY(1px);
  }


  //&:before{
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  content: '';
  //  display: block;
  //  background-color: $motum-darkgray;
  //  width: 2px;
  //  height: 16px;
  //  opacity: .8;
  //}

  @include tablet-below {
    padding-left: 0;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  a {
    color: $motum-darkgray;
    font-size: 1.6rem;
    opacity: .8;
    transition: opacity .15s ease;
    display: inline-block;
    margin-left: 1.5rem;

    // &:last-child, &:nth-child(2) {
    //   margin-left: 1.5rem;
    // }

    &:hover {
      opacity: 1;
    }

    .page-dark & {
      color: #fff;
    }

    @include tablet-below {
      font-size: 3.5rem;
      margin-left: 3rem;

      // &:last-child, &:nth-child(2) {
      //   margin-left: 3rem;
      // }

      i {
        color: #fff;
      }

    }
  }
}

#searchButton {
  margin-left: 1.5rem;
  border-radius: 50%;
  border: none;
  padding: 1rem 1.1rem;
  background: #fff;
  opacity: .8;
  transform: translateY(-5px);
  cursor: pointer;
  transition: opacity .15s ease, transform 0.2s ease;
  @include tablet-below {
    margin-left: 0;
    margin-top: 2rem;
  }
  i.icon-search {
    font-size: 1.6rem;
    color: #3a363c;
    @include tablet-below {
      font-size: 2.7rem;
    }
  }

  .header-fixed &,
  .header-dark & {
    background: #3a363c;
    i.icon-search {
      color: #fff;
    }
  }

  .header-fixed & {
    transform: translateY(1px);
    &:hover,
    &:focus {
      opacity: 1;
      transform: translateY(1px) scale(1.1);
    }
  }

  .header-dark & {
    transform: translateY(-2px);
    &:hover,
    &:focus {
      opacity: 1;
      transform: translateY(-2px) scale(1.1);
    }
  }

  .header-dark.header-fixed & {
    transform: translateY(1px);
    &:hover,
    &:focus {
      opacity: 1;
      transform: translateY(1px) scale(1.1);
    }
  }

  &:hover,
  &:focus {
    opacity: 1;
    transform: translateY(-5px) scale(1.1);
  }

}


.main-nav ul.navigation {
  display: flex;
  justify-content: space-between;
  text-align: right;
  width: 100%;
  @include tablet-below {
    flex-direction: column;
    align-items: center;
  }
  //@include column(8);
}


.main-nav ul.navigation > li {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;

  // Quick hack to show outlines
  padding: 4px;
  margin-top: -4px;
  @include desktop-small-below {
    font-size: 0.78em;
    padding-right: 1rem;
  }
  @include tablet-below {
    padding-right: 0;
  }

  &:last-child {
   // padding-right: 0;
    @include desktop-medium-below {
      > ul {
        transform: translateX(-75%);
      }
    }
  }

  > a {
    height: 32px;
    margin-top: -2px;

    &:hover, &:focus {
      text-decoration: none;
      opacity: 1;
      span,  i {
        z-index: 8;
      }
      &:before, &:after, span:before, span:after {
        width: 100%;
        left: 0;
      }
    }



    .is-changing & {
      pointer-events: none;
    }

    // padding: 3px;
    @include tablet-below {
      height: auto;
    }
  }

  > a, > span {
    font-weight: 600;
    //text-transform:uppercase;
    position: relative;
    opacity: .8;
    display: block;
    transition: color .15s ease, opacity .15s ease;
    //overflow: hidden;
    font-size: 2rem;
    //padding-bottom:1rem;
    cursor: pointer;
    &:focus > span > span > span {
      outline: 2px solid #fff;
    }
    .header--is-fixed &, .header-dark & {
      &:focus > span > span::after {
        outline: 2px solid #333;
      }
    }

    > span {
      display: block;
      //overflow: hidden;
      position: relative;
      //height: 18px;
      margin-bottom: 0.5rem;
      transition: transform .3s ease-in-out .2s;
     // margin-top: 3px;
      .header--is-fixed &{
        margin-top: 3px;
      }
    }

    @include tablet-below {
      font-size: 4rem;
      font-weight: 600;
      //text-transform: none;
    }


    .header-dark &, .header-fixed & {
      @include tablet-above {
        > span {
          transform: translateY(-50%);
        }
      }
      @include tablet-below {
        > span {
          transform: none;
        }
      }

    }

    .is-animating:not([class*="to-specialties-"]):not([class*="to-our-work-"]):not([class*="to-thinking-category"]) .header:not(.header--is-fixed) & {
      > span {
        transform: translateY(0);
      }
    }


    > span > span {
      display: block;
      position: relative;
      top: 0;
      //line-height: 7rem;
      color: #fff;

      .page-thinking-business-to-business-marketing-read & {
        // color: #fff;
      }

      color: #fff;

      &::after {
        color: $motum-darkgray;
        content: attr(data-pretitle);

        position: relative;
        display: block;
        margin-top: 5px;
        // transform: translateY(100%);
        @include desktop-medium-below {
          top: 3px;
        }
        @include tablet-below {
          display: none;
        }
      }

    }

    i {
      margin-left: 0.5rem;
    }

    .first-load & {
      &:before {
        //  background-color: #e2db19;
      }

      &:after {
        //  background-color: #6e6a5f;
      }
    }
  }

  .first-load & {

    //
    //a > span > span:first-child, > span > span > span:first-child{
    //    opacity: .15;
    //    transform: translateY(0);
    //    animation: slideOutNavItem .75s cubic-bezier(0.14, 0.34, 0.08, 0.96) 0s 1 forwards normal;
    //    .page-index &{
    //        color: #fff;
    //    }
    //
    //}
    //a > span > span:last-child, > span > span > span:last-child{
    //    .page-index &{
    //        color: #fff;
    //    }
    //    position: absolute;
    //    transform: translateY(100%);
    //    animation: slideInNavItem .75s cubic-bezier(0.14, 0.34, 0.08, 0.96) 0s 1 forwards normal;
    //}
  }


  @for $i from 1 through 14 {
    &:nth-child(#{$i}) > a > span {
      transition-delay: (.075s * $i) !important;
    }
  }

  &.active {
    > a, > span {
      color: $motum-darkgray;
      opacity: 1;

      &:hover {
        color: $motum-darkgray;
      }
    }

    &:after {
      opacity: 1;
    }

    a:before, a:after, span:before, span:after {
      width: 100%;
      left: 0;

    }

    > a > span > span:last-child {
      //top: 0px;
    }
  }

  > i {
    position: relative;
  }


  &:hover {


  }



  a.focus-visible {
    &:before,
    &:after {
      width: 100%;
      left: 0;
    }

    z-index: 8;
    text-decoration: none;
    opacity: 1;

    span {
      z-index: 8;
      text-decoration: none;
      opacity: 1;

      &:before,
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  a:before, > span:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: auto;
    //opacity: 0;
    bottom: 0px;
    width: 0;
    height: 3px;
    //transform: translateX(-100%);

    background-color: #f95200;
    transition: all .25s cubic-bezier(.694, .048, .335, 1);
    pointer-events: none;
    z-index: 2;
  }

  a:after, > span:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: auto;
    //opacity: 0;
    bottom: 0px;
    width: 0;
    height: 3px;
    //transform: translateX(-100%);

    background-color: #f1b1a0;
    z-index: 1;
    transition: all .15s cubic-bezier(.694, .048, .335, 1) .2s;
    pointer-events: none;
  }

  a:after, > span:after {
    // background-color: darken($motum-green, 25%);
    --l: 50%; /*the initial lightness*/
    background-color: hsl(var(--color-secondary), 45%, 50%);

  }

  a:before, > span:before {
    // background-color: $motum-green;
    background-color: var(--color-secondary);
  }

  &:not(.active) {
    a:after, > span:after {
      .is-animating.is-leaving:not([class*="to-specialties-"]):not([class*="to-our-work-"]) & {
        height: 0;
      }
    }

    a:before, > span:before {
      .is-animating.is-leaving:not([class*="to-specialties-"]):not([class*="to-our-work-"]) & {
        height: 0;
      }
    }
  }

}


@keyframes slideOutNavItem {
  to {
    transform: translateY(-100%);
  }
}

@keyframes slideInNavItem {
  to {
    transform: translateY(0);
  }
}


.menu-toggle {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  margin-left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 23px;
  // z-index: 102;
  display: none;
  transition: top .5s cubic-bezier(.53, -.01, 0, 1.23);
  @include tablet-below {
    display: block;
  }
  @include phone {
    top: 30px;
  }

  body:not(.mobile-menu-open) & {
    .header--is-fixed & {
      top: 13px;
    }
  }
}

.hamham, .cross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.hamham span {
  display: block;
  width: 40px;
  height: 4px;
  margin-bottom: 5px;
  overflow: hidden;
  position: relative;
}

.hamham span:last-child {
  margin: 0;
}

.hamham span:before, .hamham span:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $motum-darkgray;
  transform: translateX(-200%);
  transition: transform ease 300ms;

  .page-index & {
    background-color: #fff;
    
  }
  .page-index .header--is-fixed & {
    background-color: $motum-darkgray; 
  }

}

.hamham span:after {
  transform: translateX(0);
}

.hamham span:nth-child(2):before, .hamham span:nth-child(2):after {
  transition-delay: 75ms;
}

.hamham span:last-child:before, .hamham span:last-child:after {
  transition-delay: 150ms;
}

.menu-toggle:hover .hamham span:before {
  transform: translateX(0);
}

.menu-toggle:hover .hamham span:after {
  transform: translateX(200%);
}

.menu-toggle.active .hamham span:before {
  transform: translateX(100%);
}

.menu-toggle.active .hamham span:after {
  transform: translateX(200%);
}

.cross {
  z-index: 2;
}

.cross span {
  display: block;
  width: 33px;
  height: 4px;
  background-color: #fff;
  transform: translateY(50%) rotate(45deg) scaleX(0);
  transition: transform cubic-bezier(.53, -.01, 0, 1.53) 500ms;

  body:not(.mobile-menu-open) & {
    .header--is-fixed & {
      background-color: $motum-darkgray;
    }
  }
}

.cross span:last-child {
  transform: translateY(-50%) rotate(-45deg) scaleX(0);
}

.menu-toggle.active .cross span {
  transition-delay: 450ms;
  transform: translateY(50%) rotate(45deg) scaleX(1);
}

.menu-toggle.active .cross span:last-child {
  transform: translateY(-50%) rotate(-45deg) scaleX(1);
}


.mobile-menu-circle-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;

  > div {
    position: absolute;
    right: 60px;
    top: 8rem;
  }

  .mobile-menu-open & {
    // z-index: 100;
  }
}

.mobile-menu-circle {
  border-radius: 50%;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  background: $motum-darkgray;
  opacity: 1;
  transition: all .8s cubic-bezier(.48, .24, .53, .7);
  transform: translate(-50%, -50%);
  transform-origin: center center;
  z-index: 1;

  .page-index & {
    background: darken($motum-darkgray, 10%);
  }

  .mobile-menu-open & {
    width: 400vh;
    height: 400vh;
  }
}